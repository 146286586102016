<template>
  <div class="flex-col container">
    <div class="flex-col group_1">
      <div class="flex-col group_3">
        <v-header></v-header>
        <div class="flex-col group_4">
          <span class="text_2">一站式B2B营销增长平台</span>
          <span class="text_3">找到目标客户 · 发现关键意图 · 创新营销体验</span>
          <div class="video_parent">
            <img src="@/res/local/shouye_top_logo.png" class="image_6" />
            <!-- <img src="@/res/local/mp4_f.png" class="image_6" /> -->
            <!-- <video x5-video-player-type="h5-page"  muted loop="loop" autoplay="autoplay">
              <source src="https://marketing.cdn.kefenxi.com/website/web1012_1.mp4" type="video/mp4" />
            </video> -->

              <!-- <video vjs-fluid id="example_video_1" class="video-js vjs-default-skin" preload="none"
                    poster="@/res/local/mp4_f.png">
                <source src="https://marketing.cdn.kefenxi.com/website/web1012_1.mp4" type="video/mp4">
              </video> -->
          </div>

          <div class="search justify-between section_2">
            <img src="@/res/local/16376631404543724564.png" class="image_7 image_7_1" />
            <input v-model="input1" class="left-group flex-row" type="text" placeholder="请输入您的手机号码">
            <div @click="goformPageFun()" class="right-text-wrapper flex-col items-center">
              <span>免费试用</span>
            </div>
          </div>
          <div class="flex-col group_5">
            <div @click="goOtherPageFun('https://marketing.huoyanio.com/gong-ye-zhi-zao-xing-ye-bai-pi-shu-')" class="flex-col section_3">
              <span class="text_7">《工业制造行业增长白皮书》</span>
              <span class="text_8">500强在用的营销数字化转型全攻略</span>
              <div class="flex-row group_6">
                <span>立即阅读</span>
                <img src="@/res/local/16376631404572423570.png" class="image_3 image_9" />
              </div>
            </div>
            <div @click="goOtherPageFun('https://marketing.huoyanio.com/ruan-jiansaas-xing-ye-ying-xiao-huo-ke-bai-pi-shu?utm_source=%E5%AE%98%E7%BD%91')" class="flex-col section_4">
              <div class="flex-col group_7">
                <span class="text_10">《软件SaaS行业营销获客白皮书》</span>
                <span class="text_11">增长策略和实践案例合集</span>
              </div>
              <div class="flex-row group_8">
                <span class="text_12">立即阅读</span>
                <img src="@/res/local/16376631404572423570.png" class="image_3 image_10" />
              </div>
            </div>
            <div @click="goOtherPageFun('http://px3n.cn/320CM')" class="flex-col section_5">
              <div class="flex-col items-center group_9">
                <span class="text_13">《从0开始做ABM》</span>
                <span class="text_14" style="text-align:center">你的第一本目标客户营销指导书</span>
              </div>
              <div class="justify-center group_10">
                <span>立即阅读</span>
                <img src="@/res/local/16376631404572423570.png" class="image_3 image_11" />
              </div>
            </div>
          </div>
          <div class="flex-col items-center group_11">
            <span class="text_16">快来体验B2B爆炸式营销增长</span>
            <span class="text_17">获客、触达、培育、转化，构建一站式智能营销体系</span>
          </div>
          <div class="justify-center group_12">
            <img src="@/res/local/2f0257a0c3219f9f7951e98960364ed8.png" class="image_7 image_12" />
            <span class="text_18">数据云</span>
          </div>
        </div>
        <div class="flex-row group_13">
          <div @click="showgroup13fun(1)" :class="group_13Show==1?'groupActive':''" class="left-text-wrapper flex-col items-center">
            <span>专属B2B企业数据库</span>
          </div>
          <div @click="showgroup13fun(2)" :class="group_13Show==2?'groupActive':''"  class="center-text-wrapper flex-col items-center">
            <span>找到高契合度客户</span>
          </div>
          <div @click="showgroup13fun(3)" :class="group_13Show==3?'groupActive':''" class="flex-col items-center text-wrapper_1">
            <span class="text_21">客户数据中台</span>
          </div>
        </div>
        <div v-if="group_13Show==1" class="flex-col group_14">
          <div class="top-group flex-col">
            <div class="top-group_1 flex-row">
              <img src="@/res/local/fb6df5fe0a5404f1165e16401193fd65.svg" class="image_13" />
              <span class="text_22">覆盖1.6亿职场人群画像、6000万+企业主体</span>
            </div>
            <div class="bottom-group flex-row">
              <img src="@/res/local/index_logo_groups.svg" class="image_15" />
              <div class="right-group flex-col">
                <span>查找符合您理想客户特征（ICP）的目标企业</span>
                <span class="text_24">多维度标签，按需精准分析企业职场画像群体</span>
                <span class="text_26">实时更新，让您一直使用最新的数据</span>
              </div>
            </div>
          </div>
          <div class="flex-col group_15">
            <div class="flex-col group_16">
              <div class="flex-col items-center image-wrapper">
                <img src="@/res/remote/sjy_picture_qiyexinxi.png" class="image_17" />
              </div>
            </div>
            <div class="flex-col group_17">
              <div  @click="goOtherPageFun('/data-cloud.html')" class="top-section flex-row view_8">
                <span>了解更多</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 right" />
              </div>
              <div class="justify-center group_18">
                <img src="@/res/local/b1943ba55b9e8453c36a442ed62d034c.png" class="image_7 image_19" />
                <span class="text_29">ABM营销云</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="group_13Show==2" class="flex-col group_14">
          <div class="top-group flex-col">
            <div class="top-group_1 flex-row">
              <img src="@/res/local/fb6df5fe0a5404f1165e16401193fd65.svg" class="image_13" />
              <span class="text_22">为您推荐高契合度企业，实现精准营销</span>
            </div>
            <div class="bottom-group flex-row">
              <img src="@/res/local/index_logo_groups.svg" class="image_15" />
              <div class="right-group flex-col">
                <span>个性化主题标签定制，为企业提供及时情报</span>
                <span class="text_24">统一各部门对目标市场画像的认知偏差</span>
                <span class="text_26">基于目标客户画像推荐更多相似企业</span>
              </div>
            </div>
          </div>
          <div class="flex-col group_15">
            <div class="flex-col group_16">
              <div class="flex-col items-center image-wrapper">
                <img src="@/res/remote/abm_picture_hrs1.png" class="image_17" />
              </div>
            </div>
            <div class="flex-col group_17">
              <div @click="goOtherPageFun('/data-cloud.html')" class="top-section flex-row view_8">
                <span>了解更多</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 right" />
              </div>
              <div class="justify-center group_18">
                <img src="@/res/local/b1943ba55b9e8453c36a442ed62d034c.png" class="image_7 image_19" />
                <span class="text_29">ABM营销云</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="group_13Show==3" class="flex-col group_14">
          <div class="top-group flex-col">
            <div class="top-group_1 flex-row">
              <img src="@/res/local/fb6df5fe0a5404f1165e16401193fd65.svg" class="image_13" />
              <span class="text_22">以OneID打通并规范企业多源数据，统一客户视图</span>
            </div>
            <div class="bottom-group flex-row">
              <img src="@/res/local/index_logo_groups.svg" class="image_15" />
              <div class="right-group flex-col">
                <span>为每位客户创建一致且持续更新的客户资料</span>
                <span class="text_24">对客户群进行细分，展开更触达人心的互动</span>
                <span class="text_26">描绘客户行为历程，掌握客户生命周期阶段</span>
              </div>
            </div>
          </div>
          <div class="flex-col group_15">
            <div class="flex-col group_16">
              <div class="flex-col items-center image-wrapper">
                <img src="@/res/remote/sjy_picture_cdb.png" class="image_17" />
              </div>
            </div>
            <div class="flex-col group_17">
              <div @click="goOtherPageFun('/data-cloud.html')" class="top-section flex-row view_8">
                <span>了解更多</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 right" />
              </div>
              <div class="justify-center group_18">
                <img src="@/res/local/b1943ba55b9e8453c36a442ed62d034c.png" class="image_7 image_19" />
                <span class="text_29">ABM营销云</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-row group_19">
          <div @click="showgroup19fun(1)" :class="group_19Show==1?'groupActive':''" class="left-text-wrapper flex-col items-center">
            <span>主动式获客</span>
          </div>
          <div @click="showgroup19fun(2)" :class="group_19Show==2?'groupActive':''" class="center-text-wrapper flex-col items-center">
            <span>全渠道触达与互动</span>
          </div>
          <div @click="showgroup19fun(3)" :class="group_19Show==3?'groupActive':''" class="center-text-wrapper flex-col items-center">
            <span>自动化营销流程</span>
          </div>
        </div>
        <div v-if="group_19Show==1" class="flex-col group_20">
          <div class="flex-row group_21">
            <img src="@/res/local/fb6df5fe0a5404f1165e16401193fd65.svg" class="image_13" />
            <span class="text_33">帮您在营销前确认全量目标客户</span>
          </div>
          <div class="flex-row group_22">
            <img src="@/res/local/index_logo_groups.svg" class="image_15" />
            <div class="flex-col group_23">
              <span>从火眼云三方数据平台中筛选目标客户</span>
              <span class="text_35">从您的官网和微信生态中识别新的潜在客户</span>
              <span class="text_36">从行为意图数据和中发现意向客户</span>
            </div>
          </div>
          <div class="flex-col group_24">
            <div class="flex-col items-center image-wrapper_1">
              <img src="@/res/remote/abm_picture_cj.png" class="image_22" />
            </div>
            <div @click="goOtherPageFun('/abm-marketing-cloud.html')" class="top-section flex-row">
              <span>了解更多</span>
              <img src="@/res/local/16376631404557442233.png" class="image_3 right" />
            </div>
          </div>
          <div class="justify-center group_25">
            <img src="@/res/local/327166d1b3f8e8912112e5d91d9b3783.png" class="image_24" />
            <span class="text_38">情报云</span>
          </div>
        </div>
        <div v-if="group_19Show==2" class="flex-col group_20">
          <div class="flex-row group_21">
            <img src="@/res/local/fb6df5fe0a5404f1165e16401193fd65.svg" class="image_13" />
            <span class="text_33"> 跨平台持续与目标客户互动</span>
          </div>
          <div class="flex-row group_22">
            <img src="@/res/local/index_logo_groups.svg" class="image_15" />
            <div class="flex-col group_23">
              <span>整合主流营销渠道及平台，跨渠道触达客户</span>
              <span class="text_35">根据客户行为意图数据智能选择触达方式</span>
              <span class="text_36">通过人群特征分析与营销自动化实现千人千面的内容触达</span>
            </div>
          </div>
          <div class="flex-col group_24">
            <div class="flex-col items-center image-wrapper_1">
              <img src="@/res/remote/abm_picture_hrs.png" class="image_22" />
            </div>
            <div @click="goOtherPageFun('/abm-marketing-cloud.html')" class="top-section flex-row">
              <span>了解更多</span>
              <img src="@/res/local/16376631404557442233.png" class="image_3 right" />
            </div>
          </div>
          <div class="justify-center group_25">
            <img src="@/res/local/327166d1b3f8e8912112e5d91d9b3783.png" class="image_24" />
            <span class="text_38">情报云</span>
          </div>
        </div>
        <div v-if="group_19Show==3" class="flex-col group_20">
          <div class="flex-row group_21">
            <img src="@/res/local/fb6df5fe0a5404f1165e16401193fd65.svg" class="image_13" />
            <span class="text_33"> 可视的自动化营销画布搭建</span>
          </div>
          <div class="flex-row group_22">
            <img src="@/res/local/index_logo_groups.svg" class="image_15" />
            <div class="flex-col group_23">
              <span>通过简单的拖放完成自动营销流程搭建</span>
              <span class="text_35">轻松设置特定行为后的下一步营销动作</span>
              <span class="text_36">使用行业模板快速构建营销流程</span>
            </div>
          </div>
          <div class="flex-col group_24">
            <div class="flex-col items-center image-wrapper_1">
              <img src="@/res/remote/abm_picture_szz.png" class="image_22" />
            </div>
            <div @click="goOtherPageFun('/abm-marketing-cloud.html')" class="top-section flex-row">
              <span>了解更多</span>
              <img src="@/res/local/16376631404557442233.png" class="image_3 right" />
            </div>
          </div>
          <div class="justify-center group_25">
            <img src="@/res/local/327166d1b3f8e8912112e5d91d9b3783.png" class="image_24" />
            <span class="text_38">情报云</span>
          </div>
        </div>
        <div class="flex-row group_26">
          <div @click="showgroup26fun(1)" :class="group_26Show==1?'groupActive':''" class="left-text-wrapper flex-col items-center">
            <span>获取客户行为意图</span>
          </div>
          <div @click="showgroup26fun(2)" :class="group_26Show==2?'groupActive':''" class="center-text-wrapper flex-col items-center">
            <span>匿名访客识别</span>
          </div>
          <div @click="showgroup26fun(3)" :class="group_26Show==3?'groupActive':''" class="center-text-wrapper flex-col items-center">
            <span>微信社群粉丝识别</span>
          </div>
        </div>
        <div class="flex-col group_27">
          <div class="top-group flex-col">
            <template v-if="group_26Show==1">
              <div class="top-group_1 flex-row">
                <img src="@/res/local/fb6df5fe0a5404f1165e16401193fd65.svg" class="image_13" />
                <span class="text_42">确定哪些企业正在研究您或是您的竞品</span>
              </div>
              <div class="bottom-group flex-row">
                <img src="@/res/local/index_logo_groups.svg" class="image_15" />
                <div class="right-group flex-col">
                  <span>在您的竞争对手之前找到高意向潜在客户</span>
                  <span class="text_24">自定义行为主题，识别企业专属的高意向信号</span>
                  <span class="text_26">根据行为意图数据优先处理热线索，把握营销时机</span>
                </div>
              </div>
              <img src="@/res/remote/qby_picture_jyfx.png" class="image_27" />
              <div @click="goOtherPageFun('/Intent-cloud.html')" class="top-section flex-row view_20">
                <span>了解更多</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 right" />
              </div>
            </template>
            <template v-if="group_26Show==2">
              <div class="top-group_1 flex-row">
                <img src="@/res/local/fb6df5fe0a5404f1165e16401193fd65.svg" class="image_13" />
                <span class="text_42">实名网站流量，发现更多高意向线索</span>
              </div>
              <div class="bottom-group flex-row">
                <img src="@/res/local/index_logo_groups.svg" class="image_15" />
                <div class="right-group flex-col">
                  <span>以匿名访客识别能力，识别流量背后的企业信息</span>
                  <span class="text_24">基于识别出的企业和访客行为信息，分析其购买意图</span>
                  <span class="text_26">识别来访渠道，评估渠道质量，优化营销策略</span>
                </div>
              </div>
              <img src="@/res/remote/qby_picture_nmfksb.png" class="image_27" />
              <div @click="goOtherPageFun('/Intent-cloud.html')" class="top-section flex-row view_20">
                <span>了解更多</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 right" />
              </div>
            </template>
            <template v-if="group_26Show==3">
              <div class="top-group_1 flex-row">
                <img src="@/res/local/fb6df5fe0a5404f1165e16401193fd65.svg" class="image_13" />
                <span class="text_42"> 识别公众号粉丝与微信社群成员的身份和行为</span>
              </div>
              <div class="bottom-group flex-row">
                <img src="@/res/local/index_logo_groups.svg" class="image_15" />
                <div class="right-group flex-col">
                  <span>公众号粉丝实名化，分析粉丝职场画像标签</span>
                  <span class="text_24">识别客户所在微信群、并获取访问和下载行为</span>
                  <span class="text_26">与自有数据合并，以OneID技术统一客户视图</span>
                </div>
              </div>
              <img src="@/res/remote/qby_picture_wxsqfssb.png" class="image_27" />
              <div @click="goOtherPageFun('/Intent-cloud.html')" class="top-section flex-row view_20">
                <span>了解更多</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 right" />
              </div>
            </template>

            <span class="text_47">全场景数字化营销方案</span>
          </div>
          <div class="flex-col group_28">
            <div @click="goOtherPageFun('/solution-marketing-abm.html')" class="flex-col section_6">
              <img src="/newHuoyan/assets/pc/images/solution_v2/solution_marketing_huiyi/1.png" class="image_29">
              <div class="flex-col group_29">
                <span class="text_48">ABM一站式增长</span>
                <span class="text_49 text_50">基于其独有的意图情报分析能力，搭配成熟的营销场景方案与营销自动化功能，助力B2B企业营销数字化升级。</span>
              </div>
              <div class="flex-row button">
                <span class="text_51">方案详情</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 image_31" />
              </div>
            </div>

            <div @click="goOtherPageFun('/marketing-digital-transformation.html')" class="flex-col section_7">
              <img src="/newHuoyan/assets/pc/images/solution_v2/solution_marketing_neirong/3.png" class="image_29">
              <div class="flex-col group_30">
                <span class="text_52">线索培育转化</span>
                <span class="text_49 text_53">通过多场景、个性化、自动化触达目标客户，激活企业自有数据价值，精细化运营，实现线索的快速转化。</span>
              </div>
              <div class="flex-row group_31">
                <span class="text_54">方案详情</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 image_33" />
              </div>
            </div>
            <div @click="goOtherPageFun('/marketing-digital-transformation.html')" class="flex-col section_7">
              <img src="/newHuoyan/assets/pc/images/solution_v2/solution_marketing_neirong/3.png" class="image_29">
              <div class="flex-col group_30">
                <span class="text_52">营销协同</span>
                <span class="text_49 text_53">为销售持续提供高意向目标客户与高价值销售情报，让销售积极参与市场营销，促进线索转化</span>
              </div>
              <div class="flex-row group_31">
                <span class="text_54">方案详情</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 image_33" />
              </div>
            </div>
            <div @click="goOtherPageFun('/solution-marketing-intention-intelligence-analysis.html')" class="flex-col section_9">
              <img src="/newHuoyan/assets/pc/images/solution_v2/solution_marketing_guanwang/4.png" class="image_29" />
              <div class="flex-col group_33">
                <span class="text_58">意图情报分析</span>
                <span class="text_59">通过分析客户的兴趣偏好与采购意向，实时获取线索情报，比竞争对手抢先一步拦截目标客户。</span>
              </div>
              <div class="flex-row group_34">
                <span class="text_60">方案详情</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 image_37" />
              </div>
            </div>

            <div @click="goOtherPageFun('/solution-marketing-website.html')" class="flex-col section_9">
              <img src="/newHuoyan/assets/pc/images/solution_v2/solution_marketing_guanwang/4.png" class="image_29" />
              <div class="flex-col group_33">
                <span class="text_58">官网营销</span>
                <span class="text_59">官网安装识别雷达，发现更多的高意向客户与营销机会，更快转化目标客户。</span>
              </div>
              <div class="flex-row group_34">
                <span class="text_60">方案详情</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 image_37" />
              </div>
            </div>

            <div @click="goOtherPageFun('/solution-marketing-meeting.html')" class="flex-col section_8">
              <img src="@/res/local/4ceb0e73b6bcc0ca9dc791dadfedd4c1.png" class="image_29" />
              <div class="flex-col group_32">
                <span class="text_55">会议营销</span>
                <span class="text_49 text_56">体验一站式活动获客链路 <br />市场营销从未如此简单 </span>
              </div>
              <div class="flex-row button_1">
                <span class="text_57">方案详情</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 image_35" />
              </div>
            </div>

            <div @click="goOtherPageFun('/solution-marketing-wechat.html')" class="flex-col section_11">
              <img src="/newHuoyan/assets/pc/images/solution_v2/solution_marketing_siyu/1.png" class="image_29" />
              <div class="flex-col group_36">
                <span class="text_64">私域营销</span>
                <span class="text_49 text_65">基于微信生态的低成本增长方案 <br />构筑全域营销流量池</span>
              </div>
              <div class="flex-row button_3">
                <span class="text_66">方案详情</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 image_41" />
              </div>
            </div>

            <div @click="goOtherPageFun('/solution-marketing-content.html')" class="flex-col section_11">
              <img src="/newHuoyan/assets/pc/images/solution_v2/solution_marketing_siyu/1.png" class="image_29" />
              <div class="flex-col group_36">
                <span class="text_64">内容营销</span>
                <span class="text_49 text_65">基于客户全域浏览行为识别，搭载个性化内容推送，实现高效内容获客</span>
              </div>
              <div class="flex-row button_3">
                <span class="text_66">方案详情</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 image_41" />
              </div>
            </div>

            <div @click="goOtherPageFun('/solution-marketing-advertising.html')" class="flex-col section_10">
              <img src="/newHuoyan/assets/pc/images/index/hp_yyfa_ggjc@2x.png" class="image_29" />
              <div class="flex-col items-center group_35">
                <span class="text_61">广告营销</span>
                <span class="text_49 text_50">数字广告的全渠道投放与监测分析,将目标客户感兴趣的内容及时呈现在他们的面前。</span>
              </div>
              <div class="justify-center button_2">
                <span>方案详情</span>
                <img src="@/res/local/16376631404557442233.png" class="image_3 image_39" />
              </div>
            </div>

          </div>
          <div class="flex-col group_37">
            <span class="text_67">行业解决方案</span>
                <div style="position: relative;">
                  <div class="swiper mySwiper">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <div class="flex-col group_38">
                        <div class="top-section_1Par">
                          <div class="top-section_1 flex-row view_21" @click="topsection_1 = !topsection_1">
                              <div class="flex-col group_39">
                                <div class="top-group_2 flex-col">
                                  <span>软件SaaS</span>
                                  <div class="bottom-section"></div>
                                </div>
                                <span class="text_69">发现全量目标客户后基于目标客户的跨渠道营销自动化，实现规模化营销增长</span>
                              </div>
                              <img :class="topsection_1?'transform180':''" src="@/res/local/updown_mobile.svg" class="image_7 right_1" />
                          </div>
                          <div  @click="goOtherPageFun('/form.html')" class="top-section_2" v-if="topsection_1">
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              获取全量目标客户</div>
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              打通公域私域数据</div>
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              全渠道触达潜在客户</div>
                              <img class="top-section_2_img jjfa_img1" src="@/res/local/jjfa_img1.png" alt="">
                          </div>
                        </div>
                        <div class="top-section_1Par">
                          <div class="top-section_1 flex-row" @click="topsection_2=!topsection_2">
                            <div class="flex-col group_40">
                              <div class="top-group_2 flex-col">
                                <span>工业制造</span>
                                <div class="bottom-section"></div>
                              </div>
                              <span class="text_69">捕捉更多高意向线索，降低获客成本，增强客户体验</span>
                            </div>
                            <img :class="topsection_2?'transform180':''"  src="@/res/local/updown_mobile.svg" class="image_7 right_1" />
                          </div>
                          <div @click="goOtherPageFun('/form.html')" class="top-section_2" v-if="topsection_2">
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              打通企业多源数据</div>
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              线索全生命周期营销</div>
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              销售线索意向洞察</div>
                              <img class="top-section_2_img jjfa_img2" src="@/res/local/jjfa_img2.png" alt="">
                          </div>
                        </div>

                        <div class="top-section_1Par">
                          <div class="top-section_1 flex-row" @click="topsection_3=!topsection_3">
                            <div class="flex-col group_41">
                              <div class="top-group_2 flex-col">
                                <span>企业服务</span>
                                <div class="bottom-section"></div>
                              </div>
                              <span class="text_69">挖掘更多潜在市场，并快速营销高意向潜在客户</span>
                            </div>
                            <img :class="topsection_3?'transform180':''" src="@/res/local/updown_mobile.svg" class="image_7 right_1" />
                          </div>
                          <div @click="goOtherPageFun('/form.html')" class="top-section_2" v-if="topsection_3">
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              发现更多潜在市场</div>
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              商机生成后及时跟进</div>
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              自动培育加速转化</div>
                              <img class="top-section_2_img jjfa_img3" src="@/res/local/jjfa_img5.png" alt="">
                          </div>
                        </div>

                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="flex-col group_38">
                        <div class="top-section_1Par">
                          <div class="top-section_1 flex-row view_21" @click="topsection_4=!topsection_4">
                              <div class="flex-col group_39">
                                <div class="top-group_2 flex-col">
                                  <span>政务服务</span>
                                  <div class="bottom-section"></div>
                                </div>
                                <span class="text_69">锁定精准目标客户，提升市场获客与转化效率</span>
                              </div>
                              <img :class="topsection_4?'transform180':''" src="@/res/local/updown_mobile.svg" class="image_7 right_1" />
                          </div>
                          <div @click="goOtherPageFun('/form.html')" class="top-section_2" v-if="topsection_4">
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              潜在目标客户挖掘</div>
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              企业及关键决策人行为意图洞察</div>
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              精准营销内容覆盖</div>
                              <img class="top-section_2_img jjfa_img4" src="@/res/local/jjfa_img6.png" alt="">
                          </div>
                        </div>
                        <div class="top-section_1Par">
                          <div class="top-section_1 flex-row" @click="topsection_5=!topsection_5">
                            <div class="flex-col group_40">
                              <div class="top-group_2 flex-col">
                                <span>开发者服务</span>
                                <div class="bottom-section"></div>
                              </div>
                              <span class="text_69">精准锁定目标客户及关键决策人，千人千面的内容营销</span>
                            </div>
                            <img :class="topsection_5?'transform180':''" src="@/res/local/updown_mobile.svg" class="image_7 right_1" />
                          </div>
                          <div @click="goOtherPageFun('/form.html')" class="top-section_2" v-if="topsection_5">
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              发现更多购买决策人</div>
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              降低采购决策周期</div>
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              更多的向上/交叉销售</div>
                              <img class="top-section_2_img jjfa_img5" src="@/res/local/jjfa_img3.png" alt="">
                          </div>
                        </div>

                        <div class="top-section_1Par">
                          <div class="top-section_1 flex-row" @click="topsection_6=!topsection_6">
                            <div class="flex-col group_41">
                              <div class="top-group_2 flex-col">
                                <span>媒体展会</span>
                                <div class="bottom-section"></div>
                              </div>
                              <span class="text_69">找到符合画像的参展商和观众，扩大活动影响力</span>
                            </div>
                            <img :class="topsection_6?'transform180':''" src="@/res/local/updown_mobile.svg" class="image_7 right_1" />
                          </div>
                          <div @click="goOtherPageFun('/form.html')" class="top-section_2" v-if="topsection_6">
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              找到匹配的观众</div>
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              活动品牌精准宣传</div>
                              <div class=""><img src="@/res/local/hyjjfa_mobile.svg" alt="">
                              会议数字化管理</div>
                              <img class="top-section_2_img jjfa_img6" src="@/res/local/jjfa_img4.png" alt="">
                          </div>
                        </div>

                        </div>
                      </div>
                    </div>
                    <div class="swiper-pagination"></div>
                  </div>
                </div>




            <span class="text_75">众多知名品牌的选择</span>
          </div>
          <div class="flex-col items-center image-wrapper_2">
            <ul>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_wangyi@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_ximenzi@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_3M@2x.png" alt="">

              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_zijietiaodong@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_AWS@2x.png" alt="">

              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_zhongkeshuguang@2x.png" alt="">

              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_Impact@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_Cytiva@2x.png" alt="">

              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_huacejiance@2x.png" alt="">

              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_Cloudpick@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_dongfangzhongke@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_fangtewang@2x.png" alt="">

              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_IQair@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_tuya@2x.png" alt="">
              </li>
                <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_jindie@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_dell@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_zhongdianjinxin@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_xiyueer@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_xuanwuyun@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_saimofei@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_Honeywell@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_haifeixiu@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_Thomas@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_shichangyi@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_kennametal@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_fanruan@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_shangmi@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_kenkun@2x.png" alt="">
              </li>
              <li>
                <img src="/newHuoyan/assets/pc/images/index/logo_Allianture@2x.png" alt="">
              </li>
            </ul>
          </div>
          <div class="flex-col group_42">
            <span class="text_76">理论到实践，提供营销<br />人最优质的干货内容</span>
            <div v-for="(item,index) in newList" :key="index" @click="goOtherPageFun('/detail/204.html')" class="flex-row group_43">
              <img :src="item.image" class="image_47" />
              <div class="flex-col group_44">
                <span class="text_77 newListTitle">{{item.title}}</span>
                <div class="justify-between group_45">
                  <div class="left-text-wrapper_1 flex-col">
                    <span class="text_78">最新动态</span>
                  </div>
                  <!-- <span class="text_80">阅读时长：2分钟</span> -->
                </div>
              </div>
            </div>


            <!-- <div @click="goOtherPageFun('/detail/204.html')" class="flex-row group_43">
              <img src="@/res/local/content_01.png" class="image_47" />
              <div class="flex-col group_44">
                <span class="text_77">2022 ToB数字营销趋势大会圆满举办，共创无边界ABM生态！</span>
                <div class="justify-between group_45">
                  <div class="left-text-wrapper_1 flex-col">
                    <span class="text_78">最新动态</span>
                  </div>
                  <span class="text_80">阅读时长：2分钟</span>
                </div>
              </div>
            </div> -->
            <!-- <div @click="goOtherPageFun('/detail/199.html')" class="flex-row group_46">
              <img src="@/res/remote/810ab246b7c6c31d15603e12cb7e59a6.png" class="image_47" />
              <div class="flex-col group_47">
                <span class="text_81">获客成本降低50%，知产企业“破圈“营销 </span>
                <div class="justify-between group_48">
                  <div class="left-text-wrapper_1 flex-col">
                    <span class="text_78">客户案例</span>
                  </div>
                  <span class="text_83">阅读时长：2分钟</span>
                </div>
              </div>
            </div> -->
            <!-- <div @click="goOtherPageFun('/detail/200.html')" class="flex-row group_49">
              <img src="@/res/remote/fd8409c5b61bb0b666730bbc56b8d887.png" class="image_47" />
              <div class="flex-col group_50">
                <span class="text_84">史上最“奇葩”客户需求，居然真能有人搞定？</span>
                <div class="justify-between group_51">
                  <div class="left-text-wrapper_1 flex-col">
                    <span class="text_78">最佳实践</span>
                  </div>
                  <span class="text_86">阅读时长：2分钟</span>
                </div>
              </div>
            </div> -->
            <!-- <div @click="goOtherPageFun('/detail/198.html')" class="flex-row group_52">
              <img src="@/res/remote/4e4bb1226eccc3939b0647d200d0649d.png" class="image_47" />
              <div class="flex-col group_53">
                <span class="text_87">4大技巧，高效提升B2B数字广告ROI</span>
                <div class="justify-between group_54">
                  <div class="left-text-wrapper_1 flex-col">
                    <span class="text_78">营销策略</span>
                  </div>
                  <span class="text_89">阅读时长：2分钟</span>
                </div>
              </div>
            </div> -->
          </div>
          <!-- <div class="flex-col group_55">
            <div class="flex-col section_12">
              <span class="text_90">留下您的联系方式<br />专属顾问会尽快与您联系</span>
              <div class="search justify-between section_13">
                <img src="@/res/local/16376631404543724564.png" class="image_7 image_7_1" />
                <input v-model="input2" class="left-group flex-row" type="text" placeholder="请输入您的手机号码">
                <div @click="goformPageFun(2)" class="right-text-wrapper flex-col items-center">
                  <span>免费试用</span>
                </div>
              </div>
              <div class="group_56">
                <span class="text_93">如有疑问，欢迎致电客服热线</span><br />
                <span class="text_94">400-8521-789</span>
              </div>
            </div>
            <div class="flex-col group_57">
              <div class="section_14"></div>
              <div class="justify-between section_15">
                <div class="flex-col group_58">
                  <div class="group_59">
                    <span class="text_95">电话：</span>
                    <span class="text_96">400-8521-789</span>
                    <span class="text_97">邮箱： </span>
                    <span class="text_98">marketing@huoyan.cn</span>
                  </div>
                  <span class="text_99">©2017 北京绘客科技有限公司 <br />京ICP备16023115号-1 隐私政策</span>
                </div>
                <img src="@/res/remote/06c5f76966fbb88f09c7d54f941ea701.png" class="image_53" />
              </div>
              <img src="@/res/local/52ca3200bc469c20b13881eecea2030c.svg" class="image_54" />
            </div>
          </div> -->
          <v-footer></v-footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swiper from 'swiper';
import vHeader from '@/components/header.vue'
import vFooter from '@/components/footer.vue'
  export default {
    data() {
      return {
        group_13Show:1,
        group_19Show:1,
        group_26Show:1,
        topsection_1:false,
        topsection_2:false,
        topsection_3:false,
        topsection_4:false,
        topsection_5:false,
        topsection_6:false,
        input1:'',
        input2:'',
        newList:[]
      }
    },
    components: {
      vFooter,vHeader
    },
    mounted(){
      let _this = this
      var swiper = new Swiper(".mySwiper", {
        pagination: {
          el: ".swiper-pagination",
        },
        on:{
          slideChange: function(){
            _this.topsection_1 = false
            _this.topsection_2 = false
            _this.topsection_3 = false
            _this.topsection_4 = false
            _this.topsection_5 = false
            _this.topsection_6 = false
          }
        }
        // loop:true
      });
      // this.vidoeinitFun()
      this.getInitList()
    },
    methods: {
      showgroup13fun(val){
        this.group_13Show=val
      },
      showgroup19fun(val){
        this.group_19Show=val
      },
      showgroup26fun(val){
        this.group_26Show=val
      },
      goformPageFun(){
        var val = this.input1
        var mobileReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if(!val){
          window.location.href= '/form.html?mobile='
        }else if(mobileReg.test(val)){
          window.location.href= '/form.html?mobile='+val
        }else{
          alert('手机格式错误!')
        }
      },
      goOtherPageFun(path){
        window.location.href = path
      },
      vidoeinitFun(){
        let myPlayer = this.$video(example_video_1, {
            //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
            // controls: true,
            // poster: 'http://192.168.234.116:8110//Picture/position/banner.jpg', //封面
            //自动播放属性,muted:静音播放
            autoplay: "muted",
            //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
            preload: "auto",
            //设置视频播放器的显示宽度（以像素为单位）
            width: "100%",
            fluid:true,
            loop:true
            //设置视频播放器的显示高度（以像素为单位）
            // height: "180px"
          });
      },

      getInitList(){
        let _this = this;
        axios.get('/ajax/index_lists')
        .then(function(response){

          _this.newList = response.data
          console.log("this.newList",_this.newList);
        })
        .catch(function(err){
          console.log(err);
        });
      }
    }
  };
</script>

<style scoped lang="css">
/deep/.swiper-pagination-bullet-active{
  background-color: #FF6A00;
}
.video_parent{
  position: relative;
  width: 100%;
  height: 14.38rem;
}
.video_parent img{
  width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.video_parent video{
    width: 100%;
    position: absolute;
    top: 0;
}
video{
/* margin-top: 1.5rem; */
}
      .swiper {
        width: 100%;
        overflow-x: hidden;
        padding-bottom: 1rem;
      }

      .swiper-slide {

      }

.container .mySwiper .swiper-pagination{
    bottom:-1rem;
}
.transform180{
  transition: transform 0.2s;
  transform: rotate(180deg);
}
  .container .groupActive{
    background-color: rgba(255, 106, 0);
    color: rgba(255, 255, 255);
  }
  .image_3 {
    width: 1rem;
    height: 1rem;
  }
  .search {
    position: relative;
    height: 49px;
    /* padding: 0.5rem 0.31rem 0.44rem 0.56rem; */
    filter: drop-shadow(0px 0.88rem 0.66rem #1c25330d);
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    border: solid 0.063rem rgb(222, 222, 222);
  }
  .search .image_7_1{
    position: absolute;
    top: 0.7rem;
    left:.3125rem
  }
   .search input{
     width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 10px;
    padding-left:2rem;
   }
  .left-text-wrapper {
    flex: 1 1 7.75rem;
    color: rgb(38, 38, 38);
    font-size: 0.75rem;
    line-height: 1.06rem;
    white-space: nowrap;
    padding: 1rem 0 0.94rem;
    background-color: rgba(255, 255, 255);
    height: 3rem;
    border: solid 0.063rem rgb(237, 237, 237);
  }
  .center-text-wrapper {
    flex: 1 1 7.75rem;
    color: rgb(38, 38, 38);
    font-size: 0.75rem;
    line-height: 1.06rem;
    white-space: nowrap;
    padding: 0.94rem 0 0.88rem;
    background-color: rgb(255, 255, 255);
    height: 3rem;
    border: solid 0.063rem rgb(237, 237, 237);
  }
  .top-group {
    padding: 0 1.25rem;
  }
  .right-text-wrapper {
    position: absolute;
    right:.625rem;
    top: 0.4rem;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      102.2deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 81.34%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(255, 65, 65, 0.5);
    border-radius: 0.25rem;
    width: 5rem;
    height: 2rem;
  }
  .image_7 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .bottom-group {
    margin-top: 1rem;
    color: rgb(35, 35, 35);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5rem;
    white-space: nowrap;
    padding: 0 0.56rem;
  }

  .jjfa_img1{
    width: 14.0625rem;
  }
    .jjfa_img2{
      width: 17.5rem;
  }
    .jjfa_img3{
    width: 17.5rem;
  }
    .jjfa_img4{
    width: 17.5rem;
  }
    .jjfa_img5{
    width: 17.5rem;
  }
    .jjfa_img6{
    width: 17.5rem;
  }
  .image_13 {
    width: 2rem;
    height: 2rem;
  }
  .image_15 {
    align-self: center;
    width: 0.88rem;
    height: 4.75rem;
  }
  .top-section {
    align-self: center;
    padding: 0.44rem 1.19rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.13rem;
    border: solid 0.063rem rgb(233, 233, 233);
  }
  .image_29 {
    align-self: center;
    width: 5rem;
    height: 5rem;
  }
  .top-section_1Par{
    box-shadow: 0px 0.13rem 1.13rem 0px rgb(243, 244, 246);
  }
  .top-section_1 {
    margin-top: 0.63rem;
    padding: 0.81rem 1rem 1.06rem 1.38rem;
    background-color: rgb(255, 255, 255);

    border-radius: 0.25rem;
  }
  .top-section_2 {
    padding: 0 1rem 1.06rem 1.38rem;
    font-size: 14px;
    color: #595959;
  }
  .top-section_2 div{
    line-height: 1.5rem;
  }
  .top-section_2_img{
    margin-top: 1rem;
  }
  .image_47 {
    width: 7.5rem;
    height: 5rem;
  }
  .text_49 {
    justify-content: center;
    margin-top: 1rem;
    text-align: center;
    width: 100%;
  }
  .left-text-wrapper_1 {
    padding: 0.19rem 0 0.13rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.13rem;
    height: 1.5rem;
    border: solid 0.063rem rgb(214, 214, 214);
  }
  .bottom-section {
    margin-top: 0.41rem;
    background-color: rgb(236, 236, 236);
    width: 1.5rem;
    height: 0.19rem;
  }
  .top-group_1 {
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .top-group_2 {
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .left-group {
    margin: 0.19rem 0 0.31rem;
    color: rgb(199, 199, 199);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_4 {
    margin-left: 0.81rem;
    margin-top: 0.19rem;
  }
  .right-group {
    margin-left: 1rem;
    flex: 1 1 auto;
  }
  .right {
    margin-left: 0.75rem;
  }
  .text_24 {
    margin-top: 0.5rem;
  }
  .text_26 {
    margin-top: 0.5rem;
  }
  .right_1 {
    margin-left: 1.25rem;
    margin-top: 2.19rem;
  }
  .text_69 {
    margin-top: 0.47rem;
    color: rgb(35, 35, 35);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_78 {
    margin: 0 0.31rem;
  }
  .container {
    padding-top:3.5rem;
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
  }
  .header {
    padding: 0.84rem 0.88rem 0.72rem 2.19rem;
    color: rgb(0, 0, 0);
    font-size: 0.94rem;
    line-height: 1.13rem;
    letter-spacing: -0.019rem;
    white-space: nowrap;
    height: 2.69rem;
  }
  .group_1 {
    flex: 1 1 auto;
    overflow-y: auto;
  }
  .group {
    margin: 0.19rem 0 0.22rem;
  }
  .section_1 {
    padding: 0.88rem 1rem 0.81rem;
    color: rgb(255, 106, 0);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(0, 0, 0, 0.03);
  }
  .group_3 {
    margin-top: 2rem;
  }
  .image {
    width: 1.06rem;
    height: 0.69rem;
  }
  .image_1 {
    margin-left: 0.28rem;
    width: 1rem;
    height: 0.69rem;
  }
  .image_2 {
    margin-left: 0.28rem;
    width: 1.53rem;
    height: 0.72rem;
  }
  .group_2 {
    align-self: center;
  }
  .text-wrapper {
    padding: 0.44rem 0;
    background-color: rgba(255, 106, 0, 0.05);
    border-radius: 1rem;
    width: 5rem;
    height: 2rem;
  }
  .group_4 {
    padding-left: 1.69rem;
    padding-right: 1.63rem;
  }
  .group_13 {
    margin-top: 1.06rem;
  }
  .group_14 {
    margin-top: 1.25rem;
  }
  .group_19 {
    margin-top: 1.06rem;
  }
  .group_20 {
    margin-top: 1.25rem;
    padding-left: 0.94rem;
    padding-right: 0.88rem;
  }
  .group_26 {
    margin-top: 1.06rem;
  }
  .group_27 {
    margin-top: 1.25rem;
  }
  .image_4 {
    align-self: center;
  }
  .image_5 {
    margin-left: 1.5rem;
    width: 4.94rem;
    height: 1.5rem;
  }
  .text_2 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_3 {
    margin-top: 1rem;
    align-self: center;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .image_6 {
    margin-top: 1.5rem;
    align-self: center;
    width: 17.5rem;
    /* height: 14.38rem; */
  }
  .section_2 {
    margin-top: 1.69rem;
  }
  .group_5 {
    margin-top: 2rem;
    padding: 0 0.69rem;
  }
  .group_11 {
    margin-top: 3.75rem;
  }
  .group_12 {
    margin-top: 3.06rem;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text-wrapper_1 {
    padding: 0.94rem 0 0.88rem;
    flex: 1 1 7.75rem;
    color: rgb(38, 38, 38);
    font-size: 0.75rem;
    /* line-height: 0.63rem; */
    background-color: rgb(255, 255, 255);
    height: 3rem;
    border: solid 0.063rem rgb(237, 237, 237);
  }
  .group_15 {
    margin-top: 1rem;
  }
  .group_21 {
    padding: 0 0.31rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .group_22 {
    margin-top: 1rem;
    padding: 0 0.88rem;
    color: rgb(35, 35, 35);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5rem;
    white-space: nowrap;
  }
  .group_24 {
    margin-top: 1rem;
    color: rgb(61, 61, 61);
    font-size: 0.81rem;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .group_25 {
    margin-top: 3.81rem;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_28 {
    margin-top: 2rem;
    /* height: 111.94rem; */
  }
  .group_37 {
    margin-top: 3.75rem;
    padding-left: 1.19rem;
    padding-right: 1.06rem;
  }
  .image-wrapper_2 {
    margin-top: 1.63rem;
    padding: 1.81rem 0 3.44rem;
    background-color: rgb(249, 250, 252);
  }
  .group_42 {
    margin-top: 3.75rem;
    padding: 0 1rem;
  }
  .group_55 {
    margin-top: 3.75rem;
  }
  .section_3 {
    box-shadow: 0px 2px 19px 0px rgba(227, 227, 227, 0.5);
    padding: 1.25rem 0.5rem 0.5rem 3.56rem;
    /* filter: drop-shadow(0px 0.81rem 0.81rem #5d5d5d19); */
    overflow: hidden;
    border-radius: 0.63rem;
    background-image: linear-gradient(
      180deg,
      rgb(250, 250, 251) 0%,
      rgb(250, 250, 251) 0%,
      rgb(243, 244, 246) 100%,
      rgb(243, 244, 246) 100%
    );
    height: 8.75rem;
    border: solid 0.25rem rgb(255, 255, 255);
  }
  .section_4 {
    margin-top: 1.25rem;
    /* padding: 1.25rem 0 0.5rem 1.38rem; */
    padding: 1.25rem 0 0.5rem 0;
    box-shadow: 0px 2px 19px 0px rgba(227, 227, 227, 0.5);
    overflow: hidden;
    border-radius: 0.63rem;
    background-image: linear-gradient(
      180deg,
      rgb(250, 250, 251) 0%,
      rgb(250, 250, 251) 0%,
      rgb(243, 244, 246) 100%,
      rgb(243, 244, 246) 100%
    );
    height: 8.75rem;
    border: solid 0.25rem rgb(255, 255, 255);
  }
  .section_5 {
    margin-top: 1.31rem;
    padding: 1.25rem 0 0.5rem 0.38rem;
    box-shadow: 0px 2px 19px 0px rgba(227, 227, 227, 0.5);
    overflow: hidden;
    border-radius: 0.63rem;
    background-image: linear-gradient(
      180deg,
      rgb(250, 250, 251) 0%,
      rgb(250, 250, 251) 0%,
      rgb(243, 244, 246) 100%,
      rgb(243, 244, 246) 100%
    );
    height: 8.75rem;
    border: solid 0.25rem rgb(255, 255, 255);
  }
  .text_16 {
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_17 {
    margin-top: 1rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .image_12 {
    margin: 0.13rem 0;
  }
  .text_18 {
    margin-left: 0.94rem;
  }
  .text_21 {
    text-align: center;
    width: 6rem;
  }
  .group_17 {
    margin-top: 1rem;
  }
  .text_33 {
    margin-left: 0.63rem;
    margin-top: 0.75rem;
  }
  .group_23 {
    margin-left: 1rem;
    flex: 1 1 auto;
  }
  .image-wrapper_1 {
    padding-bottom: 0.94rem;
  }
  .image_24 {
    margin-bottom: 0.19rem;
    width: 1.5rem;
    height: 1.56rem;
  }
  .text_38 {
    margin-left: 1rem;
  }
  .image_27 {
    margin-top: 1rem;
    align-self: center;
    width: 20rem;
    height: 12.5rem;
  }
  .view_20 {
    margin-top: 1.94rem;
    color: rgb(61, 61, 61);
    font-size: 0.81rem;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .text_47 {
    margin-top: 4.13rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .section_6 {
    padding: 1.5rem 2.94rem 1.38rem 2.94rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.25rem 0 0 0.25rem;
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_7 {
    padding: 1.56rem 2.94rem 1.31rem 2.94rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_8 {
    padding: 1.5rem 2.88rem 1.25rem 2.88rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_9 {
    padding: 1.94rem 2.5rem 1.25rem 2.5rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_10 {
    padding: 1.94rem 0 1.25rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_11 {
    padding: 1.94rem 2.94rem 1.38rem 2.94rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .text_67 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_38 {
    margin-top: 1.5rem;
    padding:0 0.5rem;
  }
  .image_45 {
    margin-top: 1rem;
    align-self: center;
    width: 1.25rem;
    height: 0.38rem;
  }
  .text_75 {
    margin-top: 3.75rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .image_46 {
    width: 19.06rem;
    height: 29.38rem;
  }
  .text_76 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    text-align: center;
    /* width: 12.5rem; */
  }
  .group_43 {
    margin-top: 2rem;
  }
  .group_46 {
    margin-top: 2rem;
  }
  .group_49 {
    margin-top: 2rem;
  }
  .group_52 {
    margin-top: 2rem;
  }
  .section_12 {
    padding: 1.94rem 1.69rem 1.31rem 1.75rem;
    background-color: rgb(249, 250, 252);
  }
  .group_57 {
    padding-bottom: 14.75rem;
    position: relative;
  }
  .text_7 {
    color: rgb(35, 35, 35);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.38rem;
    white-space: nowrap;
  }
  .text_8 {
    /* margin-top: 1.19rem; */
    margin-top: 0.56rem;
    color: rgb(89, 89, 89);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .group_6 {
    margin-top: 1.44rem;
    padding: 0 3.31rem;
    color: #ff6a00;
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .group_7 {
    /* margin-right: -0.5rem; */
    text-align: center;
  }
  .group_8 {
    /* margin-right: -0.5rem; */
    margin-top: 1.44rem;
    color: #ff6a00;
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
    text-align: center;
    justify-content: center;
  }
  .group_9 {
    margin-right: -0.5rem;
  }
  .group_10 {
    margin-right: -0.5rem;
    /* margin-top: 2.06rem; */
    margin-top: 1.44rem;
    color: #ff6a00;
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_22 {
    margin: 0.75rem 1.44rem 0 0.63rem;
  }
  .image-wrapper {
    margin-left: 0.75rem;
    margin-right: 0.81rem;
  }
  .view_8 {
    color: rgb(61, 61, 61);
    font-size: 0.81rem;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .group_18 {
    margin-top: 3.81rem;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_35 {
    margin-top: 0.5rem;
  }
  .text_36 {
    margin-top: 0.5rem;
  }
  .image_22 {
    width: 92.5vw;
    height: 57.5vw;
  }
  .text_42 {
    margin-left: 0.63rem;
    margin-top: 0.75rem;
  }
  .group_29 {
    margin-top: 1.25rem;
  }
  .button {
    width: 100%;
    justify-content: center;
    margin-top: 2.06rem;
    color: rgb(61, 61, 61);
    font-size: 1rem;
    line-height: 1.38rem;
    white-space: nowrap;
  }
  .group_30 {
    margin-top: 1.25rem;
  }
  .group_31 {
    display: flex;
    justify-content: center;
    margin-top: 2.19rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .group_32 {
    margin-top: 1.25rem;
  }
  .button_1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2.19rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .group_33 {
    margin-top: 1.25rem;
  }
  .group_34 {
    width: 100%;
    justify-content: center;
    margin-top: 1.75rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .group_35 {
    /* margin-top: 1.25rem; */
  }
  .button_2 {
    margin-top: 1.75rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .group_36 {
    margin-top: 1.25rem;
  }
  .button_3 {
    width: 100%;
    justify-content: center;
    margin-top: 1.75rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .view_21 {
    margin-top: 0;
  }
  .group_44 {
    margin-left: 0.69rem;
    flex: 1 1 auto;
  }
  .group_47 {
    margin-left: 0.69rem;
    flex: 1 1 auto;
  }
  .group_50 {
    margin-left: 0.69rem;
    flex: 1 1 auto;
  }
  .group_53 {
    margin-left: 0.69rem;
    flex: 1 1 auto;
  }
  .text_90 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.13rem;
    font-weight: 500;
    line-height: 1.8rem;
    text-align: center;
    width: 14.38rem;
  }
  .section_13 {
    margin-top: 0.88rem;
  }
  .group_56 {
    margin-top: 1.13rem;
    align-self: center;
    text-align: center;
    width: 14.38rem;
  }
  .section_14 {
    background-color: rgb(31, 41, 55);
    height: 2.06rem;
  }
  .section_15 {
    padding: 3.25rem 1.06rem 2.63rem 1.44rem;
    background-color: rgb(31, 41, 55);
    position: absolute;
    left: 0;
    right: 0;
    top: 2.06rem;
  }
  .image_54 {
    width: 7.25rem;
    height: 2.25rem;
    position: absolute;
    left: 1.44rem;
    top: 1.44rem;
  }
  .image_9 {
    margin: 0.13rem 0 0.13rem 0.5rem;
  }
  .text_10 {
    color: rgb(35, 35, 35);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.38rem;
    white-space: nowrap;
  }
  .text_11 {
    margin-top: 0.56rem;
    align-self: center;
    color: rgb(89, 89, 89);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_12 {
    /* margin-left: 5.5rem; */
  }
  .image_10 {
    margin: 0.13rem 0 0.13rem 0.5rem;
  }
  .text_13 {
    color: rgb(35, 35, 35);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.38rem;
    white-space: nowrap;
  }
  .text_14 {
    margin-top: 0.56rem;
    color: rgb(89, 89, 89);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .image_11 {
    margin: 0.13rem 0 0.13rem 0.5rem;
  }
  .image_17 {
    width: 93.5vw;
    height: 57.5vw;
  }
  .image_19 {
    margin-bottom: 0.19rem;
  }
  .text_29 {
    margin-left: 1rem;
  }
  .text_48 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_50 {
    color: rgb(38, 38, 38);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
  .text_51 {
    /* margin-left: 4.25rem; */
  }
  .image_31 {
    margin: 0.25rem 0 0.13rem 0.5rem;
  }
  .text_52 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_53 {
    color: rgb(89, 89, 89);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
  .text_54 {
    /* margin-left: 4.5rem; */
  }
  .image_33 {
    margin: 0.13rem 0 0.13rem 0.5rem;
  }
  .text_55 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_56 {
    color: rgb(38, 38, 38);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
  .text_57 {
    /* margin-left: 4.5rem; */
  }
  .image_35 {
    margin: 0.13rem 0 0.13rem 0.5rem;
  }
  .text_58 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_59 {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    color: rgb(38, 38, 38);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: center;
    width: 100%;
  }
  .text_60 {
    /* margin-left: 5rem; */
  }
  .image_37 {
    margin: 0.13rem 0 0.13rem 0.5rem;
  }
  .text_61 {
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_62 {
    margin-top: 1rem;
    color: rgb(38, 38, 38);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: center;
    width: 9.38rem;
  }
  .image_39 {
    margin: 0.13rem 0 0.13rem 0.5rem;
  }
  .text_64 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_65 {
    color: rgb(38, 38, 38);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
  .text_66 {
    /* margin-left: 4.5rem; */
  }
  .image_41 {
    margin: 0.13rem 0 0.13rem 0.5rem;
  }
  .group_39 {
    flex: 1 1 auto;
  }
  .group_40 {
    flex: 1 1 auto;
  }
  .group_41 {
    flex: 1 1 auto;
  }
  .text_77 {
    margin-right: 0.38rem;
    color: rgb(35, 35, 35);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
  }
  .group_45 {
    margin-top: 1.13rem;
    color: rgb(140, 140, 140);
    font-size: 0.75rem;
    line-height: 1.06rem;
    white-space: nowrap;
  }
  .text_81 {
    margin-right: 0.38rem;
    color: rgb(35, 35, 35);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
  }
  .group_48 {
    margin-top: 1.13rem;
    color: rgb(140, 140, 140);
    font-size: 0.75rem;
    line-height: 1.06rem;
    white-space: nowrap;
  }
  .text_84 {
    color: rgb(35, 35, 35);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
  }
  .group_51 {
    margin-top: 1.13rem;
    color: rgb(140, 140, 140);
    font-size: 0.75rem;
    line-height: 1.06rem;
    white-space: nowrap;
  }
  .text_87 {
    color: rgb(35, 35, 35);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .group_54 {
    margin-top: 2.31rem;
    color: rgb(140, 140, 140);
    font-size: 0.75rem;
    line-height: 1.06rem;
    white-space: nowrap;
  }
  .text_93 {
    color: rgb(137, 137, 137);
    font-size: 0.88rem;
    line-height: 1.5rem;
  }
  .text_94 {
    color: rgb(255, 106, 0);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.38rem;
  }
  .group_58 {
    width: 13.44rem;
  }
  .image_53 {
    margin-top: 2.63rem;
    width: 6.25rem;
    height: 6.25rem;
  }
  .text_80 {
    margin: 0.25rem 0 0.19rem;
  }
  .text_83 {
    margin: 0.25rem 0 0.19rem;
  }
  .text_86 {
    margin: 0.25rem 0 0.19rem;
  }
  .text_89 {
    margin: 0.25rem 0 0.19rem;
  }
  .group_59 {
    text-align: left;
    width: 11.56rem;
  }
  .text_99 {
    margin-top: 2.19rem;
    color: rgb(140, 140, 140);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: left;
  }
  .text_95 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 2.19rem;
  }
  .text_96 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 2.19rem;
  }
  .text_97 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 1.25rem;
  }
  .text_98 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 1.25rem;
  }
  .newListTitle{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .image-wrapper_2 ul{
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 16px;
  }
  .image-wrapper_2 ul li {
    list-style: none;
    width: 30%;
    margin: 4px;
  }
  .image-wrapper_2 ul li img{
    width: 100%;
  }
</style>
