<template>
  <div class="flex-col container">
    <v-header></v-header>
    <div class="about_us">
        <div class="banner1">
            <img src="@/res/about/banner1.png" alt="">
            <div class="banner1_txt">让B2B企业实现大规模获客增长</div>
        </div>
        <div class="banner2">
            <div class="banner2_line"></div>
            <div class="banner2_title">关于我们</div>
            <div class="banner2_txt1 banner2_txt">
                火眼云成立于2016年（隶属于：北京绘客科技有限公司），是一家营销增长加速平台，专注于为B2B企业提供目标客户营销 (ABM)解决方案及领先的数字化营销产品，致力于为B2B企业打造从获客到复购的营销闭环，现已为近百家明星B2B企业完成营销数字化转型。
            </div>
            <div class="banner2_txt2 banner2_txt">
                火眼云基于其独有的全域企业信息和职场人群行为情报，在快速获取目标客户并进行全渠道互动的同时，实现了全场景的OneID链接，帮助企业精准唤醒公域流量，实现私域流量长效增长，并配合成熟的行业营销场景方案与营销自动化技术，最大限度强化B2B企业的智能营销体系，帮助营销团队成为其公司业绩增长的驱动力。
            </div>
            <div class="banner2_img">
                <img src="https://marketing.cdn.kefenxi.com/website/company_office.png" alt="">
            </div>
        </div>
        <div class="banner3">
            <div class="banner3_title">团队价值观</div>
            <div class="banner3_content">
                <div class="banner3_content_item">
                    <span class="banner3_content_item_span1">企业愿景</span>
                    <span class="banner3_content_item_line"></span>
                    <span class="banner3_content_item_span2">成为数字链接者</span>
                </div>
                <div class="banner3_content_item">
                    <span class="banner3_content_item_span1">企业使命</span>
                    <span class="banner3_content_item_line"></span>
                    <span class="banner3_content_item_span2">让商业更智能</span>
                </div>
            </div>
            <div class="banner3_icon">
                <div class="banner3_icon_item">
                    <img src="@/res/about/banner3.png" alt="">
                    <div class="banner3_icon_item_txt">客户成长</div>
                </div>
                <div class="banner3_icon_item">
                    <img src="@/res/about/banner4.png" alt="">
                    <div class="banner3_icon_item_txt">勇于开拓</div>
                </div>
                <div class="banner3_icon_item">
                    <img src="@/res/about/banner5.png" alt="">
                    <div class="banner3_icon_item_txt">互为靠背</div>
                </div>
                <div class="banner3_icon_item">
                    <img src="@/res/about/banner6.png" alt="">
                    <div class="banner3_icon_item_txt">守文持正</div>
                </div>
            </div>
        </div>
        <div class="banner4">
            <div class="banner4_title">发展历程</div>
            <div class="banner4_content">
                <div class="banner4_content_item">
                    <div class="banner4_content_item_cicle">
                        <span></span>
                    </div>
                    <div class="banner4_content_item_line"></div>
                    <div class="banner4_content_item_txt">
                        <div class="banner4_content_item_txt_time">2016</div>
                        <div class="banner4_content_item_txt_thing">2016年1月  团队成立</div>
                        <div class="banner4_content_item_txt_thing">2016年5月  完成种子轮融资</div>
                    </div>
                </div>
                <div class="banner4_content_item">
                    <div class="banner4_content_item_cicle">
                        <span></span>
                    </div>
                    <div class="banner4_content_item_line"></div>
                    <div class="banner4_content_item_txt">
                        <div class="banner4_content_item_txt_time">2017</div>
                        <div class="banner4_content_item_txt_thing">2017年6月  完成天使轮融资</div>
                        <div class="banner4_content_item_txt_thing">2017年9月  销售云产品内测上线</div>
                    </div>
                </div>
                <div class="banner4_content_item">
                    <div class="banner4_content_item_cicle">
                        <span></span>
                    </div>
                    <div class="banner4_content_item_line"></div>
                    <div class="banner4_content_item_txt">
                        <div class="banner4_content_item_txt_time">2018</div>
                        <div class="banner4_content_item_txt_thing">2018年3月  销售云产品正式发布</div>
                    </div>
                </div>
                <div class="banner4_content_item">
                    <div class="banner4_content_item_cicle">
                        <span></span>
                    </div>
                    <div class="banner4_content_item_line"></div>
                    <div class="banner4_content_item_txt">
                        <div class="banner4_content_item_txt_time">2019</div>
                        <div class="banner4_content_item_txt_thing">2019年3月  入选阿里AI赛道明星班二期</div>
                        <div class="banner4_content_item_txt_thing">2019年4月  完成Pre-A轮融资</div>
                        <div class="banner4_content_item_txt_thing">2019年6月  销售云2.0上线</div>
                        <div class="banner4_content_item_txt_thing">2019年12月 入围ToB行业影响力价值榜</div>
                    </div>
                </div>
                <div class="banner4_content_item">
                    <div class="banner4_content_item_cicle">
                        <span></span>
                    </div>
                    <div class="banner4_content_item_line"></div>
                    <div class="banner4_content_item_txt">
                        <div class="banner4_content_item_txt_time">2020</div>
                        <div class="banner4_content_item_txt_thing">2020年3月     【ABM营销云】 产品测试并上线</div>
                        <div class="banner4_content_item_txt_thing">2020年8月      获高捷资本、金沙江联合资本、阿米巴资本A轮融资</div>
                        <div class="banner4_content_item_txt_thing">2020年8月     入围2020 MarTech Top 50</div>
                        <div class="banner4_content_item_txt_thing">2020年10月  【情报云】 初版产品上线</div>
                        <div class="banner4_content_item_txt_thing">2020年12月   入选腾讯SaaS加速器二期</div>
                        <div class="banner4_content_item_txt_thing">2020年12月   获取2020第四届金匠奖B2B年度营销技术贡献奖</div>
                    </div>
                </div>
                <div class="banner4_content_item">
                    <div class="banner4_content_item_cicle">
                        <span></span>
                    </div>
                    <div class="banner4_content_item_line"></div>
                    <div class="banner4_content_item_txt">
                        <div class="banner4_content_item_txt_time">2021</div>
                        <div class="banner4_content_item_txt_thing">2021年5月  腾讯云联合共建【商机魔方】产品上线</div>
                        <div class="banner4_content_item_txt_thing">2021年7月  获取2021数字化转型推动力奖及2021行业影响力人物奖</div>
                        <div class="banner4_content_item_txt_thing">2021年8月  入围中国营销技术弯弓奖2021年度营销技术TOP100</div>
                        <div class="banner4_content_item_txt_thing">2021年10月 发布火眼云全产品矩阵</div>
                        <div class="banner4_content_item_txt_thing">2021年12月 入围中国ToB年度榜单·创新力企业TOP30</div>
                    </div>
                </div>
                <div class="banner4_content_item">
                    <div class="banner4_content_item_cicle">
                        <span></span>
                    </div>
                    <div class="banner4_content_item_line"></div>
                    <div class="banner4_content_item_txt">
                        <div class="banner4_content_item_txt_time">2022</div>
                        <div class="banner4_content_item_txt_thing">2022年1月    获取2021第五届金匠奖年度十大营销技术服务企业</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="banner5">
            <div class="banner5_title">投资者</div>
            <div class="banner5_logo">
                <div class="banner5_logo_item">
                    <img src="@/res/about/banner7.png" alt="">
                </div>
                <div class="banner5_logo_item">
                    <img src="@/res/about/banner8.png" alt="">
                </div>
                <div class="banner5_logo_item">
                    <img src="@/res/about/banner9.png" alt="">
                </div>
            </div>
        </div>
        <div class="banner6">
            <div class="banner6_title">生态合作伙伴</div>
            <div class="banner6_content">
                <div class="banner6_content_img">
                    <img src="@/res/about/about_logo1.png" alt="">
                    <img src="@/res/about/about_logo2.png" alt="">
                    <img src="@/res/about/about_logo3.png" alt="">
                </div>
                <div class="banner6_content_img">
                    <img src="@/res/about/about_logo4.png" alt="">
                    <img src="@/res/about/about_logo5.png" alt="">
                    <img src="@/res/about/about_logo6.png" alt="">
                </div>
                <div class="banner6_content_img">
                    <img src="@/res/about/about_logo7.png" alt="">
                    <img src="@/res/about/about_logo8.png" alt="">
                    <img src="@/res/about/about_logo9.png" alt="">
                </div>
                <div class="banner6_content_img">
                    <img src="@/res/about/about_logo10.png" alt="">
                    <img src="@/res/about/about_logo11.png" alt="">
                    <img src="@/res/about/about_logo12.png" alt="">
                </div>
                <div class="banner6_content_img">
                    <img src="@/res/about/about_logo13.png" alt="">
                    <img src="@/res/about/about_logo14.png" alt="">
                </div>
            </div>
        </div>
        <div class="banner7">
            <div class="banner7_title">联系我们</div>
            <div class="banner7_nav">
                <div :class="activeIndex==1?'active':''" class="banner7_nav_item" @click="navTiggleFun(1)">
                    北京（总部）
                </div>
                <div :class="activeIndex==2?'active':''" class="banner7_nav_item" @click="navTiggleFun(2)">
                    上海
                </div>
                <div :class="activeIndex==3?'active':''" class="banner7_nav_item" @click="navTiggleFun(3)">
                    珠海
                </div>
            </div>
            <div class="banner7_content">
                <div class="banner7_content_item" v-if="activeIndex==1">
                    <div class="banner7_content_item_title">
                        <img src="@/res/about/about_us_site.png" alt="">
                        北京（总部）
                    </div>
                    <div class="banner7_content_item_li">地址：北京市朝阳区西坝河23号 嘉汇中心</div>
                    <div class="banner7_content_item_li">电话：400-8521-789</div>
                    <div class="banner7_content_item_li">邮箱：marketing@huoyan.cn</div>
                    <div class="banner7_content_item_li">路线：地铁13号线光熙门站B出口</div>
                    <div class="banner7_content_item_img">
                        <img src="@/res/about/about_us_map1.png" alt="">
                    </div>
                </div>
                <div class="banner7_content_item" v-if="activeIndex==2">
                    <div class="banner7_content_item_title">
                        <img src="@/res/about/about_us_site.png" alt="">
                        上海办公室
                    </div>
                    <div class="banner7_content_item_li">地址：上海市静安区愚园路环球世界大厦A座</div>
                    <div class="banner7_content_item_li">电话：400-8521-789</div>
                    <div class="banner7_content_item_li">邮箱：marketing@huoyan.cn</div>
                    <div class="banner7_content_item_li">路线：地铁2号/7号/14号线静安寺站出</div>
                    <div class="banner7_content_item_img">
                        <img src="@/res/about/about_us_map2.png" alt="">
                    </div>
                </div>
                <div class="banner7_content_item" v-if="activeIndex==3">
                    <div class="banner7_content_item_title">
                        <img src="@/res/about/about_us_site.png" alt="">
                        珠海办公室
                    </div>
                    <div class="banner7_content_item_li">地址：珠海市香洲区旅游路168号梅溪商业广场云溪谷</div>
                    <div class="banner7_content_item_li">电话：400-8521-789</div>
                    <div class="banner7_content_item_li">邮箱：marketing@huoyan.cn</div>
                    <div class="banner7_content_item_li">路线：广东省珠海市香洲区梅界路与旅游路交汇处</div>
                    <div class="banner7_content_item_img">
                        <img src="/newHuoyan/assets/pc/images/about/address_zhuhai.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
import vFooter from '@/components/footer.vue'
import Swiper from 'swiper';
  export default {
    data() {
      return {
        input1:'',
        activeIndex:'1'
      };
    },
        components: {
      vFooter,vHeader
    },
    mounted () {

    },
    methods: {
        navTiggleFun(val){
            this.activeIndex = val
        }
    }
  };
</script>

<style scoped lang="css">
    .about_us{
        padding-top: 3.69rem;
        flex: 1 1 auto;
        overflow-y: auto;
    }
    .banner1{
      padding:1.25rem;
      background: #F9FAFC;
    }
    .banner1 img{
        width: 21.25rem;
    }
    .banner1_txt{
        margin-top:1.25rem;
        text-align: center;
    }

    .banner2{
        background: #ffffff;
        padding:0 1.25rem;
    }
    .banner2_img{
        margin-top:1.5rem;
    }
    .banner2_img img{
        width: 21.4375rem;
    }
    .banner2_line{
        margin-top:2.75rem;
        width: 3.75rem;
        height: 1px;
        border: 2px solid #CCCCCC;
    }
    .banner2_title{
        margin-top:1.375rem;
        font-size: 1rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
    }
    .banner2_txt{
        font-size: .875rem;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #3D3D3D;
        line-height: 2rem;
    }
    .banner2_txt1{
        margin-top:.5rem;
    }
    .banner2_txt2{
        margin-top:3.875rem;
    }
    .banner3{
        padding:0 1.25rem;
        background: #ffffff;
    }
    .banner3_title{
        text-align: center;
        margin-top: 1.875rem;
        margin-bottom: .75rem;
        font-size: 1rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
        line-height: 1.875rem;
    }
    .banner3_content{
        padding:1.25rem 2.375rem;
        background: linear-gradient(90deg, #FF9F00 0%, #FF6A00 100%);
        border-radius: 8px;
    }
    .banner3_content_item:first-child{
        margin-bottom: .9375rem;
    }
    .banner3_content_item_span1{
        font-size: 1rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
    .banner3_content_item_line{
        width: 1px;
        height:1.875rem;
        margin:0 2.1875rem;
        border: 1px solid rgba(255, 255, 255, 0.39);
    }
    .banner3_content_item_span2{
        font-size: 1rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
    }
    .banner3_icon{
        display: flex;
        justify-content: space-between;
        margin-top:2.375rem;
    }
    .banner3_icon_item img{
        width: 2.25rem;
        height: 2.25rem;
    }
    .banner3_icon_item_txt{
        margin-top:.5rem;
        font-size: .8125rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FF6A00;
        line-height: 2rem;
    }
    .banner4{
        margin-top:2.375rem;
        background: linear-gradient(180deg, #3C4C62 0%, #1C2533 100%);
        padding:1.375rem 1.25rem;
    }
    .banner4_title{
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
    .banner4_content{
        margin-top:1.0625rem
    }
    .banner4_content_item{
        position: relative;
    }
    .banner4_content_item_cicle{
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

    }
    .banner4_content_item_cicle span{
        display: inline-block;
        width: .6875rem;
        height: .6875rem;
        background: #FFFFFF;
        border-radius: 50%;

    }
    .banner4_content_item_line{
        height: 100%;
        top:.5rem;
        left: .6875rem;
        position: absolute;
        width: 1px;
        border: 1px solid #656F7D;
    }
    .banner4_content_item_txt{
        margin-left:2.1875rem;
        font-size: 14px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
    }
    .banner4_content_item_txt_time{
        color: #fff;
        font-weight: 700;
        padding-bottom: .3125rem;
        padding-top: .1875rem;
    }
    .banner4_content_item_txt_thing{
        color: #cdf;
        padding:.3125rem 0;
    }
    .banner5{
        padding:0 1.25rem;
    }
    .banner5_title{
        margin-top:3.5625rem;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
        text-align: center;
    }
    .banner5_logo{
        display: flex;
        justify-content: space-between;
        margin-top:.875rem;
    }
    .banner5_logo_item{
        width: 6.25rem;
        height: 6.25rem;
        background: #FFFFFF;
        border-radius: 50%;
        box-shadow: 0px 5px 19px 0px rgba(214, 214, 214, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .banner5_logo_item img{
        width: 5.625rem;
    }

    .banner6{

    }
    .banner6_title{
        margin:4.4375rem 0 .875rem 0;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
        text-align: center;
    }
    .banner6_content{
        background: #F9FAFC;
        padding:1.25rem 1.25rem;
    }
    .banner6_content_img{
        margin-top:.875rem;
    }
    .banner6_content_img img{
        width: 6.6875rem;
    }
    .banner7{
        padding:0 1.25rem;
    }
    .banner7_title{
        margin:3.125rem 0 .875rem 0;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
    }
    .banner7_nav{
        width: 21.4375rem;
        height: 2.9375rem;
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #E4E4E4;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
    }
    .banner7_nav .banner7_nav_item:nth-child(2){
        border-left:1px solid #E4E4E4;
        border-right:1px solid #E4E4E4;
    }
    .banner7_nav_item{
        font-size: 13px;
        /* font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300; */
        font-weight: 500;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #3D3D3D;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34%;
    }
    .banner7_nav_item.active{
        background: #FF6A00;
        color: #FFFFFF;
    }
    .banner7_content{
        margin-top:.875rem;
    }
    .banner7_content_item{

    }
    .banner7_content_item_title{
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #3D3D3D;
        margin-top:1.25rem;
        display: flex;
        align-items: center;
        margin-bottom: .9375rem;
    }
    .banner7_content_item_title img{
        width: 1.4375rem;
        margin-right: .625rem;
    }
    .banner7_content_item_li{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #595959;
        line-height: 1.625rem;
    }
    .banner7_content_item_img{
        margin-top: .9375rem;
    }
    .banner7_content_item_img img{
        width: 21.4375rem;
    }
</style>
