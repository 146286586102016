<template>
  <div class="flex-col container">
    <div class="flex-col group_1">
      <div class="flex-col group_2">
        <v-header></v-header>
        <div class="flex-col group_4">
          <span class="text_2">B2B数字化转型</span>
          <span class="text_3">无边界的营销服务平台</span>
          <img src="@/res/remote/a9ac25641884e04ad835f34ad2f6af06.png" class="image_5" />
          <div @click="goformPageFun()" class="flex-col items-center button">
            <span>定制您的专属方案</span>
          </div>
          <span class="text_5">方案价值</span>
          <div class="flex-col list">
            <div class="list-item flex-row">
              <img src="@/res/local/jjfa_number_experience_icon.png" class="image_6" />
              <div class="right-group flex-col">
                <span class="text_6">用户体验个性化</span>
                <span class="text_8"
                  >全渠道触点整合+营销自动化的组合搭配，让企业与目标客户建立深度连接，构建个性化用户体验，强化用户粘性，提升企业竞争力。</span
                >
              </div>
            </div>
            <div class="list-item flex-row">
              <img src="@/res/local/jjfa_number_Marketing_icon.png" class="image_6" />
              <div class="right-group flex-col">
                <span class="text_6">营销效果可视化</span>
                <span class="text_8"
                  >一站式的营销自动化平台，实现数据整合和营销活动统一运营，自动生成多维度报表，评估营销活动ROI，体现市场价值。</span
                >
              </div>
            </div>
            <div class="list-item flex-row">
              <img src="@/res/local/jjfa_number_organization_icon.png" class="image_6" />
              <div class="right-group flex-col">
                <span class="text_6">组织能力敏捷化</span>
                <span class="text_8"
                  >以数字化打通营销全链路，以工具赋能营销协同，强化组织内部向心力，提升外部客户响应速度，实现业绩提升。</span
                >
              </div>
            </div>
          </div>
          <div class="flex-col items-center group_5">
            <span class="text_14">火眼云ABM一站式增长解决方案</span>
            <span class="text_15">全渠道精准获客</span>
          </div>
          <img src="@/res/remote/94d65b2d0ba01b8e55a6c580fe502015.png" class="image_10 image_11" />
          <div class="group_6 flex-col view_3">
            <div class="top-group flex-row">
              <div class="left-section"></div>
              <span class="text_16">连接B2B企业三方数据平台（DMP）</span>
            </div>
            <span class="text_18">支持多维度筛选目标企业与自定义目标画像建模，精准定位全量客户群</span>
          </div>
          <div class="group_6 flex-col view_6">
            <div class="top-group flex-row">
              <div class="left-section"></div>
              <span class="text_16">整合企业自有数据</span>
            </div>
            <span class="text_20">识别官网匿名访客企业和微信粉丝信息，打造企业营销数据中台（CDP）</span>
          </div>
          <div class="group_6 flex-col view_9">
            <div class="top-group flex-row">
              <div class="left-section"></div>
              <span class="text_16">整合营销</span>
            </div>
            <span class="text_22"
              >火眼营销云平台一站式整合广告、微信、邮件、短信、电销等触达渠道，支持线上+线下营销活动场景，实现全渠道精准获客和统一平台集客。<br
            /></span>
          </div>
          <div @click="goformPageFun()" class="text-wrapper_1 flex-col items-center view_12">
            <span>立即体验</span>
          </div>
        </div>
        <div class="flex-col section_5">
          <span class="text_24">营销数据整合治理和客户行为洞察</span>
          <img src="@/res/remote/28c65b31d26e2968f40278b778cb03fa.png" class="image_10 image_12" />
          <div class="flex-col group_7">
            <div class="flex-row group_8">
              <div class="left-section"></div>
              <span class="text_25">全渠道数据整合</span>
            </div>
            <span class="text_26"
              >基于火眼云One
              ID能力，将企业散落在各个数字渠道的数据资产进行客户统一身份和行为合并，真正实现“以客户为中心的数据体系”。</span
            >
          </div>
          <div class="flex-col group_9">
            <div class="flex-row group_10">
              <div class="left-section"></div>
              <span class="text_27">360°客户洞察</span>
            </div>
            <span class="text_28"
              >通过搭建维度丰富的标签和打分体系，提供客户画像，可描绘完整的客户互动轨迹，实现全方位的客户洞察，指导个性化的营销活动和运营策略。</span
            >
          </div>
          <div @click="goformPageFun()" class="text-wrapper_1 flex-col items-center text-wrapper_2">
            <span>立即体验</span>
          </div>
        </div>
        <div class="flex-col group_11">
          <span class="text_30">贯穿全生命周期的个性化营销体验</span>
          <img src="@/res/remote/d011965ce7ea12db42bb63a5537d53c2.png" class="image_10 image_13" />
          <div class="flex-col group_12">
            <div class="flex-row group_13">
              <div class="left-section"></div>
              <span class="text_31">全域触达和个性化营销</span>
            </div>
            <span class="text_32"
              >火眼营销云的全域触达能力支持在不同的营销阶段和场景下，对细分人群全方位、自动化触达与个性化内容推荐。</span
            >
          </div>
          <div class="flex-col group_14">
            <div class="flex-row group_15">
              <div class="left-section"></div>
              <span class="text_33">提升客户体验</span>
            </div>
            <span class="text_34"
              >根据客户所处阶段和预设行为，自动触发相应个性化内容，将客户体验贯穿整个生命周期，提升客户粘性和忠诚度。</span
            >
          </div>
          <div @click="goformPageFun()" class="text-wrapper_1 flex-col items-center view_17">
            <span>立即体验</span>
          </div>
        </div>
        <div class="flex-col section_6">
          <span class="text_36">效果导向的数据分析能力</span>
          <img src="@/res/remote/e420d57aee1f67190aa477983c83a2d9.png" class="image_10 image_14" />
          <div class="group_6 flex-col view_18">
            <div class="top-group flex-row">
              <div class="left-section"></div>
              <span class="text_16">营销效果统计</span>
            </div>
            <span class="text_38">营销渠道整合内置多个统计图表及数据看板，可实时监控和展示营销活动效果。</span>
          </div>
          <div class="group_6 flex-col view_21">
            <div class="top-group flex-row">
              <div class="left-section"></div>
              <span class="text_16">多触点归因分析</span>
            </div>
            <span class="text_40"
              >区分来自不同获客触点和渠道的线索，帮助市场部进行线索归因分析，衡量投入产出，优化获客渠道。</span
            >
          </div>
          <div class="group_6 flex-col view_24">
            <div class="top-group flex-row">
              <div class="left-section"></div>
              <span class="text_16">客户生命周期价值体现</span>
            </div>
            <span class="text_42">通过用户阶段自定义设置和管理，形成营销漏斗，指导企业的用户生命周期管理。</span>
          </div>
          <div @click="goformPageFun()" class="text-wrapper_1 flex-col items-center view_27">
            <span>立即体验</span>
          </div>
          <span class="text_44">成功案例</span>
            <div class="mySwiperPar">
              <div class="swiper mySwiper mySwiper1">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="flex-col group_16">
                      <div class="flex-row section_7">
                        <span>
<img src="@/res/local/logo_26.png" class="image_15 image_15_new" />
                        </span>
                        
                        <img src="@/res/local/994a00cf5c8f903da4989e3b6874619d.png" class="image_16" />
                        <span class="text_45 text_46"
                          >致远互联在过往的营销过程中，面临线索不足且质量差的痛点，故积极探索营销的数字化转型，想要采用领先的技术手段解决获客难题。致远互联与火眼云合作已久，成功为致远互联带来精准线索，并提升互动与参与度，辐射致远互联全国业务，建立了市场和销售协同的增长体系</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="flex-col group_16">
                      <div class="flex-row section_7">
                        <span>
<img src="@/res/local/logo_8.png" class="image_15 image_15_new" />
                        </span>
                        
                        <img src="@/res/local/994a00cf5c8f903da4989e3b6874619d.png" class="image_16" />
                        <span class="text_45 text_46"
                          >该企业过往的营销手段较为单一，仅采用百度SEM和线下展会，但受到疫情影响，线下展会全面受阻，线索数量严重下降。这样的客观情况促使希悦尔寻求线上获客渠道，部署营销渠道整合进行获客活动，盘活沉睡数据，大力开展线上会议，通过双方的共同努力，希悦尔营销模式成功转型，大幅度提升品牌曝光及知名度的同时，为业务部门输送千余条高质量销售线索。</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>
            </div>
          <div class="flex-col group_17">
            <span class="text_47">他们也在用</span>
            <div class="flex-row group_18">
              <div class="grid">
                <div class="flex-col items-center grid-item">
                  <img src="@/res/local/baiduzhinengyun_logo.png" class="image_18" />
                </div>
                <div class="grid-item_1 flex-col items-center">
                  <img src="@/res/local/didi_logo.png" class="image_18" />
                </div>
                <div class="grid-item_1 flex-col items-center">
                  <img src="@/res/local/jingdonghuicai_logo.png" class="image_18" />
                </div>
                <div class="flex-col items-center grid-item_2">
                  <img src="@/res/local/xiaoshouyi_logo's.png" class="image_18" />
                </div>
              </div>
              <div class="flex-col items-center image-wrapper_2">
                <img src="@/res/local/ruixiangyun_logo.png" class="image_18" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="text_48">了解更多ABM营销自动化方案</span>
      <div class="mySwiperPar1"> 
          <div class="swiper mySwiper mySwiper2">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="flex-col group_31" @click="goOtherPageFun('/abm_grow.html')">
                  <div class="flex-col items-center image-wrapper_3">
                    <img src="@/res/local/lujing13.png" class="image_24" />
                  </div>
                  <span class="text_62">ABM一站式增长</span>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="flex-col group_31" @click="goOtherPageFun('/Intent-analysis.html')">
                  <div class="flex-col items-center image-wrapper_3">
                    <img src="@/res/local/lujing13_c2.png" class="image_24" />
                  </div>
                  <span class="text_62">行为意图分析</span>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="flex-col group_31" @click="goOtherPageFun('/wechat-marketing.html')">
                  <div class="flex-col items-center image-wrapper_3">
                    <img src="@/res/local/lujing13_c3.png" class="image_24" />
                  </div>
                  <span class="text_62">私域营销</span>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="flex-col group_31" @click="goOtherPageFun('/wechat-marketing.html')">
                  <div class="flex-col items-center image-wrapper_3">
                    <img src="@/res/local/lujing13_c3.png" class="image_24" />
                  </div>
                  <span class="text_62">私域营111销</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- <div class="flex-col group_20">
        <div class="flex-col section_8">
          <span class="text_50">留下您的联系方式<br />专属顾问会尽快与您联系</span>
          <div class="justify-between search">
            <div class="flex-row group_21">
              <img src="@/res/local/16376631404543724564.png" class="image_25" />
              <span class="text_51">请输入您的手机号码</span>
            </div>
            <div class="flex-col items-center text-wrapper_3">
              <span>免费试用</span>
            </div>
          </div>
          <div class="group_22">
            <span class="text_53">如有疑问，欢迎致电客服热线</span>
            <span class="text_54">400-8521-789</span>
          </div>
        </div>
        <div class="text_45 flex-col view_28">
          <div class="section_9"></div>
          <div class="justify-between section_10">
            <div class="flex-col group_23">
              <div class="group_24">
                <span class="text_55">电话：</span>
                <span class="text_56">400-8521-789</span>
                <span class="text_57">邮箱： </span>
                <span class="text_58">marketing@huoyan.cn</span>
              </div>
              <span class="text_59">©2017 北京绘客科技有限公司 <br />京ICP备16023115号-1 隐私政策</span>
            </div>
            <img src="@/res/remote/06c5f76966fbb88f09c7d54f941ea701.png" class="image_26" />
          </div>
          <img src="@/res/local/52ca3200bc469c20b13881eecea2030c.svg" class="image_27" />
        </div>
      </div> -->
      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
import vFooter from '@/components/footer.vue'
import Swiper from 'swiper';
  export default {
    data() {
      return { listg0SoeCn8: [null, null, null] };
    },
        components: {
      vFooter,vHeader
    },
    mounted(){
      var swiper = new Swiper(".mySwiper1", {
        pagination: {
          el: ".swiper-pagination",
        },
        // loop:true
      });
      var swiper1 = new Swiper('.mySwiper2', {
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
      });
    },
    methods: {
      goformPageFun(val){
        if(val==1){
          window.location.href='/form.html?mobile='+this.input1
        }else{
          window.location.href='/form.html?mobile='
        }
      },
      goOtherPageFun(path){
        window.location.href = path
      }
    }
  };
</script>

<style scoped lang="css">
/deep/.swiper-pagination-bullet-active{
  background-color: #FF6A00;
}
    .mySwiperPar{
      position: relative;
      overflow: hidden;
      padding-bottom: 3rem;
    }
    .mySwiperPar1{
      position: relative;
      overflow: hidden;
    }
      .swiper {
        width: 100%;
      }

      .swiper-slide {

      }
    .mySwiper2 .swiper-slide {
      transition: 300ms;
      z-index: 1;
      transform: scale(0.8);
      opacity: 0.5;
    }
    .mySwiper2 .swiper-slide-active,.mySwiper1 .swiper-slide-duplicate-active{
        transform: scale(1);
        z-index: 2;
        opacity: 1;
    }

    .mySwiper2 .swiper-slide span{
      display: none;
      width: 9rem;
      text-align: center;
      /* padding-top: 16px; */
    }
    .mySwiper2 .swiper-slide-active span{
      display: block;
    }
    /* .image_15_new{
      height: 1.3rem !important;
    } */
  .text_45 {
    position: relative;
  }
  .image_10 {
    align-self: center;
    width: 20rem;
    height: 12.5rem;
  }
  .group_6 {
    padding: 0 0.25rem;
  }
  .text-wrapper_1 {
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      99.8deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 82.66%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.56rem 0px rgba(255, 65, 65, 0.1);
    border-radius: 0.13rem;
    width: 6.25rem;
  }
  .list-item {
    padding: 0.75rem 0.5rem 0.44rem 1rem;
    background-image: linear-gradient(
      180deg,
      rgb(246, 247, 251) 0%,
      rgb(246, 247, 251) 0%,
      rgb(253, 253, 255) 100%,
      rgb(253, 253, 255) 100%
    );
    box-shadow: 0px 0.13rem 1.5rem 0px rgba(227, 227, 227, 0.5);
    border-radius: 0.25rem;
    border: solid 0.25rem rgb(255, 255, 255);
  }
  .image_6 {
    width: 3rem;
    height: 3rem;
  }
  .right-group {
    margin-left: 1rem;
    flex: 1 1 auto;
  }
  .left-section {
    align-self: center;
    background-color: rgb(216, 216, 216);
    border-radius: 50%;
    width: 0.25rem;
    height: 0.25rem;
  }
  .text_6 {
    color: rgb(38, 38, 38);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.38rem;
    white-space: nowrap;
  }
  .text_8 {
    margin-right: 0.88rem;
    margin-top: 0.75rem;
    color: rgb(89, 89, 89);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.41rem;
    text-align: justify;
  }
  .grid-item_1 {
    padding: 0.56rem 0 0.63rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.13rem;
    position: relative;
  }
  .image_18 {
    width: 5.63rem;
    height: 1.75rem;
  }
  .top-group {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_16 {
    margin-left: 0.5rem;
  }
  .container {
    padding-top:3.5rem;
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
  }
  .header {
    padding: 0.84rem 0.88rem 0.72rem 2.19rem;
    color: rgb(0, 0, 0);
    font-size: 0.94rem;
    line-height: 1.13rem;
    letter-spacing: -0.019rem;
    white-space: nowrap;
    height: 2.69rem;
  }
  .group_1 {
    flex: 1 1 auto;
    overflow-y: auto;
  }
  .group {
    margin: 0.19rem 0 0.22rem;
  }
  .text_48 {
    margin-top: 2.5rem;
    padding-bottom:2.19rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_19 {
    margin-top: 2.19rem;
    padding: 0 0.81rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .group_20 {
    margin-top: 3.25rem;
  }
  .image {
    width: 1.06rem;
    height: 0.69rem;
  }
  .image_1 {
    margin-left: 0.28rem;
    width: 1rem;
    height: 0.69rem;
  }
  .image_2 {
    margin-left: 0.28rem;
    width: 1.53rem;
    height: 0.72rem;
  }
  .section_1 {
    padding: 0.88rem 1rem 0.81rem;
    color: rgb(255, 106, 0);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(0, 0, 0, 0.03);
    position: relative;
  }
    .text_62 {
    margin-top: 1rem;
    align-self: center;
  }
  .group_4 {
    padding: 1.69rem 1.25rem 1.94rem 1.31rem;
  }
  .section_5 {
    padding: 1.38rem 1.56rem 1.56rem;
    background-color: rgb(248, 249, 251);
  }
  .group_11 {
    padding: 2.44rem 1.44rem 2.5rem 1.56rem;
  }
  .section_6 {
    padding: 1.75rem 1.25rem 1.63rem 1.31rem;
    background-color: rgb(248, 249, 251);
  }
  .image-wrapper_3 {
    background-image: url('https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/6198d70c8611d00011b57c2f/6198d8928611d00011b57c86/16377417725036935418.png');
    background-position: 0px 0.31rem;
    background-size: 100% 91%;
    background-repeat: no-repeat;
  }
  .text_49 {
    margin-top: 1.25rem;
    align-self: center;
  }
  .section_8 {
    padding: 1.94rem 1.69rem 1.31rem 1.75rem;
    background-color: rgb(249, 250, 252);
  }
  .group_3 {
    align-self: center;
  }
  .text-wrapper {
    padding: 0.44rem 0;
    background-color: rgba(255, 106, 0, 0.05);
    border-radius: 1rem;
    width: 5rem;
    height: 2rem;
  }
  .text_2 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.06rem;
    white-space: nowrap;
  }
  .text_3 {
    margin-top: 0.88rem;
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .image_5 {
    margin-top: 1.75rem;
    align-self: center;
    width: 16.38rem;
    height: 14.81rem;
  }
  .button {
    margin-top: 1.81rem;
    padding: 0.56rem 0;
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.38rem;
    white-space: nowrap;
    background-color: rgb(255, 106, 0);
    border-radius: 0.13rem;
    width: 11.13rem;
  }
  .text_5 {
    margin-top: 2.44rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .list {
    margin-top: 1.38rem;
  }
  .group_5 {
    margin-top: 2.44rem;
  }
  .image_11 {
    margin-top: 1.56rem;
  }
  .view_3 {
    margin-top: 2.25rem;
  }
  .view_6 {
    margin-top: 1.56rem;
  }
  .view_9 {
    margin-top: 1.94rem;
  }
  .view_12 {
    margin-top: 1.88rem;
  }
  .text_24 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .image_12 {
    margin-top: 1.94rem;
  }
  .group_7 {
    margin-top: 1.88rem;
  }
  .group_9 {
    margin-top: 2.13rem;
  }
  .text-wrapper_2 {
    margin-top: 1.94rem;
  }
  .text_30 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .image_13 {
    margin-top: 1.75rem;
  }
  .group_12 {
    margin-top: 2.06rem;
  }
  .group_14 {
    margin-top: 1.63rem;
  }
  .view_17 {
    margin-top: 1.88rem;
  }
  .text_36 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .image_14 {
    margin-top: 1.81rem;
  }
  .view_18 {
    margin-top: 2rem;
  }
  .view_21 {
    margin-top: 1.88rem;
  }
  .view_24 {
    margin-top: 2.13rem;
  }
  .view_27 {
    margin-top: 2rem;
  }
  .text_44 {
    margin-top: 3.44rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_16 {
    margin-top: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.19rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    line-height: 1.63rem;
  }
  .group_17 {
    margin-top: 2.81rem;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .image_24 {
    filter: drop-shadow(0px 0.063rem 0.44rem rgba(174, 174, 174, 0.64));
    width: 11.44rem;
    /* height: 7.13rem; */
  }
  .text_50 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.13rem;
    font-weight: 500;
    line-height: 1.13rem;
    text-align: center;
    width: 12.38rem;
  }
  .search {
    margin-top: 0.88rem;
    padding: 0.5rem 0.31rem 0.44rem 0.56rem;
    filter: drop-shadow(0px 0.88rem 0.66rem #1c25330d);
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    border: solid 0.063rem rgb(222, 222, 222);
  }
  .group_22 {
    margin-top: 1.13rem;
    align-self: center;
    text-align: center;
    width: 11.38rem;
  }
  .section_9 {
    background-color: rgb(31, 41, 55);
    height: 2.06rem;
  }
  .section_10 {
    padding: 3.25rem 1.06rem 1.75rem 1.44rem;
    background-color: rgb(31, 41, 55);
  }
  .image_27 {
    width: 7.25rem;
    height: 2.25rem;
    position: absolute;
    left: 1.44rem;
    top: 1.44rem;
  }
  .image_3 {
    align-self: center;
    width: 1rem;
    height: 1rem;
  }
  .image_4 {
    margin-left: 1.5rem;
    width: 4.94rem;
    height: 1.5rem;
  }
  .list-item:not(:first-of-type) {
    margin-top: 0.63rem;
  }
  .text_14 {
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_15 {
    margin-top: 1.06rem;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .text_18 {
    margin-left: 0.75rem;
    margin-top: 0.69rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .text_20 {
    margin: 0.56rem 0.13rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_22 {
    margin: 0.63rem 0.13rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: left;
  }
  .group_8 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_26 {
    margin: 0.63rem 0.69rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: justify;
  }
  .group_10 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_28 {
    margin: 0.63rem 0.88rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: justify;
  }
  .group_13 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_32 {
    margin: 0.63rem 1rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: left;
  }
  .group_15 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_34 {
    margin: 0.56rem 0.19rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_38 {
    margin: 0.63rem 0.94rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: left;
  }
  .text_40 {
    margin: 0.56rem 0.13rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_42 {
    margin: 0.56rem 0.13rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .section_7 {
    padding: 1.31rem 0.94rem 3rem;
    overflow: hidden;
    border-radius: 0.94rem;
    background-color: rgb(255, 255, 255);
    height: 17.81rem;
    position: relative;
  }
  .image_17 {
    margin-top: 0.94rem;
    align-self: center;
    width: 1.38rem;
    height: 0.25rem;
  }
  .text_47 {
    align-self: center;
  }
  .group_18 {
    margin-top: 1rem;
  }
  .group_21 {
    margin: 0.19rem 0 0.31rem;
    color: rgb(199, 199, 199);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text-wrapper_3 {
    padding: 0.44rem 0;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-image: linear-gradient(
      102.2deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 81.34%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(255, 65, 65, 0.5);
    border-radius: 0.25rem;
    width: 5rem;
    height: 2rem;
  }
  .text_53 {
    color: rgb(137, 137, 137);
    font-size: 0.88rem;
    line-height: 1.5rem;
  }
  .text_54 {
    color: rgb(255, 106, 0);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.38rem;
  }
  .group_23 {
    width: 12.44rem;
  }
  .image_26 {
    margin-top: 2.63rem;
    width: 6.25rem;
    height: 6.25rem;
  }
  .text_25 {
    margin-left: 0.5rem;
  }
  .text_27 {
    margin-left: 0.5rem;
  }
  .text_31 {
    margin-left: 0.5rem;
  }
  .text_33 {
    margin-left: 0.5rem;
  }
  .image_15 {
    width: 3rem;
    /* height: 3rem; */
  }
  .image_16 {
    width: 4.63rem;
    height: 4.25rem;
    position: absolute;
    left: 0;
    top: 12.56rem;
  }
  .text_46 {
    margin-left: 1.19rem;
    margin-right: 0.13rem;
    flex: 1 1 auto;
    text-align: justify;
  }
  .grid {
    flex: 1 1 auto;
    height: 6.38rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 0.5rem;
    grid-column-gap: 0.56rem;
  }
  .image-wrapper_2 {
    margin-left: 0.5rem;
    padding: 0.56rem 0 0.63rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.13rem;
    width: 6.63rem;
    height: 2.94rem;
  }
  .image_25 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .text_51 {
    margin-left: 0.81rem;
    margin-top: 0.19rem;
  }
  .group_24 {
    text-align: left;
    width: 11.56rem;
  }
  .text_59 {
    margin-top: 2.19rem;
    color: rgb(140, 140, 140);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: left;
  }
  .grid-item {
    padding: 0.63rem 0 0.56rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.63rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.094rem;
    position: relative;
  }
  .grid-item_2 {
    padding: 0.56rem 0 0.63rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.13rem;
  }
  .text_55 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 2.19rem;
  }
  .text_56 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 2.19rem;
  }
  .text_57 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 1.25rem;
  }
  .text_58 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 1.25rem;
  }
</style>