import Vue from 'vue';
import VueRouter from 'vue-router';
import Shouye from '@/pages/shouye.vue';
import Chanpin_ABMyingxiaoyun from '@/pages/chanpin_ABMyingxiaoyun.vue';
import Chanpin_shujuyun from '@/pages/chanpin_shujuyun.vue';
import Chanpin_qingbaoyun from '@/pages/chanpin_qingbaoyun.vue';
import Chanpin_guanggaoyun from '@/pages/chanpin_guanggaoyun.vue';
import Jiejuefangan_B2Bshuzihuazhuanxing from '@/pages/jiejuefangan_B2Bshuzihuazhuanxing.vue';
import Jiejuefangan_hangweiyitufenxibeifen from '@/pages/jiejuefangan_hangweiyitufenxibeifen.vue';
import Jiejuefangan_hangweiyitufenxi from '@/pages/jiejuefangan_hangweiyitufenxi.vue';
import Chanpin_kehushujupingtai_CDP_ from '@/pages/chanpin_kehushujupingtai_CDP_.vue';
import Jiejuefangan_ABMyizhanshizengchang from '@/pages/jiejuefangan_ABMyizhanshizengchang.vue';
import Jiejuefangan_ABM from '@/pages/jiejuefangan_ABM.vue';
import Jiejuefangan_industry from '@/pages/jiejuefangan_industry.vue';
import kehuanli from '@/pages/kehuanli.vue';
import about_us from '@/pages/about_us.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'shouye',
    component: Shouye,
    meta:{
      title:'火眼云一站式B2B营销增长平台-让B2B企业实现大规模营销增长',
      content:{
        keywords:'火眼云,abm,营销平台,市场获客,线索培育,精准营销,大数据获客,销售协同',
        description:'火眼云一站式营销增长平台，基于其独有的全域企业信息和职场人群行为情报，为B2B企业提供目标客户营销 (ABM)解决方案及领先的数字化营销产品，现已为超千家明星B2B企业实现大规模营销增长。'
      }
    }
  },
  {
    path: '/abm-marketing-cloud.html',
    name: 'chanpin_ABMyingxiaoyun',
    component: Chanpin_ABMyingxiaoyun,
    meta:{
      title:'营销云-营销自动化-精准营销-数字化营销-营销获客-火眼云ABM营销云',
      content:{
        keywords:'abm,营销云,活动管理,营销自动化,精准营销,数字化营销, 线索打分,线索孵化',
        description:'火眼云ABM营销平台选定目标客户进行全渠道互动，提高客户全生命周期转化率，让大规模、个性化执行目标客户营销（ABM）策略成为可能'
      }
    }
  },
  {
    path: '/data-cloud.html',
    name: 'chanpin_shujuyun',
    component: Chanpin_shujuyun,
    meta:{
      title:'人群画像-目标客户模型-DMP营销-大数据营销平台-火眼数据云',
      content:{
        keywords:'DMP,数据管理平台,B2B获客,人群画像,目标客户,大数据营销,DMP营销',
        description:'火眼数据云覆盖6000万企业主体及1.6亿职场人群画像，并掌握B2B企业情报及意向数据，帮助您识别、理解和吸引理想的目标客户'
      }
    }
  },
  {
    path: '/Intent-cloud.html',
    name: 'chanpin_qingbaoyun',
    component: Chanpin_qingbaoyun,
    meta:{
      title:'客户分析-商情分析-匿名访客识别-私域流量转化-潜在客户-精准客户-火眼情报云',
      content:{
        keywords:'客户分析,匿名访客识别,客户行为分析,私域流量转化,匿名流量',
        description:'火眼情报云通过企业情报分析和行为意图数据，实时定位对您产品或服务感兴趣的潜在客户。'
      }
    }
  },
  {
    path: '/ad-cloud.html',
    name: 'chanpin_guanggaoyun',
    component: Chanpin_guanggaoyun,
    meta:{
      title:'广告投放-信息流-DMP营销-数字广告-火眼广告云',
      content:{
        keywords:'DMP,数据管理平台,B2B获客,广告投放,重定向,人群包,整合投放',
        description:'B2B数字广告的全渠道投放、监测、归因分析以及投放策略优化'
      }
    }
  },
  {
    path: '/marketing-digital-transformation.html',
    name: 'jiejuefangan_B2Bshuzihuazhuanxing',
    component: Jiejuefangan_B2Bshuzihuazhuanxing,
    meta:{
      title:'数字化转型-精准获客-整合营销-全渠道营销-火眼云数字化转型专家',
      content:{
        keywords:'数字化转型,精准获客,整合营销,全渠道营销',
        description:'火眼云B2B数字化转型，实现数据整合和营销活动统一运营，强化内部协同向心力，提升外部客户响应速度，实现业绩提升。'
      }
    }
  },
  {
    path: '/wechat-marketing.html',
    name: 'jiejuefangan_hangweiyitufenxibeifen',
    component: Jiejuefangan_hangweiyitufenxibeifen,
    meta:{
      title:'私域营销-微信营销-粉丝识别-精准引流-私域流量-私域管理-火眼云私域营销',
      content:{
        keywords:'私域营销,微信营销,粉丝识别,精准引流,私域流量,私域管理',
        description:'火眼云私域营销平台帮助企业有效和主动触达的客户群体，具备跨平台的流量整合及应用能力，能够统一管理全渠道私域用户的营销互动，并具有企业级数据安全和个人信息保护机制。'
      }
    }
  },
  {
    path: '/Intent-analysis.html',
    name: 'jiejuefangan_hangweiyitufenxi',
    component: Jiejuefangan_hangweiyitufenxi,
    meta:{
      title:'潜在客户-线索转化-客户流失-潜在商机-火眼云行为意图分析',
      content:{
        keywords:'潜在客户,线索转化,客户流失,潜在商机',
        description:'火眼云行为意图分析帮助企业将匿名潜在客户快速精准识别为实名用户,帮助企业比竞争对手抢先一步拦截意向客户,加速线索转化,增加续约机率,提高投资回报,真正为企业业务产生价值.'
      }
    }
  },
  {
    path: '/cdp.html',
    name: 'chanpin_kehushujupingtai_CDP_',
    component: Chanpin_kehushujupingtai_CDP_,
    meta:{
      title:'企业信息数据-客户画像-线索全生命周期管理-火眼云客户数据平台CDP',
      content:{
        keywords:'CDP,用户数据平台,oneid,营销数据打通,用户画像,线索全生命周期管理',
        description:'火眼云客户数据平台以oneid方式激活企业数据价值加速营销增长,实现B2B企业营销数字化转型'
      }
    }
  },
  {
    path: '/abm_grow.html',
    name: 'jiejuefangan_ABMyizhanshizengchang',
    component: Jiejuefangan_ABMyizhanshizengchang,
    meta:{
      title:'营销自动化平台-客户画像-人群画像-营销方案-火眼云ABM解决方案',
      content:{
        keywords:'火眼云,客户画像,营销自动化平台,营销平台,人群画像,营销方案,企业标签',
        description:'火眼云ABM营销平台通过大数据智能推荐引擎+营销自动化平台+专业服务,一站式助力企业搭建可持续增长的数字营销体系'
      }
    }
  },
  {
    path: '/abm.html',
    name: 'jiejuefangan_ABM',
    component: Jiejuefangan_ABM,
    meta:{
      title:'ABM_ABM精准营销_abm营销平台_abm营销工具_火眼云',
      content:{
        keywords:'ABM,ABM精准营销,abm营销平台,abm营销工具,火眼云',
        description:'火眼云基于 ABM 策略+营销自动化工具，覆盖 6000 万+优质企业全景画像和 1.2 亿职场人群行为意向，预先指定目标企业决策人并进行大规模、个性化触达和培育，帮助 ToB 企业实现全流程自动化线上获客。'
      }
    }
  },
  {
    path: '/industry.html',
    name: 'jiejuefangan_industry',
    component: Jiejuefangan_industry,
    meta:{
      title:'火眼云-工业制造行业营销解决方案，工业制造行业数字化转型，工业制造业营销自动化，工业制造行业用户画像，工业制造业大数据营销，工业制造业全渠道数字化营销服务商_火眼云',
      content:{
        keywords:'工业制造行业营销方案，工业制造行业CDP，工业制造行业CDP，工业制造行业数字化营销，工业制造行业智能营销，工业制造行业精准客户营销，工业制造行业用户画像，火眼云营销',
        description:'火眼云为B2B营销企业提供ABM营销自动化B端智能营销技术解决方案，帮助工业制造行业整合数字营销，构建用户画像，提升精细化运营能力，提高用户全生命周期价值，提升工业制造行业B端营销获客。'
      }
    }
  },
  {
    path: '/case.html',
    name: 'kehuanli',
    component: kehuanli,
    meta:{
      title:'客户案例_火眼云',
      content:{
        keywords:'客户案例,火眼云',
        description:'客户案例栏目是火眼云ABM营销云平台精心整理的关于abm营销案例中的优质案例。'
      }
    }
  },
  {
    path: '/about.html',
    name: 'about_us',
    component: about_us,
    meta:{
      title:'北京绘客科技有限公司_火眼云',
      content:{
        keywords:'北京绘客科技有限公司,绘客科技,火眼云',
        description:'火眼云基于 ABM 策略+营销自动化工具，覆盖 6000 万+优质企业全景画像和 1.2 亿职场人群行为意向，预先指定目标企业决策人并进行大规模、个性化触达和培育，帮助 ToB企业实现全流程自动化线上获客。'
      }
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if(to.meta.content){
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
    }
  next()
})
export default router;