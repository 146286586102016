<template>
  <div class="flex-col container">
    <div class="flex-col group_1">
      <div class="flex-col group_2">
        <v-header></v-header>
        <div class="flex-col group_4">
          <span class="text_2">行为意图分析</span>
          <span class="text_3">比竞争对手抢先一步拦截意向客户</span>
          <img src="@/res/remote/d2f40b74540e62b3a2c606d7730df954.png" class="image_5" />
          <div @click="goformPageFun()" class="flex-col items-center button">
            <span>定制您的专属方案</span>
          </div>
          <div class="flex-col group_5">
            <span class="text_5">方案价值</span>
            <div class="flex-col list">
              <div class="list-item flex-row">
                <img src="@/res/local/jjfa_intention_distinguish_icon.png" class="image_6" />
                <div class="right-group flex-col">
                  <span class="text_6">识别潜在客户</span>
                  <span class="text_8">摒弃“大海捞针”式获客，根据情报数据进行意向分析锁定潜在客户，先人一步发现目标线索。</span>
                </div>
              </div>
              <div class="list-item flex-row">
                <img src="@/res/local/jjfa_intention_forecast_icon.png" class="image_6" />
                <div class="right-group flex-col">
                  <span class="text_6">预测采购时机</span>
                  <span class="text_8">想知道正在跟进的重点商机何时发起采购流程吗？通过行为分析，预测采购时机，为销售提供最新“情报”。</span>
                </div>
              </div>
              <div class="list-item flex-row">
                <img src="@/res/local/jjfa_intention_accelerate_icon.png" class="image_6" />
                <div class="right-group flex-col">
                  <span class="text_6">加速线索转化</span>
                  <span class="text_8">基于对客户行为的阶段性积累和意图深度分析，自动化推荐客户所需产品和方案，缩短培育周期，加速成单速度。</span>
                </div>
              </div>
            </div>
          </div>
          <div class="group_6 flex-col view_3">
            <span class="text_14">火眼云ABM一站式增长解决方案</span>
            <span class="text_15">客户行为意图数据洞察</span>
            <img src="@/res/remote/fdf484a0f5c90260435a829486996be1.png" class="image_10 image_11" />
            <div class="flex-col group_7">
              <div class="flex-row group_8">
                <div class="left-section"></div>
                <span class="text_16">企业一方行为意图洞察</span>
              </div>
              <span class="text_17"
                >对接企业官网、微信公众号、CRM等平台，整合线上线下获客渠道，收集客户互动行为数据。</span
              >
            </div>
          </div>
          <div class="group_6 flex-col view_5">
            <div class="flex-row group_9">
              <div class="left-section"></div>
              <span class="text_18">第三方行为意图数据分析</span>
            </div>
            <span class="text_20"
              >重点关注并定向分析垂直类公众号、访问竞品官网和搜索特定关键词等人群，以自定义的行业、行为特点为规则，锁定符合规则画像的高意向目标。</span
            >
          </div>
          <div class="group_6 flex-col view_7">
            <div class="flex-row group_10">
              <div class="left-section"></div>
              <span class="text_18">行为数据沉淀</span>
            </div>
            <span class="text_22">将企业一方数据和三方意图数据沉淀至火眼营销云，生成行为记录，自动匹配标签及分值。</span>
          </div>
          <div @click="goformPageFun()" class="button_1 flex-col items-center text-wrapper_1">
            <span>立即体验</span>
          </div>
        </div>
        <div class="flex-col section_5">
          <span class="text_24">通过行为数据分析客户需求和采购时机</span>
          <img src="@/res/remote/46fec71bc3e27b443d9a5db428725745.png" class="image_10 image_12" />
          <div class="flex-col group_11">
            <div class="flex-row group_12">
              <div class="left-section"></div>
              <span class="text_25">识别潜在客户</span>
            </div>
            <span class="text_26"
              >通过AI算法从海量行为中识别出特定行为，锁定潜在客户与潜在商机。</span
            >
          </div>
          <div class="flex-col group_13">
            <div class="flex-row group_14">
              <div class="left-section"></div>
              <span class="text_27">预测采购时机</span>
            </div>
            <span class="text_28"
              >时刻关注在跟进客户的实名与匿名行为，洞察其行为背后所蕴含的意图信号，为销售提供有效情报信息。</span
            >
          </div>
          <div class="flex-col group_15">
            <div class="flex-row group_16">
              <div class="left-section"></div>
              <span class="text_29">丰富客户画像</span>
            </div>
            <span class="text_30"
              >以往的客户画像维度中静态属性居多，行为意图数据极大程度丰富了动态偏好的角度，令客户画像更加立体。</span
            >
          </div>
          <div @click="goformPageFun()" class="button_1 flex-col items-center text-wrapper_2">
            <span>立即体验</span>
          </div>
        </div>
        <div class="flex-col group_17">
          <span class="text_32">个性化营销促进线索转化</span>
          <img src="@/res/remote/db00fb1645fd17c4f171fe6b22198021.png" class="image_10 image_13" />
          <div class="flex-col group_18">
            <div class="flex-row group_19">
              <div class="left-section"></div>
              <span class="text_33">加速线索转化</span>
            </div>
            <span class="text_34"
              >基于行为意图分析数据和营销自动化技术，自动推荐贴合客户需求的产品和方案，大幅度提升客户体验，提升线索转化效率。</span
            >
          </div>
          <div class="flex-col group_20">
            <div class="flex-row group_21">
              <div class="left-section"></div>
              <span class="text_35">增加续约机率</span>
            </div>
            <span class="text_36"
              >通过长期的客户行为意图洞察，使客户感受到企业更“懂”自己，增加信任感，更容易达成复购和交叉销售。</span
            >
          </div>
          <div class="flex-col group_22">
            <div class="flex-row group_23">
              <div class="left-section"></div>
              <span class="text_37">提高投资回报</span>
            </div>
            <span class="text_38"
              >企业可将资源用于成交概率高的潜在客户上，从而提高营销和销售的有效性，实现业绩回报和人效提升的双赢。</span
            >
          </div>
          <div @click="goformPageFun()" class="button_1 flex-col items-center view_15">
            <span>立即体验</span>
          </div>
        </div>
        <div class="flex-col section_6">
          <span class="text_40">成交客户流失可能性预测</span>
          <img src="@/res/remote/03f1961deb852ebbe90b1e5ac5ee12f6.png" class="image_10 image_14" />
          <div class="group_24 flex-col view_16">
            <div class="top-group flex-row">
              <div class="left-section"></div>
              <span class="text_41">成交客户行为分析</span>
            </div>
            <span class="text_43"
              >客户成交并不意味着完全进入稳定期，仍然需要定期的关键行为分析，输出阶段情报分析报告。</span
            >
          </div>
          <div class="group_24 flex-col view_19">
            <div class="top-group flex-row">
              <div class="left-section"></div>
              <span class="text_41">成交客户流失预警</span>
            </div>
            <span class="text_45"
              >通过对成交客户的行为意图数据的实时获取与分析，及时向销售预警客户频繁访问竞品官网等行为，将客户流失风险降至最低</span
            >
          </div>
          <div @click="goformPageFun()" class="button_1 flex-col items-center view_22">
            <span>立即体验</span>
          </div>
          <span class="text_47">成功案例</span>
            <div class="mySwiperPar">
              <div class="swiper mySwiper mySwiper1">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="flex-col group_25">
                      <div class="flex-row section_7">
                        <span>
                          <img src="@/res/local/logo_28.png" class="image_15 image_15_new" />
                        </span>
                        
                        <img src="@/res/local/994a00cf5c8f903da4989e3b6874619d.png" class="image_16" />
                        <span class="text_48 text_49"
                          >由于对行为意图分析的深度认知，客户在选择中国区供应商时，毫不犹豫选择了国内首屈一指能够实现意图数据捕获和建模能力的火眼云。火眼通过客户给出的关键词建立行为分析规则，通过算法观察目标客户的关键行为命中，并给出意图评估和企业及关键决策人行为意图洞察，让该客户能够有依据地做出营销策略和商业决策，加速了客户的成单进度</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="flex-col group_25">
                      <div class="flex-row section_7">
                        <span>
<img src="@/res/local/logo_7.png" class="image_15 image_15_new" />
                        </span>
                        
                        <img src="@/res/local/994a00cf5c8f903da4989e3b6874619d.png" class="image_16" />
                        <span class="text_48 text_49"
                          >处在数字化转型的大方向下，该企业大胆探索新模式，选择火眼云进行共同“破局”，火眼云特有的行为意图分析解决方案，帮助该企业锁定目标客户，并实现定向精准触达，该企业市场部借助行为意图分析方案成功锁定多位目标客户，并成功转化为线索，成功展现市场部门价值，实现与销售部门强效协同。</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>
            </div>
          <div class="flex-col group_26">
            <span class="text_50">他们也在用</span>
            <div class="flex-row group_27">
              <div class="grid">
                <div class="flex-col items-center grid-item">
                  <img src="@/res/local/SAP_logo.png" class="image_18" />
                </div>
                <div class="grid-item_1 flex-col items-center">
                  <img src="@/res/local/huaweiyun_logo.png" class="image_18" />
                </div>
                <div class="grid-item_1 flex-col items-center">
                  <img src="@/res/local/logo_26.png" class="image_18" />
                </div>
                <div class="flex-col items-center grid-item_2">
                  <img src="@/res/local/logo_10.png" class="image_18" />
                </div>
              </div>
              <div class="flex-col items-center image-wrapper_2">
                <img src="@/res/local/zhenyun_logo.png" class="image_18" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="text_51">了解更多ABM营销自动化方案</span>
      <div class="mySwiperPar1"> 
          <div class="swiper mySwiper mySwiper2">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="flex-col group_31" @click="goOtherPageFun('/marketing-digital-transformation.html')">
                  <div class="flex-col items-center image-wrapper_3">
                    <img src="@/res/local/lujing13_c1.png" class="image_24" />
                  </div>
                  <span class="text_62">B2B数字化转型</span>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="flex-col group_31" @click="goOtherPageFun('/abm_grow.html')">
                  <div class="flex-col items-center image-wrapper_3">
                    <img src="@/res/local/lujing13.png" class="image_24" />
                  </div>
                  <span class="text_62">ABM一站式增长</span>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="flex-col group_31" @click="goOtherPageFun('/wechat-marketing.html')">
                  <div class="flex-col items-center image-wrapper_3">
                    <img src="@/res/local/lujing13_c3.png" class="image_24" />
                  </div>
                  <span class="text_62">私域营销</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- <div class="flex-col group_29">
        <div class="flex-col section_8">
          <span class="text_53">留下您的联系方式<br />专属顾问会尽快与您联系</span>
          <div class="justify-between search">
            <div class="flex-row group_30">
              <img src="@/res/local/16376631404543724564.png" class="image_25" />
              <span class="text_54">请输入您的手机号码</span>
            </div>
            <div class="flex-col items-center text-wrapper_3">
              <span>免费试用</span>
            </div>
          </div>
          <div class="group_31">
            <span class="text_56">如有疑问，欢迎致电客服热线</span>
            <span class="text_57">400-8521-789</span>
          </div>
        </div>
        <div class="text_48 flex-col view_23">
          <div class="section_9"></div>
          <div class="justify-between section_10">
            <div class="flex-col group_32">
              <div class="group_33">
                <span class="text_58">电话：</span>
                <span class="text_59">400-8521-789</span>
                <span class="text_60">邮箱： </span>
                <span class="text_61">marketing@huoyan.cn</span>
              </div>
              <span class="text_62">©2017 北京绘客科技有限公司 <br />京ICP备16023115号-1 隐私政策</span>
            </div>
            <img src="@/res/remote/06c5f76966fbb88f09c7d54f941ea701.png" class="image_26" />
          </div>
          <img src="@/res/local/52ca3200bc469c20b13881eecea2030c.svg" class="image_27" />
        </div>
      </div> -->
      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
import vFooter from '@/components/footer.vue'
import Swiper from 'swiper';
  export default {
    data() {
      return { listU2vCjKsj: [null, null, null] };
    },
        components: {
      vFooter,vHeader
    },
    mounted(){
      var swiper = new Swiper(".mySwiper1", {
        pagination: {
          el: ".swiper-pagination",
        },
        // loop:true
      });
      var swiper1 = new Swiper('.mySwiper2', {
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
      });
    },
    methods: {
      goformPageFun(val){
        if(val==1){
          window.location.href='/form.html?mobile='+this.input1
        }else{
          window.location.href='/form.html?mobile='
        }
      },
      goOtherPageFun(path){
        window.location.href = path
      }
    }
  };
</script>

<style scoped lang="css">
/deep/.swiper-pagination-bullet-active{
  background-color: #FF6A00;
}
    .mySwiperPar{
      position: relative;
      overflow: hidden;
      padding-bottom: 3rem;
    }
      .swiper {
        width: 100%;
      }

      .swiper-slide {

      }
    .mySwiperPar1{
      position: relative;
      overflow: hidden;
    }
    .mySwiper2 .swiper-slide {
      transition: 300ms;
      z-index: 1;
      transform: scale(0.8);
    }
    .mySwiper2 .swiper-slide-active,.mySwiper1 .swiper-slide-duplicate-active{
        transform: scale(1);
        z-index: 2;
    }

    .mySwiper2 .swiper-slide span{
      display: none;
      width: 9rem;
      /* text-align: center; */
      /* padding-top: 16px; */
    }
    .mySwiper2 .swiper-slide-active span{
      display: block;
    }
    /* .image_15_new{
      height: 1.3rem !important;
    } */
  .text_48 {
    position: relative;
  }
  .group_6 {
    padding: 0 0.31rem;
  }
  .button_1 {
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      99.8deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 82.66%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.56rem 0px rgba(255, 65, 65, 0.1);
    border-radius: 0.13rem;
    width: 6.25rem;
  }
  .image_10 {
    align-self: center;
    width: 20rem;
    height: 12.5rem;
  }
  .group_24 {
    padding: 0 0.25rem;
  }
  .list-item {
    padding: 0.75rem 0.5rem 1.25rem 1rem;
    background-image: linear-gradient(
      180deg,
      rgb(246, 247, 251) 0%,
      rgb(246, 247, 251) 0%,
      rgb(253, 253, 255) 100%,
      rgb(253, 253, 255) 100%
    );
    box-shadow: 0px 0.13rem 1.5rem 0px rgba(227, 227, 227, 0.5);
    border-radius: 0.25rem;
    border: solid 0.25rem rgb(255, 255, 255);
  }
  .left-section {
    align-self: center;
    background-color: rgb(216, 216, 216);
    border-radius: 50%;
    width: 0.25rem;
    height: 0.25rem;
  }
  .image_6 {
    width: 3rem;
    height: 3rem;
  }
  .right-group {
    margin-left: 1rem;
    flex: 1 1 auto;
  }
  .grid-item_1 {
    padding: 0.56rem 0 0.63rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.13rem;
    position: relative;
  }
  .image_18 {
    width: 5.63rem;
    height: 1.75rem;
  }
  .text_6 {
    color: rgb(38, 38, 38);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.38rem;
    white-space: nowrap;
  }
  .text_8 {
    margin-right: 0.88rem;
    margin-top: 0.69rem;
    color: rgb(89, 89, 89);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: left;
  }
  .top-group {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_18 {
    margin-left: 0.5rem;
  }
  .text_41 {
    margin-left: 0.5rem;
  }
  .container {
    padding-top:3.5rem;
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
  }
  .header {
    padding: 0.84rem 0.88rem 0.72rem 2.19rem;
    color: rgb(0, 0, 0);
    font-size: 0.94rem;
    line-height: 1.13rem;
    letter-spacing: -0.019rem;
    white-space: nowrap;
    height: 2.69rem;
  }
  .group_1 {
    flex: 1 1 auto;
    overflow-y: auto;
  }
  .group {
    margin: 0.19rem 0 0.22rem;
  }
  .text_51 {
    margin-top: 2.5rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_28 {
    margin-top: 2.19rem;
    padding: 0 0.81rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .group_29 {
    margin-top: 3.25rem;
  }
  .image {
    width: 1.06rem;
    height: 0.69rem;
  }
  .image_1 {
    margin-left: 0.28rem;
    width: 1rem;
    height: 0.69rem;
  }
  .image_2 {
    margin-left: 0.28rem;
    width: 1.53rem;
    height: 0.72rem;
  }
  .section_1 {
    padding: 0.88rem 1rem 0.81rem;
    color: rgb(255, 106, 0);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(0, 0, 0, 0.03);
    position: relative;
  }
  .group_4 {
    padding: 1.69rem 1.25rem 1.88rem;
  }
  .section_5 {
    padding: 1.38rem 1.56rem 1.13rem;
    background-color: rgb(248, 249, 251);
  }
  .group_17 {
    padding: 2.5rem 1.56rem 2.44rem;
  }
  .section_6 {
    padding: 1.75rem 1.25rem 1.63rem 1.31rem;
    background-color: rgb(248, 249, 251);
  }
  .image-wrapper_3 {
    background-image: url('https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/6198d70c8611d00011b57c2f/6198d8928611d00011b57c86/16377417725036935418.png');
    background-position: 0px 0.31rem;
    background-size: 100% 91%;
    background-repeat: no-repeat;
  }
  .text_52 {
    margin-top: 1.25rem;
    align-self: center;
  }
  .section_8 {
    padding: 1.94rem 1.69rem 1.31rem 1.75rem;
    background-color: rgb(249, 250, 252);
  }
  .group_3 {
    align-self: center;
  }
  .text-wrapper {
    padding: 0.44rem 0;
    background-color: rgba(255, 106, 0, 0.05);
    border-radius: 1rem;
    width: 5rem;
    height: 2rem;
  }
  .text_2 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.06rem;
    white-space: nowrap;
  }
  .text_3 {
    margin-top: 0.88rem;
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .image_5 {
    margin-top: 1.44rem;
    align-self: center;
    width: 19.13rem;
    height: 14.5rem;
  }
  .button {
    margin-top: 2.44rem;
    padding: 0.56rem 0;
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.38rem;
    white-space: nowrap;
    background-color: rgb(255, 106, 0);
    border-radius: 0.13rem;
    width: 11.13rem;
  }
  .group_5 {
    margin-top: 2.44rem;
  }
  .view_3 {
    margin-top: 4rem;
  }
  .view_5 {
    margin-top: 1.63rem;
  }
  .view_7 {
    margin-top: 1.88rem;
  }
  .text-wrapper_1 {
    margin-top: 2.63rem;
  }
  .text_24 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .image_12 {
    margin-top: 1.69rem;
  }
  .group_11 {
    margin-top: 2.13rem;
  }
  .group_13 {
    margin-top: 1.63rem;
  }
  .group_15 {
    margin-top: 1.63rem;
  }
  .text-wrapper_2 {
    margin-top: 2.13rem;
  }
  .text_32 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .image_13 {
    margin-top: 1.75rem;
  }
  .group_18 {
    margin-top: 2.06rem;
  }
  .group_20 {
    margin-top: 1.63rem;
  }
  .group_22 {
    margin-top: 1.88rem;
  }
  .view_15 {
    margin-top: 2.38rem;
  }
  .text_40 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .image_14 {
    margin-top: 1.88rem;
  }
  .view_16 {
    margin-top: 1.94rem;
  }
  .view_19 {
    margin-top: 1.88rem;
  }
  .view_22 {
    margin-top: 2.38rem;
  }
  .text_47 {
    margin-top: 3.31rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_25 {
    margin-top: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.19rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    line-height: 1.63rem;
  }
  .group_26 {
    margin-top: 2.81rem;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .image_24 {
    filter: drop-shadow(0px 0.063rem 0.44rem rgba(174, 174, 174, 0.64));
    width: 11.44rem;
    /* height: 7.13rem; */
  }
  .text_53 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.13rem;
    font-weight: 500;
    line-height: 1.13rem;
    text-align: center;
    width: 12.38rem;
  }
  .search {
    margin-top: 0.88rem;
    padding: 0.5rem 0.31rem 0.44rem 0.56rem;
    filter: drop-shadow(0px 0.88rem 0.66rem #1c25330d);
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    border: solid 0.063rem rgb(222, 222, 222);
  }
  .group_31 {
    margin-top: 1.13rem;
    align-self: center;
    text-align: center;
    /* width: 11.38rem; */
  }
  .section_9 {
    background-color: rgb(31, 41, 55);
    height: 2.06rem;
  }
  .section_10 {
    padding: 3.25rem 1.06rem 2.31rem 1.44rem;
    background-color: rgb(31, 41, 55);
  }
  .image_27 {
    width: 7.25rem;
    height: 2.25rem;
    position: absolute;
    left: 1.44rem;
    top: 1.44rem;
  }
  .image_3 {
    align-self: center;
    width: 1rem;
    height: 1rem;
  }
  .image_4 {
    margin-left: 1.5rem;
    width: 4.94rem;
    height: 1.5rem;
  }
  .text_5 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .list {
    margin-top: 0.75rem;
  }
  .text_14 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_15 {
    margin-top: 0.88rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .image_11 {
    margin-top: 1.5rem;
  }
  .group_7 {
    margin-top: 1.13rem;
  }
  .group_9 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_20 {
    margin-left: 0.75rem;
    margin-top: 0.56rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .group_10 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_22 {
    margin-left: 0.75rem;
    margin-top: 0.63rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: left;
  }
  .group_12 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_26 {
    margin: 0.63rem 0.88rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: justify;
  }
  .group_14 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_28 {
    margin: 0.63rem 0.88rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: justify;
  }
  .group_16 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_30 {
    margin: 0.63rem 0.88rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: justify;
  }
  .group_19 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_34 {
    margin: 0.63rem 0.88rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: left;
  }
  .group_21 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_36 {
    margin-left: 0.75rem;
    margin-top: 0.56rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .group_23 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_38 {
    margin-left: 0.75rem;
    margin-top: 0.56rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_43 {
    margin: 0.63rem 0.94rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: left;
  }
  .text_45 {
    margin: 0.56rem 0.13rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .section_7 {
    padding: 1.31rem 0.94rem 3rem;
    overflow: hidden;
    border-radius: 0.94rem;
    background-color: rgb(255, 255, 255);
    height: 16.81rem;
    position: relative;
  }
  .image_17 {
    margin-top: 0.94rem;
    align-self: center;
    width: 1.38rem;
    height: 0.25rem;
  }
  .text_50 {
    align-self: center;
  }
  .group_27 {
    margin-top: 1rem;
  }
  .group_30 {
    margin: 0.19rem 0 0.31rem;
    color: rgb(199, 199, 199);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text-wrapper_3 {
    padding: 0.44rem 0;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-image: linear-gradient(
      102.2deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 81.34%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(255, 65, 65, 0.5);
    border-radius: 0.25rem;
    width: 5rem;
    height: 2rem;
  }
  .text_56 {
    color: rgb(137, 137, 137);
    font-size: 0.88rem;
    line-height: 1.5rem;
  }
  .text_57 {
    color: rgb(255, 106, 0);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.38rem;
  }
  .group_32 {
    width: 12.44rem;
  }
  .image_26 {
    margin-top: 2.63rem;
    width: 6.25rem;
    height: 6.25rem;
  }
  .list-item:not(:first-of-type) {
    margin-top: 0.75rem;
  }
  .group_8 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_17 {
    margin: 0.63rem 0.63rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: left;
  }
  .text_25 {
    margin-left: 0.5rem;
  }
  .text_27 {
    margin-left: 0.5rem;
  }
  .text_29 {
    margin-left: 0.5rem;
  }
  .text_33 {
    margin-left: 0.5rem;
  }
  .text_35 {
    margin-left: 0.5rem;
  }
  .text_37 {
    margin-left: 0.5rem;
  }
  .image_15 {
    width: 3rem;
    /* height: 3rem; */
  }
  .image_16 {
    width: 4.63rem;
    height: 4.25rem;
    position: absolute;
    left: 0;
    top: 12.56rem;
  }
  .text_49 {
    margin-left: 1.19rem;
    margin-right: 0.13rem;
    flex: 1 1 auto;
    text-align: justify;
  }
  .grid {
    flex: 1 1 auto;
    height: 6.38rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 0.5rem;
    grid-column-gap: 0.56rem;
  }
  .image-wrapper_2 {
    margin-left: 0.5rem;
    padding: 0.56rem 0 0.63rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.13rem;
    width: 6.63rem;
    height: 2.94rem;
  }
  .image_25 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .text_54 {
    margin-left: 0.81rem;
    margin-top: 0.19rem;
  }
  .group_33 {
    text-align: left;
    width: 11.56rem;
  }
  .text_62 {
    margin-top: 1rem;
    color: rgb(140, 140, 140);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: left;
  }
  .text_16 {
    margin-left: 0.5rem;
  }
  .grid-item {
    padding: 0.63rem 0 0.56rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.63rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.094rem;
    position: relative;
  }
  .grid-item_2 {
    padding: 0.56rem 0 0.63rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.13rem;
  }
  .text_58 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 2.19rem;
  }
  .text_59 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 2.19rem;
  }
  .text_60 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 1.25rem;
  }
  .text_61 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 1.25rem;
  }
</style>