<template>
  <div class="flex-col container">
    <div class="flex-col group_1">
      <div class="flex-col group_2">
        <v-header></v-header>
        <div class="flex-col section_2">
          <div class="flex-col items-center group_4">
            <span class="text_2">客户数据平台（CDP）</span>
            <span class="text_3">激活企业数据价值加速营销增长</span>
          </div>
          <img src="@/res/remote/0368d1f054d4aa895f7a5341d1a9ba44.png" class="image_5" />
          <span class="text_4">打通、补充、规范企业多源数据并以OneID能力统一客户视图，实现营销数字化转型</span>
          <div class="section_3 justify-between view">
            <!-- <div class="left-group flex-row">
              <img src="@/res/local/16376631404543724564.png" class="image_6" />
              <span class="text_5">请输入您的手机号码</span>
            </div> -->
            <img src="@/res/local/16376631404543724564.png" class="image_6 image_7_1" />
            <input v-model="input1" class="left-group flex-row" type="text" placeholder="请输入您的手机号码">
            <div @click="goformPageFun(1)" class="right-text-wrapper flex-col items-center">
              <span>预约演示</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col group_5">
        <span class="text_8">功能亮点</span>
        <span class="text_9">客户匿名访问行为归集，跨渠道创建个性化体验</span>
        <div class="flex-col group_6">
          <div class="section_4 flex-row view_3">
            <img src="@/res/local/a3de98d5279a9a6b5e399babf8af2bc5.png" class="image_8" />
            <div class="flex-col group_7">
              <span class="text_10">提升洞察客户的能力</span>
              <span class="text_12 text_13">构建完善的客户标签体系及全景画像，提升对客户的认知能力</span>
            </div>
          </div>
          <div class="section_4 flex-row">
            <img src="@/res/local/6ad96f52bcac82500f95c4366c359dd0.png" class="image_8" />
            <div class="flex-col group_8">
              <span class="text_10">激活自有数据价值</span>
              <span class="text_12">将企业各种渠道的营销数据进行标准化清洗，并补全企业一方数据</span>
            </div>
          </div>
          <div class="section_4 flex-row">
            <img src="@/res/local/cd92086c8581217d069ac80e7b6f0d44.png" class="image_8" />
            <div class="flex-col group_9">
              <span class="text_10">数字化精准营销</span>
              <span class="text_12">打通各渠道营销数据，构建统一的客户视图，并跨平台进行营销触达</span>
            </div>
          </div>
          <div class="section_4 flex-row">
            <img src="@/res/local/341c31d02ca140b7d584ca8f1347cf88.png" class="image_8" />
            <div class="flex-col group_10">
              <span class="text_10">部门有效协同</span>
              <span class="text_12">市场与销售、客户成功等部门进行有效联动，为客户创造一致的体验</span>
            </div>
          </div>
        </div>
        <span class="text_20">多源数据整合与治理</span>
        <div class="flex-col items-center group_11">
          <span>将各渠道数据进行收集、整合、清洗，</span>
          <span class="text_22">并统一客户360视图</span>
        </div>
        <img src="@/res/local/b05ea8777d5728e9c358e67da4aebc10.svg" class="image_13 image_14" />
        <div class="flex-col group_12">
          <div class="flex-row group_13">
            <div class="left-section"></div>
            <span class="text_23">多场景营销数据实时动态收集</span>
          </div>
          <div class="flex-row group_14">
            <div class="left-section"></div>
            <span class="text_24">OneID识别统一客户身份</span>
          </div>
          <div class="flex-row group_15">
            <div class="left-section"></div>
            <span class="text_25">火眼云三方数据补充企业一方数据</span>
          </div>
        </div>
        <div @click="goformPageFun()" class="button flex-col items-center text-wrapper_1">
          <span>免费试用</span>
        </div>
        <span class="text_27">匿名流量的识别与整合</span>
        <div class="flex-col group_16">
          <div class="flex-col items-center group_17">
            <span>识别与整合各个渠道中的匿名流量，</span>
            <span class="text_29">大幅提升线索数量与转化率</span>
          </div>
          <img src="@/res/remote/4498bb0ec00cdf5bb93c9d7845215d64.svg" class="image_15" />
        </div>
        <div class="flex-col group_18">
          <div class="flex-row group_19">
            <div class="left-section"></div>
            <span class="text_30">识别和管理官网与广告中匿名流量的浏览、点击行为</span>
          </div>
          <div class="flex-row group_20">
            <div class="left-section"></div>
            <span class="text_31">识别微信粉丝与微信群友身份，并获取行为轨迹</span>
          </div>
          <div class="flex-row group_21">
            <div class="left-section"></div>
            <span class="text_32">与企业自有数据合并，实现统一客户视图</span>
          </div>
        </div>
        <div @click="goformPageFun()" class="button flex-col items-center">
          <span>免费试用</span>
        </div>
        <span class="text_34">第三方数据补充赋能</span>
        <div class="group_22 flex-col view_13">
          <div class="flex-col items-center group_23">
            <span>基于火眼云三方数据能力，补充、合并、</span>
            <span class="text_36">规范所有企业一方数据</span>
          </div>
          <img src="@/res/remote/210324af8d3a1641b7701f16b8c47fb9.svg" class="image_13 image_16" />
        </div>
        <div class="flex-col group_24">
          <div class="flex-row group_25">
            <div class="left-section"></div>
            <span class="text_37">为您创建一致且持续更新的目标客户资料</span>
          </div>
          <div class="flex-row group_26">
            <div class="left-section"></div>
            <span class="text_38">对目标客户群进行细分，展开更触达人心的互动</span>
          </div>
          <div class="flex-row group_27">
            <div class="left-section"></div>
            <span class="text_39">将目标客户的意图数据推送给企业，分析客户采购意向</span>
          </div>
        </div>
        <div @click="goformPageFun()" class="button flex-col items-center">
          <span>免费试用</span>
        </div>
        <div class="group_22 flex-col view_17">
          <span class="text_41">精准的标签与画像体系</span>
          <div class="flex-col group_28">
            <span class="text_42">根据目标客户属性与行为，动态生成标签，并结合多维度数据建立客户的360画像</span>
            <img src="@/res/remote/01fcbf7f04663e5a2e6c68ab77182443.svg" class="image_13 image_17" />
          </div>
        </div>
        <div class="flex-col group_29">
          <div class="flex-row group_30">
            <div class="left-section"></div>
            <span class="text_43">完整的静态标签和动态标签体系</span>
          </div>
          <div class="flex-row group_31">
            <div class="left-section"></div>
            <span class="text_44">360度客户画像，还原客户完整行为</span>
          </div>
          <div class="flex-row group_32">
            <div class="left-section"></div>
            <span class="text_45">多维度标签交叉组合获取精准人群</span>
          </div>
        </div>
        <div @click="goformPageFun()" class="button flex-col items-center">
          <span>免费试用</span>
        </div>
        <div class="flex-col group_33">
          <span class="text_47">线索全生命周期管理</span>
          <span class="text_48">管理每个客户的旅程阶段，对他们执行符合偏好和行为的个性化沟通，加速转化</span>
        </div>
        <img src="@/res/local/e51db1451cfcbefa16e9ac3d51e4b652.svg" class="image_13 image_18" />
        <div class="flex-col group_34">
          <div class="flex-row group_35">
            <div class="left-section"></div>
            <span class="text_49">以线索行为动作为营销自动化指令，创建个性化营销触达</span>
          </div>
          <div class="flex-row group_36">
            <div class="left-section"></div>
            <span class="text_50">当线索有高意向行为后立即发送特定主题营销内容</span>
          </div>
          <div class="flex-row group_37">
            <div class="left-section"></div>
            <span class="text_51">多渠道内容统一发布，提升客户营销体验</span>
          </div>
        </div>
        <div @click="goformPageFun()" class="button flex-col items-center">
          <span>免费试用</span>
        </div>
        <span class="text_53">应用场景</span>
      </div>
      <div class="flex-col group_38">
        <div class="flex-col section_5">
          <img src="@/res/local/86c5fda621b6849da4c66f5681af1c6e.png" class="image_19 image_20" />
          <div class="flex-col group_39">
            <span class="text_54">制定营销策略</span>
            <span class="text_55">客户生命周期管理，可针对不同阶段的客户制定营销策略，挖掘客户最大价值</span>
          </div>
        </div>
        <div class="flex-col section_6">
          <img src="@/res/local/acedfd7b7ca26b4fcbc8a35b44c3b64b.png" class="image_19 image_21" />
          <div class="flex-col group_40">
            <span class="text_56">个性化精准营销</span>
            <span class="text_57">根据客户属性、关键行为及特征进行人群细分，并针对各个细分人群进行相应的营销</span>
          </div>
        </div>
        <div class="flex-col section_7">
          <img src="@/res/local/b06dff3ab3520b4b3d69cce5d790400f.png" class="image_19 image_22" />
          <div class="flex-col group_41">
            <span class="text_58">客户数据完善</span>
            <span class="text_59">为企业补充、合并、规范原有客户数据字段，并创建一致且持续更新的客户资料</span>
          </div>
        </div>
        <div class="flex-col section_8">
          <img src="@/res/local/ee76a110c15c6c2b9c91e7de8fb60933.png" class="image_19 image_23" />
          <div class="flex-col group_42">
            <span class="text_60">广告投放监测</span>
            <span class="text_61">整合全部投放渠道，完成从流量到转化的全流程广告效果监测，降低平均获客成本</span>
          </div>
        </div>
        <div class="flex-col section_9">
          <img src="@/res/local/88e6435256060f60652d62648b54896f.png" class="image_19 image_24" />
          <div class="flex-col group_43">
            <span class="text_62">渠道归因分析</span>
            <span class="text_63">跟踪每个渠道，了解哪个渠道表现最佳，借助多触点归因，深入了解客户旅程及其偏好</span>
          </div>
        </div>
        <div class="flex-col section_10">
          <img src="@/res/local/4c3fa6252749f9a2349c205122055e8c.png" class="image_19 image_25" />
          <div class="flex-col group_44">
            <span class="text_64">发现潜在市场</span>
            <span class="text_65">确定您的最佳客户，发现更多潜在市场机会，并可视化实时信息来规划GTM 策略</span>
          </div>
        </div>
      </div>
      <div class="flex-col group_45">
        <div class="flex-col group_46">
          <span class="text_66">火眼云一站式营销增长产品矩阵</span>
          <span class="text_67">找到目标客户 、发现关键意图 、创新营销体验，并开展爆炸式营销增长</span>
          <div class="flex-row group_47">
            <div class="flex-col items-center group_48">
              <div class="section_11">
                <span class="liuguang"></span>
              </div>
              <img src="@/res/local/a8e129945b8c9d085b5d3356e13e4bef.png" class="image_26" />
              <img src="@/res/local/dbc56334b545bcdc59619a25af96c5ad.png" class="image_27" />
              <img src="@/res/local/548757465bc8230d91f7a254af8da972.png" class="image_28" />
              <img src="@/res/local/8c13bce909108634edc08da1d14465bd.png" class="image_29" />
            </div>
            <div class="flex-col group_49">
              <div @click="goOtherPageFun('/abm-marketing-cloud.html')" class="flex-col group_50">
                <span class="text_68">ABM营销云</span>
                <span class="text_69">让大规模、个性化执行目标客户营销（ABM）策略成为可能</span>
              </div>
              <div @click="goOtherPageFun('/Intent-cloud.html')" class="flex-col group_51">
                <span class="text_70">情报云</span>
                <span class="text_71">通过企业情报和行为意图，实时定位对您产品或服务感兴趣的潜在客户</span>
              </div>
              <div @click="goOtherPageFun('/cdp.html')" class="flex-col group_52">
                <span class="text_72">客户数据平台（CDP）</span>
                <span class="text_73">打通、补充、规范企业多源数据并以OneID能力统一客户视图，实现营销数字化转型</span>
              </div>
              <div @click="goOtherPageFun('/ad-cloud.html')" class="flex-col group_53">
                <span class="text_74">广告云</span>
                <span class="text_75">进行数字广告的全渠道投放、监测、归因分析以及投放策略优化</span>
              </div>
            </div>
          </div>
        </div>
        <div @click="goOtherPageFun('/data-cloud.html')" class="flex-row section_12">
          <img src="@/res/local/7afc9facdfff43874d0ffaf4318ef4c3.png" class="image_19 image_30" />
          <div class="flex-col group_54">
            <span class="text_76">数据云（DMP）</span>
            <span class="text_77"
              >覆盖6000万企业主体及1.6亿职场人群画像，掌握B2B企业情报及意向数据，帮助您识别、理解和吸引理想的目标客户</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="flex-col group_55">
        <div class="flex-col section_13">
          <span class="text_78">留下您的联系方式<br />专属顾问会尽快与您联系</span>
          <div class="section_3 justify-between section_14">
            <div class="left-group flex-row">
              <img src="@/res/local/16376631404543724564.png" class="image_6" />
              <span class="text_5">请输入您的手机号码</span>
            </div>
            <div class="right-text-wrapper flex-col items-center">
              <span>免费试用</span>
            </div>
          </div>
          <div class="group_56">
            <span class="text_81">如有疑问，欢迎致电客服热线</span>
            <span class="text_82">400-8521-789</span>
          </div>
        </div>
        <div class="flex-col group_57">
          <div class="section_15"></div>
          <div class="justify-between section_16">
            <div class="flex-col group_58">
              <div class="group_59">
                <span class="text_83">电话：</span>
                <span class="text_84">400-8521-789</span>
                <span class="text_85">邮箱： </span>
                <span class="text_86">marketing@huoyan.cn</span>
              </div>
              <span class="text_87">©2017 北京绘客科技有限公司 <br />京ICP备16023115号-1 隐私政策</span>
            </div>
            <img src="@/res/remote/06c5f76966fbb88f09c7d54f941ea701.png" class="image_32" />
          </div>
          <img src="@/res/local/52ca3200bc469c20b13881eecea2030c.svg" class="image_33" />
        </div>
      </div> -->
      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
import vFooter from '@/components/footer.vue'
  export default {
    data() {
      return {
        input1:''
      };
    },
        components: {
      vFooter,vHeader
    },
    methods: {
      goformPageFun(val){
        var mobileReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if(val == 1){
          if(!this.input1){
            window.location.href= '/form.html?mobile='
          } else if(mobileReg.test(this.input1)){
             window.location.href = '/form.html?mobile='+this.input1
          } else {
            alert('手机格式错误!')
          }
        }else{
          window.location.href= '/form.html?mobile='
        }
      },
      goOtherPageFun(path){
        window.location.href = path
      }
    }
  };
</script>

<style scoped lang="css">
  .image_13 {
    width: 20.06rem;
    height: 12.5rem;
  }
  .button {
    margin-top: 1.69rem;
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      99.8deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 82.66%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.56rem 0px rgba(255, 65, 65, 0.1);
    border-radius: 0.13rem;
    width: 6.25rem;
  }
  .group_22 {
    padding: 0 0.44rem;
  }
  .section_3 {
    position: relative;
    height: 49px;
    /* padding: 0.5rem 0.31rem 0.44rem 0.56rem; */
    filter: drop-shadow(0px 0.88rem 0.66rem #1c25330d);
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    border: solid 0.063rem rgb(222, 222, 222);
  }
  .section_3 input{
     width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 10px;
    padding-left:2rem;
   }
  .image_7_1{
    position: absolute;
    top: 0.7rem;
    left:.3125rem
  }
  .section_4 {
    margin-top: 1rem;
    padding: 1.5rem 1.5rem 1.75rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 1.13rem 0px rgb(243, 244, 246);
    border-radius: 0.25rem;
  }
  .image_19 {
    width: 5rem;
    height: 5rem;
  }
  .right-text-wrapper {
    position: absolute;
    right:.625rem;
    top: 0.4rem;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      102.2deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 81.34%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(255, 65, 65, 0.5);
    border-radius: 0.25rem;
    width: 5rem;
    height: 2rem;
  }
  .image_8 {
    width: 3rem;
    height: 3rem;
  }
  .left-section {
    align-self: center;
    background-color: rgb(212, 212, 212);
    border-radius: 50%;
    width: 0.38rem;
    height: 0.38rem;
  }
  .image_6 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .text_10 {
    color: rgb(51, 51, 51);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .left-group {
    margin: 0.19rem 0 0.31rem;
    color: rgb(199, 199, 199);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_5 {
    margin-left: 0.81rem;
    margin-top: 0.19rem;
  }
  .text_12 {
    margin-top: 0.81rem;
    color: rgb(35, 35, 35);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .container {
    padding-top:3.5rem;
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
  }
  .header {
    padding: 0.84rem 0.88rem 0.72rem 2.19rem;
    color: rgb(0, 0, 0);
    font-size: 0.94rem;
    line-height: 1.13rem;
    letter-spacing: -0.019rem;
    white-space: nowrap;
    height: 2.69rem;
  }
  .group_1 {
    flex: 1 1 auto;
    overflow-y: auto;
  }
  .group {
    margin: 0.19rem 0 0.22rem;
  }
  .group_5 {
    margin-top: 2.5rem;
    padding-left: 1.25rem;
    padding-right: 1rem;
  }
  .group_38 {
    margin-top: 1.88rem;
  }
  .group_45 {
    margin-top: 3.94rem;
    height: 52.25rem;
  }
  .group_55 {
    margin-top: 3.13rem;
  }
  .image {
    width: 1.06rem;
    height: 0.69rem;
  }
  .image_1 {
    margin-left: 0.28rem;
    width: 1rem;
    height: 0.69rem;
  }
  .image_2 {
    margin-left: 0.28rem;
    width: 1.53rem;
    height: 0.72rem;
  }
  .section_1 {
    padding: 0.88rem 1rem 0.81rem;
    color: rgb(255, 106, 0);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(0, 0, 0, 0.03);
  }
  .section_2 {
    margin-top: 0.19rem;
    padding: 2.06rem 1.19rem 1.63rem;
    background-image: linear-gradient(
      180deg,
      rgb(250, 250, 251) 0%,
      rgb(250, 250, 251) 0%,
      rgb(243, 244, 246) 100%,
      rgb(243, 244, 246) 100%
    );
    overflow: hidden;
    height: 29.38rem;
  }
  .text_8 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_9 {
    margin-top: 1rem;
    align-self: center;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .group_6 {
    margin-top: 2rem;
  }
  .text_20 {
    margin-top: 3.69rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_11 {
    margin-top: 1.25rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    white-space: nowrap;
  }
  .image_14 {
    margin-top: 1.13rem;
    align-self: center;
  }
  .group_12 {
    margin-top: 1.44rem;
    padding: 0 3.75rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .text-wrapper_1 {
    margin-top: 1.94rem;
  }
  .text_27 {
    margin-top: 3rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_16 {
    margin-top: 1.25rem;
    padding-left: 0.63rem;
    padding-right: 0.56rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    white-space: nowrap;
  }
  .group_18 {
    margin-top: 1.69rem;
    padding-left: 2.44rem;
    padding-right: 2.31rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .text_34 {
    margin-top: 4.25rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .view_13 {
    margin-top: 1.25rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    white-space: nowrap;
  }
  .group_24 {
    margin-top: 1.75rem;
    padding-left: 1.63rem;
    padding-right: 1.5rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .view_17 {
    margin-top: 3.25rem;
  }
  .group_29 {
    margin-top: 1.75rem;
    padding: 0 1.81rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .group_33 {
    margin-top: 3.25rem;
    padding-left: 0.56rem;
    padding-right: 0.5rem;
  }
  .image_18 {
    margin-top: 2.5rem;
    align-self: center;
  }
  .group_34 {
    margin-top: 1.81rem;
    padding: 0 1.06rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .text_53 {
    margin-top: 3.88rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .section_5 {
    padding: 1.19rem 0.75rem 1.81rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
    border-top: solid 0.26rem rgb(245, 245, 245);
  }
  .section_6 {
    padding: 1.38rem 0.75rem 2.19rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_7 {
    padding: 1.31rem 0.75rem 1.69rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.25rem;
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_8 {
    padding: 1.13rem 0.75rem 1.53rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_9 {
    padding: 1.31rem 0.75rem 1.94rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_10 {
    padding: 1.25rem 0.75rem 2rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
    border-bottom: solid 0.26rem rgb(245, 245, 245);
  }
  .group_46 {
    padding-left: 1.31rem;
    padding-right: 1.25rem;
  }
  .section_12 {
    margin-top: -0.44rem;
    padding: 2.19rem 1.25rem 2rem 1.31rem;
    background-image: linear-gradient(
      90deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.29%,
      rgb(255, 181, 129) 148.28%,
      rgb(255, 181, 129) 100%
    );
    position: relative;
  }
  .section_13 {
    padding: 1.94rem 1.69rem 1.31rem 1.75rem;
    background-color: rgb(249, 250, 252);
  }
  .group_57 {
    padding-bottom: 13.94rem;
    position: relative;
  }
  .group_3 {
    align-self: center;
  }
  .text-wrapper {
    padding: 0.44rem 0;
    background-color: rgba(255, 106, 0, 0.05);
    border-radius: 1rem;
    width: 5rem;
    height: 2rem;
  }
  .image_5 {
    margin-top: 2rem;
    align-self: center;
    width: 17.38rem;
    height: 10.25rem;
  }
  .text_4 {
    margin-top: 1.88rem;
    color: rgb(61, 61, 61);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .view {
    margin: 2.44rem 0.5rem 0 0.56rem;
  }
  .view_3 {
    margin-top: 0;
  }
  .text_22 {
    margin-top: 0.5rem;
  }
  .group_14 {
    margin-top: 1.38rem;
  }
  .group_15 {
    margin-top: 1.38rem;
  }
  .image_15 {
    margin-top: 0.88rem;
    width: 20rem;
    height: 12.5rem;
  }
  .group_20 {
    margin-top: 1.38rem;
  }
  .group_21 {
    margin-top: 1.38rem;
  }
  .image_16 {
    margin-right: 0.25rem;
    margin-top: 0.81rem;
  }
  .group_26 {
    margin-top: 1.38rem;
  }
  .group_27 {
    margin-top: 1.38rem;
  }
  .text_41 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_28 {
    margin-top: 1.25rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
  }
  .group_31 {
    margin-top: 1.38rem;
  }
  .group_32 {
    margin-top: 1.38rem;
  }
  .text_47 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_48 {
    margin-top: 1.25rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: center;
  }
  .group_36 {
    margin-top: 1.38rem;
  }
  .group_37 {
    margin-top: 1.38rem;
  }
  .image_20 {
    align-self: center;
  }
  .group_39 {
    margin-top: 0.94rem;
  }
  .image_21 {
    align-self: center;
  }
  .group_40 {
    margin-top: 0.75rem;
  }
  .image_22 {
    align-self: center;
  }
  .group_41 {
    margin-top: 0.81rem;
  }
  .image_23 {
    align-self: center;
  }
  .group_42 {
    margin-top: 1.03rem;
  }
  .image_24 {
    align-self: center;
  }
  .group_43 {
    margin-top: 0.94rem;
  }
  .image_25 {
    align-self: center;
  }
  .group_44 {
    margin-top: 1.06rem;
  }
  .text_66 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_67 {
    margin: 1.25rem 0.44rem 0 0.56rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: center;
  }
  .group_47 {
    margin-top: 3.06rem;
  }
  .image_30 {
    align-self: center;
  }
  .group_54 {
    margin-left: 1.25rem;
    flex: 1 1 auto;
  }
  .text_78 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.13rem;
    font-weight: 500;
    line-height: 1.13rem;
    text-align: center;
    width: 12.38rem;
  }
  .section_14 {
    margin-top: 0.88rem;
  }
  .group_56 {
    margin-top: 1.13rem;
    align-self: center;
    text-align: center;
    width: 11.38rem;
  }
  .section_15 {
    background-color: rgb(31, 41, 55);
    height: 2.06rem;
  }
  .section_16 {
    padding: 3.25rem 1.06rem 1.81rem 1.44rem;
    background-color: rgb(31, 41, 55);
    position: absolute;
    left: 0;
    right: 0;
    top: 2.06rem;
  }
  .image_33 {
    width: 7.25rem;
    height: 2.25rem;
    position: absolute;
    left: 1.44rem;
    top: 1.44rem;
  }
  .image_3 {
    align-self: center;
    width: 1rem;
    height: 1rem;
  }
  .image_4 {
    margin-left: 1.5rem;
    width: 4.94rem;
    height: 1.5rem;
  }
  .text_2 {
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_3 {
    margin-top: 0.38rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .group_7 {
    margin-left: 1rem;
    margin-top: 0.38rem;
    flex: 1 1 auto;
  }
  .group_8 {
    margin: 0.38rem 0.19rem 0 1rem;
    flex: 1 1 auto;
  }
  .group_9 {
    margin: 0.38rem 0.19rem 0 1rem;
    flex: 1 1 auto;
  }
  .group_10 {
    margin: 0.38rem 0.19rem 0 1rem;
    flex: 1 1 auto;
  }
  .text_23 {
    margin-left: 0.63rem;
  }
  .text_24 {
    margin-left: 0.63rem;
  }
  .text_25 {
    margin-left: 0.63rem;
    margin-right: 0.5rem;
  }
  .text_29 {
    margin-top: 0.5rem;
  }
  .text_30 {
    margin-left: 0.63rem;
    margin-right: 0.81rem;
  }
  .text_31 {
    margin-left: 0.63rem;
  }
  .text_32 {
    margin-left: 0.63rem;
    margin-right: 0.81rem;
  }
  .text_36 {
    margin-top: 0.5rem;
  }
  .text_37 {
    margin-left: 0.63rem;
    margin-right: 2.44rem;
  }
  .text_38 {
    margin-left: 0.63rem;
  }
  .text_39 {
    margin-left: 0.63rem;
  }
  .text_42 {
    margin-left: 0.13rem;
    text-align: center;
  }
  .image_17 {
    margin-right: 0.25rem;
    margin-top: 1.69rem;
  }
  .text_43 {
    margin-left: 0.63rem;
  }
  .text_44 {
    margin-left: 0.63rem;
  }
  .text_45 {
    margin-left: 0.63rem;
  }
  .text_49 {
    margin-left: 0.63rem;
    margin-right: 1rem;
  }
  .text_50 {
    margin-left: 0.63rem;
    margin-right: 0.19rem;
  }
  .text_51 {
    margin-left: 0.63rem;
    margin-right: 2.63rem;
  }
  .text_54 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_55 {
    margin-right: 0.25rem;
    margin-top: 0.94rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  .text_56 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_57 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  .text_58 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_59 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: center;
  }
  .text_60 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_61 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: center;
  }
  .text_62 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_63 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  .text_64 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_65 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  .group_48 {
    padding-top: 4.56rem;
    width: 5rem;
    position: relative;
  }
  .group_49 {
    margin-left: 1.25rem;
    margin-top: 0.31rem;
    flex: 1 1 auto;
  }
  .text_76 {
    color: rgb(255, 255, 255);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_77 {
    margin-top: 0.38rem;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_81 {
    color: rgb(137, 137, 137);
    font-size: 0.88rem;
    line-height: 1.5rem;
  }
  .text_82 {
    color: rgb(255, 106, 0);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.38rem;
  }
  .group_58 {
    width: 12.44rem;
  }
  .image_32 {
    margin-top: 2.63rem;
    width: 6.25rem;
    height: 6.25rem;
  }
  .text_13 {
    width: 13.13rem;
  }
  .section_11 {
    background-image: linear-gradient(
      180.1deg,
      rgb(255, 211, 134) 0%,
      rgb(255, 211, 134) -38.14%,
      rgb(255, 239, 187) 118.97%,
      rgb(255, 239, 187) 100%
    );
    width: 0.38rem;
    height: 29.19rem;
    position: relative;
    overflow: hidden;
  }
    .liuguang{
    position: absolute;
    bottom:0rem;
    left:0;
    width: 0.38rem;
    height: 20rem;
    background:#f5bf62;
    animation: animate1 1s linear infinite;
  }
  @keyframes animate1 {
      0% {
          bottom: -100%;
      }
      50%,
      100% {
          bottom: 100%;
      }
  }
  .image_26 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .image_27 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 8.13rem;
  }
  .image_28 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 16.25rem;
  }
  .image_29 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 24.38rem;
  }
  .group_51 {
    margin-top: 3.81rem;
  }
  .group_52 {
    margin-top: 3.81rem;
  }
  .group_53 {
    margin-top: 3.81rem;
  }
  .group_59 {
    text-align: left;
    width: 11.56rem;
  }
  .text_87 {
    margin-top: 2.19rem;
    color: rgb(140, 140, 140);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: left;
  }
  .text_68 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_69 {
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    width: 13rem;
  }
  .text_70 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_71 {
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_72 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_73 {
    margin-right: 0.81rem;
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_74 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_75 {
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_83 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 2.19rem;
  }
  .text_84 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 2.19rem;
  }
  .text_85 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 1.25rem;
  }
  .text_86 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 1.25rem;
  }
</style>