<template>
          <div class="flex-col group_55">
            <div class="flex-col section_12">
              <span class="text_90">留下您的联系方式<br />专属顾问会尽快与您联系</span>
              <div class="search justify-between section_13">
                <img src="@/res/local/16376631404543724564.png" class="image_7 image_7_1" />
                <input v-model="input2" class="left-group flex-row" type="text" placeholder="请输入您的手机号码">
                <div @click="goformPageFun(2)" class="right-text-wrapper flex-col items-center">
                  <span>免费试用</span>
                </div>
              </div>
              <div class="group_56">
                <span class="text_93">如有疑问，欢迎致电客服热线</span><br />
                <span class="text_94">400-8521-789</span>
              </div>
            </div>
            <div class="flex-col group_57">
              <div class="section_14"></div>
              <div class="justify-between section_15">
                <div class="flex-col group_58">
                  <div class="group_59">
                    <span class="text_95">电话：</span>
                    <span class="text_96">400-8521-789</span><br />
                    <span class="text_97">邮箱： </span>
                    <span class="text_98">marketing@huoyan.cn</span>
                  </div>
                  <span class="text_99">©2017 北京绘客科技有限公司 <br />京ICP备16023115号-1 隐私政策</span>
                </div>
                <img src="@/res/remote/06c5f76966fbb88f09c7d54f941ea701.png" class="image_53" />
              </div>
              <img src="@/res/local/52ca3200bc469c20b13881eecea2030c.svg" class="image_54" />
              <div class="logo_bottom_par">
                <img src="@/res/local/logo_bottom.png" class="logo_bottom" />
              </div>
              <div class="bottom_logo_bg"></div>
            </div>
          </div>
</template>

<script>

  export default {
    name:'',
    props:[''],
    data () {
      return {
        input2:''
      };
    },

    components: {},

    computed: {},

    beforeMount() {},

    mounted() {
      this.maimaFun()
    },

    methods: {
        goformPageFun(val){
          var mobileReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
          if(val == 2){
            if(!this.input2){
              window.location.href= '/form.html?mobile='
            } else if(mobileReg.test(this.input2)){
              window.location.href = '/form.html?mobile='+this.input2
            } else {
              alert('手机格式错误!')
            }
          }else{
            window.location.href= '/form.html?mobile='
          }
        },
        maimaFun(){

        }
    },

    watch: {}

  }

</script>
<style lang='less' scoped>
  .group_55 {
    margin-top: 3.75rem;
  }
  .section_12 {
    padding: 1.94rem 1.69rem 1.31rem 1.75rem;
    background-color: rgb(249, 250, 252);
  }
  .text_90 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.13rem;
    font-weight: 500;
    line-height: 1.8rem;
    text-align: center;
    width: 14.38rem;
  }

  .search {
    position: relative;
    height: 49px;
    /* padding: 0.5rem 0.31rem 0.44rem 0.56rem; */
    filter: drop-shadow(0px 0.88rem 0.66rem #1c25330d);
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    border: solid 0.063rem rgb(222, 222, 222);
  }
  .search .image_7_1{
    position: absolute;
    top: 0.7rem;
    left:.3125rem
  }
    .image_7 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .bottom_logo_bg{
    height: 5rem;
    background-color: #1f2937;
    position: absolute;
    bottom: -6.8em;
    width: 100%;
  }
   .search input{
     width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 10px;
    padding-left:2rem;
   }
  .left-text-wrapper {
    flex: 1 1 7.75rem;
    color: rgb(38, 38, 38);
    font-size: 0.75rem;
    line-height: 1.06rem;
    white-space: nowrap;
    padding: 1rem 0 0.94rem;
    background-color: rgba(255, 255, 255);
    height: 3rem;
    border: solid 0.063rem rgb(237, 237, 237);
  }
 .section_13 {
    margin-top: 0.88rem;
  }
    .left-group {
    margin: 0.19rem 0 0.31rem;
    color: rgb(199, 199, 199);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .right-text-wrapper {
    position: absolute;
    right: .625rem;
    top: 0.4rem;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      102.2deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 81.34%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(255, 65, 65, 0.5);
    border-radius: 0.25rem;
    width: 5rem;
    height: 2rem;
  }
    .group_56 {
    margin-top: 1.13rem;
    align-self: center;
    text-align: center;
    width: 14.38rem;
  }
    .text_93 {
    color: rgb(137, 137, 137);
    font-size: 0.88rem;
    line-height: 1.5rem;
  }
  .text_94 {
    color: rgb(255, 106, 0);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.38rem;
  }
  .group_57 {
    padding-bottom: 14.75rem;
    position: relative;
  }
  .section_14 {
    background-color: rgb(31, 41, 55);
    height: 2.06rem;
  }
    .section_15 {
    padding: 3.25rem 1.06rem 5.63rem 1.44rem;
    background-color: rgb(31, 41, 55);
    position: absolute;
    left: 0;
    right: 0;
    top: 2.06rem;
  }
  .group_58 {
    width: 13.44rem;
  }
  .group_59 {
    text-align: left;
    // width: 11.56rem;
  }
  .text_95 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 2.19rem;
  }
  .text_96 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 2.19rem;
  }
  .text_97 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 1.25rem;
  }
  .text_98 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 1.25rem;
  }
    .image_53 {
    margin-top: 2.63rem;
    width: 6.25rem;
    height: 6.25rem;
  }
  .image_54 {
    width: 7.25rem;
    height: 2.25rem;
    position: absolute;
    left: 1.44rem;
    top: 1.44rem;
  }
  .text_99 {
    margin-top: 2.19rem;
    color: rgb(140, 140, 140);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: left;
  }
  .logo_bottom_par{
    position: absolute;
    bottom: -1.8rem;
    width: 21.875rem;
    margin: 0 auto;
    left: 1.44rem;
    border-top: 1px solid #33363B;
    padding-top:1rem;
  }
  .logo_bottom{
    width: 100%;
  }
</style>