<template>
  <div id="mobile_header">
    <div class="justify-between section_1">
      <div class="flex-row group_2">
        <!-- <img @click="listShow=true" src="@/res/local/ba6da767d0d092bb2f57a1a84a358343.png" class="image_3" /> -->
        <img
          @click="goOtherPageFun('/')"
          src="@/res/local/6e18050993709bfd1622f2127b47f419.svg"
          class="image_4"
        />
      </div>
      <span style="display: flex">
        <div
          style="margin-right: 1.25rem"
          @click="goformPageFun()"
          class="flex-col items-center text-wrapper"
        >
          <span>免费试用</span>
        </div>
        <img
          @click="listShow = true"
          src="@/res/local/ba6da767d0d092bb2f57a1a84a358343.png"
          class="image_3"
        />
      </span>
    </div>
    <div v-if="listShow" class="mobile_header_list">
      <div class="mobile_header_list_top">
        <a href="/">
          <img class="header_logo" src="../res/header/new_logo.svg" alt="" />
        </a>
        <a class="mobile_header_ap" href="/form.html">
          <span> 安排演示 </span>
        </a>
        <img
          @click="listShow = false"
          class="header_close"
          src="../res/header/new_close.png"
          alt=""
        />
      </div>
      <div class="mobile_header_list_main">
        <!-- <div @click="goOtherPageFun('/discern.html')" class="list_li">
              <div class="li_title">ToB获客工具限时免费用</div>
            </div> -->
        <div class="list_li" @click="showUlFun(1)">
          <div :class="li1 ? 'actived' : ''" class="li_title">
            产品
            <img
              v-if="!li1"
              height="14"
              src="../res/header/new_right.png"
              alt=""
            />
            <img v-else width="14" src="../res/header/new_down.png" alt="" />
          </div>
          <ul v-if="li1">
            <li style="width:50%">
              <a href="/abm-marketing-cloud.html">ABM营销云</a>
            </li>
            <li>
              <a href="/cdp.html">客户数据平台（CDP）</a>
            </li>
            <li>
              <a href="/data-cloud.html">数据云（DMP）</a>
            </li>
            <li>
              <a href="/Intent-cloud.html">情报云</a>
            </li>
            <li>
              <a href="/ad-cloud.html">广告云</a>
            </li>
          </ul>
        </div>
        <div class="list_li" @click="showUlFun(2)">
          <div :class="li2 ? 'actived' : ''" class="li_title">
            解决方案
            <img
              v-if="!li2"
              height="14"
              src="../res/header/new_right.png"
              alt=""
            />
            <img v-else width="14" src="../res/header/new_down.png" alt="" />
          </div>
          <div class="list_li_par">
            <ul v-if="li2">
              <li>
                <a style="color:#ff6a00" class="list_li_par_title" href="javascript:void(0)"
                  >整合营销解决方案</a
                >
              </li>
              <li>
                <a class="border-bottom" href="/solution-marketing-abm.html"
                  >ABM一站式增长</a
                >
              </li>
              <li>
                <a
                  class="border-bottom"
                  href="/solution-marketing-leads-nurturing.html"
                  >线索培育转化</a
                >
              </li>
              <li>
                <a
                  class="border-bottom"
                  href="/solution-marketing-synergy.html"
                  >营销协同</a
                >
              </li>
              <li>
                <a
                  class="border-bottom"
                  href="/solution-marketing-intention-intelligence-analysis.html"
                  >意图情报分析</a
                >
              </li>
            </ul>
            <ul v-if="li2">
              <li>
                <a style="color:#ff6a00" class="list_li_par_title" href="javascript:void(0)"
                  >营销场景</a
                >
              </li>
              <li>
                <a
                  class="border-bottom"
                  href="/solution-marketing-website.html"
                  >官网营销</a
                >
              </li>
              <li>
                <a
                  class="border-bottom"
                  href="/solution-marketing-advertising.html"
                  >广告营销</a
                >
              </li>
              <li>
                <a class="border-bottom" href="/solution-marketing-meeting.html"
                  >会议营销</a
                >
              </li>
              <li>
                <a class="border-bottom" href="/solution-marketing-wechat.html"
                  >私域营销</a
                >
              </li>
              <li>
                <a class="border-bottom" href="/solution-marketing-content.html"
                  >内容营销</a
                >
              </li>
              <li>
                
              </li>
            </ul>
            <ul v-if="li2">
              <li>
                <a style="color:#ff6a00" class="list_li_par_title" href="javascript:void(0)"
                  >行业解决方案</a
                >
              </li>
              <li>
                <a href="/abm.html">软件SaaS行业</a>
              </li>
              <li>
                <a href="/industry.html">工业制造行业</a>
              </li>
            </ul>
          </div>
        </div>
        <div @click="goOtherPageFun('/case.html')" class="list_li">
          <div class="li_title">客户案例</div>
        </div>
        <div @click="goOtherPageFun('/content.html')" class="list_li">
          <div class="li_title">ABM学院</div>
        </div>
        <div class="list_li" @click="showUlFun(3)">
          <div class="li_title" @click="goOtherPageFun('/about.html')">
            关于我们
          </div>
          <!-- <div :class="li3?'actived':''" class="li_title">
                <img v-if="!li3" height="14" src="../res/header/new_right.png" alt="">
                <img v-else width="14" src="../res/header/new_down.png" alt="">
              </div>
              <ul v-if="li3">
                <li>
                  <a href="/content/1.html">媒体报道</a>
                </li>
                <li>
                  <a href="/about.html">公司介绍</a>
                </li>
              </ul> -->
        </div>
      </div>
    </div>
    <!-- <div v-if="listShow" @click="listShow=false" class="header_mask"></div> -->
    <div v-if="listShow" class="header_mask"></div>
  </div>
</template>

<script>
export default {
  name: "mobile_footer",
  props: [""],
  data() {
    return {
      listShow: false,
      li1: false,
      li2: false,
      li3: false,
      input1: "",
      input2: "",
    };
  },

  components: {},

  computed: {},

  beforeMount() {},

  mounted() {},

  methods: {
    goformPageFun(val) {
      if (val == 1) {
        window.location.href = "/form.html?mobile=" + this.input1;
      } else {
        window.location.href = "/form.html?mobile=" + this.input2;
      }
    },
    goOtherPageFun(path) {
      window.location.href = path;
    },
    showUlFun(val) {
      if (val == 1) {
        this.li1 = !this.li1;
        this.li2 = false;
        this.li3 = false;
      } else if (val == 2) {
        this.li1 = false;
        this.li2 = !this.li2;
        this.li3 = false;
      } else {
        this.li1 = false;
        this.li2 = false;
        this.li3 = !this.li3;
      }
    },
  },

  watch: {},
};
</script>
<style lang="less" scoped>
#mobile_header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}
.section_1 {
  padding: 0.88rem 1rem 0.81rem 1.09rem;
  color: rgb(255, 106, 0);
  font-size: 0.81rem;
  font-weight: 500;
  line-height: 1.13rem;
  white-space: nowrap;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0.13rem 0.25rem 0px rgba(0, 0, 0, 0.03);
}
.group_2 {
  align-self: center;
}
.image_3 {
  align-self: center;
  width: 0.81rem;
  height: 0.69rem;
}
.image_4 {
  // margin-left: 1.59rem;
  width: 4.94rem;
  height: 1.5rem;
}
.text-wrapper {
  padding: 0.44rem 0;
  background-color: rgba(255, 106, 0, 0.05);
  border-radius: 1rem;
  width: 5rem;
  height: 2rem;
}
.mobile_header_list {
  font-family: PingFangSC-Thin, sans-serif, "helvetica neue", tahoma,
    "hiragino sans gb", stheiti, "wenquanyi micro hei", \5FAE\8F6F\96C5\9ED1,
    \5B8B\4F53, sans-serif;
  position: fixed;
  top: 0;
  z-index: 111;
  width: 100%;
}
.mobile_header_list_top {
  background: #474747;
  width: 100%;
  height: 2.8125rem;
  // position: fixed;
  // top: 0;
  display: flex;
  padding: 0 0.625rem;
  align-items: center;
}
ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.header_logo {
  width: 13.875rem;
}
.mobile_header_ap span {
  display: block;
  width: 4.375rem;
  background: #ff6c01;
  border-radius: 0.1rem;
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 2px 0;
  margin-left: 1.25rem;
}
.header_close {
  width: 0.8125rem;
  margin-left: 1.25rem;
}
.mobile_header_list_main {
  width: 100%;
  background: #fff;
  position: absolute;
  top: 2.8125rem;
  .list_li {
    border-bottom: 0.01rem solid #bfbfbf;
    .li_title:active {
      color: #ff6c01;
    }
    .li_title {
      height: 47px;
      display: flex;
      padding-left: 1.5625rem;
      align-items: center;
      font-weight: bold;
      justify-content: space-between;
      padding-right: 1.5625rem;
      &.actived {
        color: #ff6c01;
      }
    }
    .list_li_par {
      // display: flex;
      .list_li_par_title {
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #232323;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      background: #f8f8f8;
      display: flex;
      flex-wrap: wrap;
      li:active {
        a {
          color: #ff6c01;
        }
      }
      li {
        padding-left: 1.5625rem;
        height: 2.9375rem;
        display: flex;
        align-items: center;
        font-size: 0.9375rem;
        width: 50%;
        border-bottom: 1px solid #E5E6EB;
        a {
          color: #333;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
      li:first-child{
        width: 100%;
      }
    }
  }
}
.header_mask {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
}
</style>
