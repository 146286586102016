<template>
  <div class="flex-col container">
    <div class="flex-col group_1">
      <div class="flex-col group_2">
        <v-header></v-header>
        <div class="flex-col section_2">
          <div class="flex-col items-center group_4">
            <span class="text_2">数据云（DMP）</span>
            <span class="text_3">专为B2B企业而生的数据平台</span>
          </div>
          <div class="flex-col group_5">
            <img src="@/res/remote/74bbad75fe2a7b9f1d900c078f10025d.png" class="image_5" />
            <span class="text_4"
              >火眼数据云覆盖6000万企业主体及1.6亿职场人群画像，并掌握B2B企业情报及意向数据，帮助您识别、理解和吸引理想的目标客户</span
            >
          </div>
          <div class="bottom-section justify-between view">
            <!-- <div class="left-group flex-row">
              <img src="@/res/local/16376631404543724564.png" class="image_6" />
              <span class="text_5">请输入您的手机号码</span>
            </div> -->
            <img src="@/res/local/16376631404543724564.png" class="image_6 image_7_1" />
            <input v-model="input1" class="left-group flex-row" type="text" placeholder="请输入您的手机号码">
            <div @click="goformPageFun(1)" class="right-text-wrapper flex-col items-center">
              <span>预约演示</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col group_6">
        <span class="text_8">功能亮点</span>
        <div class="justify-center group_7">
          <span>激活客户数据价值</span>
          <span class="text_10">实现营销快速增长</span>
        </div>
        <div class="flex-col group_8">
          <div class="section_3 flex-row view_3">
            <img src="@/res/local/1eb668523730f84abd8f4f08c0e90893.png" class="image_8" />
            <div class="flex-col group_9">
              <span class="text_11">专属B2B企业数据库</span>
              <span class="text_13">挖掘全量目标市场，确定最佳客户，快速开展营销策略</span>
            </div>
          </div>
          <div class="section_3 flex-row">
            <img src="@/res/local/b8200d0307c2e99dc38e2e0da7a45012.png" class="image_8" />
            <div class="flex-col group_10">
              <span class="text_11">找到高契合度客户</span>
              <span class="text_13">基于客户的行业特征定制化建模，按需提供高匹配目标客户</span>
            </div>
          </div>
          <div class="section_3 flex-row">
            <img src="@/res/local/125e1406db3408a841b055a8a74a3507.png" class="image_8" />
            <div class="flex-col group_11">
              <span class="text_11">实时的B2B销售情报</span>
              <span class="text_13 text_18">为您提供客户资料、行为和意图数据，以有限的资源优先转化高意向线索</span>
            </div>
          </div>
          <div class="section_3 flex-row">
            <img src="@/res/local/502e3adfa33de5c4e6824b6ea6d24a88.png" class="image_8" />
            <div class="flex-col group_12">
              <span class="text_11">有效执行ABM营销</span>
              <span class="text_13">在您最关心的目标客户中，识别、互动和转化业务关键人</span>
            </div>
          </div>
        </div>
        <span class="text_21">有深度的B2B企业信息</span>
        <span class="text_22">6000万+企业主体、300+公司属性，创建量身定制的高价值公司列表</span>
        <img src="@/res/remote/sjy_picture_qiyexinxi.png" class="image_13" />
        <div class="flex-col group_13">
          <div class="flex-row group_14">
            <div class="left-section"></div>
            <span class="text_23">查找符合您理想客户特征（ICP）的目标企业</span>
          </div>
          <div class="flex-row group_15">
            <div class="left-section"></div>
            <span class="text_24">多维度筛选生成目标客户列表（TAL），挖掘全量目标市场</span>
          </div>
          <div class="flex-row group_16">
            <div class="left-section"></div>
            <span class="text_25">高精准的目标客群</span>
          </div>
        </div>
        <div @click="goformPageFun()" class="button flex-col items-center text-wrapper_1">
          <span>免费试用</span>
        </div>
        <div class="flex-col group_17">
          <span class="text_27">全面的职场人群画像</span>
          <span class="text_28">覆盖1.6亿职场人群画像，多维度职场人群画像标签洞察采购流程，发现最佳机会</span>
        </div>
        <img src="@/res/remote/7f1f8566c5be40968dfc41b1b378b105.png" class="image_14" />
        <div class="group_18 flex-col view_10">
          <div class="flex-row group_19">
            <div class="left-section"></div>
            <span class="text_29">根据画像分析企业职场群体角色方向</span>
          </div>
          <div class="center-group flex-row">
            <div class="left-section"></div>
            <span class="text_30">以行为意图数据丰富客户职场画像，有效认定沟通场景</span>
          </div>
          <div class="bottom-group flex-row">
            <div class="left-section"></div>
            <span class="text_31">实时更新，让您一直使用最新的数据</span>
          </div>
        </div>
        <div @click="goformPageFun()" class="button flex-col items-center">
          <span>免费试用</span>
        </div>
        <div class="group_20 flex-col view_16">
          <span class="text_33">定制高意向客户列表</span>
          <div class="bottom-group_1 flex-col view_17">
            <span class="text_35">按业务需求推荐企业标签画像，为您找到高契合度企业，实现精准营销</span>
            <img src="@/res/remote/58531bcf34ebc0daa2785100cf706fb6.png" class="image_15" />
          </div>
        </div>
        <div class="group_18 flex-col view_18">
          <div class="flex-row group_21">
            <div class="left-section"></div>
            <span class="text_36">定制业务标签，深度挖掘高契合目标客户</span>
          </div>
          <div class="center-group flex-row">
            <div class="left-section"></div>
            <span class="text_37">基于您的业务生成高匹配度的行业定制标签</span>
          </div>
          <div class="bottom-group flex-row">
            <div class="left-section"></div>
            <span class="text_38">根据您的目标客户画像推荐更多相似企业</span>
          </div>
        </div>
        <div @click="goformPageFun()" class="button flex-col items-center">
          <span>免费试用</span>
        </div>
        <div class="group_20 flex-col view_25">
          <span class="text_33">定制高意向人群列表</span>
          <span class="bottom-group_1 text_41">通过职场人群行为，多维度洞察职场行为特征，为您定位精准高意向人群</span>
        </div>
        <img src="@/res/local/0670c3dce9aef36a458a22f50198e196.png" class="image_16" />
        <div class="flex-col group_22">
          <div class="flex-row group_23">
            <div class="left-section"></div>
            <span class="text_42">通过行为、行业、职务等多种方式挖掘目标人群</span>
          </div>
          <div class="flex-row group_24">
            <div class="left-section"></div>
            <span class="text_43">洞察职场人群特征，帮助您理解目标人群的行为意图</span>
          </div>
          <div class="flex-row group_25">
            <div class="left-section"></div>
            <span class="text_44">数据实时更新，构建动态的高意向职场人群列表</span>
          </div>
        </div>
        <div @click="goOtherPageFun('/Intent-cloud.html')" class="button flex-col items-center">
          <span>了解更多</span>
        </div>
        <div class="flex-col group_26">
          <span class="text_46">赋能企业一方数据中台（CDP）</span>
          <div class="flex-col group_27">
            <span class="text_47"
              >基于火眼云数据能力，补充、合并、规范企业一方数据，并以OneID能力实现跨渠道的个性化营销体验</span
            >
            <img src="@/res/remote/a22d0a2cfeb04439ad01d7f55498b86e.png" class="image_17" />
          </div>
          <div class="group_18 flex-col view_29">
            <div class="flex-row group_28">
              <div class="left-section"></div>
              <span class="text_48">为每位客户创建一致且持续更新的客户资料</span>
            </div>
            <div class="center-group flex-row">
              <div class="left-section"></div>
              <span class="text_49">对客户群进行细分，展开更触达人心的互动</span>
            </div>
            <div class="bottom-group flex-row">
              <div class="left-section"></div>
              <span class="text_50">描绘客户行为历程，掌握客户生命周期阶段</span>
            </div>
          </div>
        </div>
        <div @click="goOtherPageFun('/cdp.html')" class="button flex-col items-center">
          <span>了解更多</span>
        </div>
        <span class="text_52">应用场景</span>
      </div>
      <div class="flex-col group_29">
        <div class="flex-col section_4">
          <img src="@/res/local/eb7259e8b7f4f4b352750d960c19a68f.png" class="image_18 image_19" />
          <div class="flex-col group_30">
            <span class="text_53">规模化获客</span>
            <span class="text_54">三方数据能力帮助B2B市场部高效获取线索，找到全量目标市场</span>
          </div>
        </div>
        <div class="flex-col section_5">
          <img src="@/res/local/e83672f2ecaa0b109a6e096fea11305d.png" class="image_18 image_20" />
          <div class="flex-col items-center group_31">
            <span class="text_55">ABM营销</span>
            <span class="text_56">找到最具价值目标企业和业务决策人，是执行ABM的基础</span>
          </div>
        </div>
        <div class="flex-col section_6">
          <img src="@/res/local/c36164f0b62b9799b3d180422b302362.png" class="image_18 image_21" />
          <div class="flex-col items-center group_32">
            <span class="text_57">客户画像洞察</span>
            <span class="text_58">清晰精准地了解客户的行和特征，全方位提升对客户的认知</span>
          </div>
        </div>
        <div class="flex-col section_7">
          <img src="@/res/local/b06dff3ab3520b4b3d69cce5d790400f.png" class="image_18 image_22" />
          <div class="flex-col group_33">
            <span class="text_59">行为意图分析</span>
            <span class="text_60">通过识别行为数据，实时定位对您产品或服务感兴趣的潜在客户</span>
          </div>
        </div>
        <div class="flex-col section_8">
          <img src="@/res/local/54e7e49d9bf94749553454cacb5fb054.png" class="image_18 image_23" />
          <div class="flex-col group_34">
            <span class="text_61">线索评分模型</span>
            <span class="text_62">全链路营销数据整合，对线索进行评分评级，快速甄别优质线索</span>
          </div>
        </div>
        <div class="flex-col section_9">
          <img src="@/res/local/2d8dd2268becf47542a854a702792988.png" class="image_18 image_24" />
          <div class="flex-col group_35">
            <span class="text_63">一方数据补全</span>
            <span class="text_64">对企业自有客户数据进行数据更新和字段信息补全，并常态化更新</span>
          </div>
        </div>
      </div>
      <div class="flex-col group_36">
        <div class="flex-col group_37">
          <span class="text_65">火眼云一站式营销增长产品矩阵</span>
          <span class="text_66">找到目标客户 、发现关键意图 、创新营销体验，并开展爆炸式营销增长</span>
          <div class="flex-row group_38">
            <div class="flex-col items-center group_39">
              <div class="section_10">
                <span class="liuguang"></span>
              </div>
              <img src="@/res/local/a8e129945b8c9d085b5d3356e13e4bef.png" class="image_25" />
              <img src="@/res/local/dbc56334b545bcdc59619a25af96c5ad.png" class="image_26" />
              <img src="@/res/local/548757465bc8230d91f7a254af8da972.png" class="image_27" />
              <img src="@/res/local/8c13bce909108634edc08da1d14465bd.png" class="image_28" />
            </div>
            <div class="flex-col group_40">
              <div @click="goOtherPageFun('/abm-marketing-cloud.html')" class="flex-col group_41">
                <span class="text_67">ABM营销云</span>
                <span class="text_68">让大规模、个性化执行目标客户营销（ABM）策略成为可能</span>
              </div>
              <div @click="goOtherPageFun('/Intent-cloud.html')" class="flex-col group_42">
                <span class="text_69">情报云</span>
                <span class="text_70">通过企业情报和行为意图，实时定位对您产品或服务感兴趣的潜在客户</span>
              </div>
              <div @click="goOtherPageFun('/cdp.html')" class="flex-col group_43">
                <span class="text_71">客户数据平台（CDP）</span>
                <span class="text_72">以OneID技术打通、补充、规范企业多元数据，统一客户视图，实现营销数字化转型</span>
              </div>
              <div @click="goOtherPageFun('/ad-cloud.html')" class="flex-col group_44">
                <span class="text_73">广告云</span>
                <span class="text_74">进行数字广告的全渠道投放、监测、归因分析以及投放策略优化</span>
              </div>
            </div>
          </div>
        </div>
        <div @click="goOtherPageFun('/data-cloud.html')" class="flex-row section_11">
          <img src="@/res/local/7afc9facdfff43874d0ffaf4318ef4c3.png" class="image_18 image_29" />
          <div class="flex-col group_45">
            <span class="text_75">数据云（DMP）</span>
            <span class="text_76"
              >覆盖6000万企业主体及1.6亿职场人群画像，掌握B2B企业情报及意向数据，帮助您识别、理解和吸引理想的目标客户</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="flex-col group_46">
        <div class="flex-col section_12">
          <span class="text_77">留下您的联系方式<br />专属顾问会尽快与您联系</span>
          <div class="bottom-section justify-between section_13">
            <div class="left-group flex-row">
              <img src="@/res/local/16376631404543724564.png" class="image_6" />
              <span class="text_5">请输入您的手机号码</span>
            </div>
            <div class="right-text-wrapper flex-col items-center">
              <span>免费试用</span>
            </div>
          </div>
          <div class="group_47">
            <span class="text_80">如有疑问，欢迎致电客服热线</span>
            <span class="text_81">400-8521-789</span>
          </div>
        </div>
        <div class="flex-col group_48">
          <div class="section_14"></div>
          <div class="justify-between section_15">
            <div class="flex-col group_49">
              <div class="group_50">
                <span class="text_82">电话：</span>
                <span class="text_83">400-8521-789</span>
                <span class="text_84">邮箱： </span>
                <span class="text_85">marketing@huoyan.cn</span>
              </div>
              <span class="text_86">©2017 北京绘客科技有限公司 <br />京ICP备16023115号-1 隐私政策</span>
            </div>
            <img src="@/res/remote/06c5f76966fbb88f09c7d54f941ea701.png" class="image_31" />
          </div>
          <img src="@/res/local/52ca3200bc469c20b13881eecea2030c.svg" class="image_32" />
        </div>
      </div> -->
      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
import vFooter from '@/components/footer.vue'
  export default {
    data() {
      return {
        input1:''
      };
    },
        components: {
      vFooter,vHeader
    },
    methods: {
      goformPageFun(val){
        var mobileReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if(val == 1){
          if(!this.input1){
            window.location.href= '/form.html?mobile='
          } else if(mobileReg.test(this.input1)){
             window.location.href = '/form.html?mobile='+this.input1
          } else {
            alert('手机格式错误!')
          }
        }else{
          window.location.href= '/form.html?mobile='
        }
      },
      goOtherPageFun(path){
        window.location.href = path
      }
    }
  };
</script>

<style scoped lang="css">
  .button {
    margin-top: 1.69rem;
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      99.8deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 82.66%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.56rem 0px rgba(255, 65, 65, 0.1);
    border-radius: 0.13rem;
    width: 6.25rem;
  }
  .group_18 {
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0 2.44rem;
  }
  .group_20 {
    padding-left: 0.88rem;
    padding-right: 0.56rem;
  }
  .bottom-section {
        position: relative;
    height: 49px;
    /* padding: 0.5rem 0.31rem 0.44rem 0.56rem; */
    filter: drop-shadow(0px 0.88rem 0.66rem #1c25330d);
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    border: solid 0.063rem rgb(222, 222, 222);
  }
  .bottom-section input{
     width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 10px;
    padding-left:2rem;
   }
  .image_7_1{
    position: absolute;
    top: 0.7rem;
    left:.3125rem
  }
  .section_3 {
    margin-top: 1rem;
    padding: 1.5rem 1.5rem 1.75rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 1.13rem 0px rgb(243, 244, 246);
    border-radius: 0.25rem;
  }
  .image_18 {
    width: 5rem;
    height: 5rem;
  }
  .right-text-wrapper {
    position: absolute;
    right:.625rem;
    top: 0.4rem;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      102.2deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 81.34%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(255, 65, 65, 0.5);
    border-radius: 0.25rem;
    width: 5rem;
    height: 2rem;
  }
  .image_8 {
    width: 3rem;
    height: 3rem;
  }
  .left-section {
    align-self: center;
    background-color: rgb(212, 212, 212);
    border-radius: 50%;
    width: 0.38rem;
    height: 0.38rem;
  }
  .image_6 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .text_33 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_11 {
    color: rgb(51, 51, 51);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .center-group {
    margin-top: 1.38rem;
  }
  .bottom-group {
    margin-top: 1.38rem;
  }
  .bottom-group_1 {
    margin-top: 1.25rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
  }
  .left-group {
    margin: 0.19rem 0 0.31rem;
    color: rgb(199, 199, 199);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_5 {
    margin-left: 0.81rem;
    margin-top: 0.19rem;
  }
  .text_13 {
    margin-top: 0.81rem;
    color: rgb(35, 35, 35);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .container {
    padding-top:3.5rem;
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
  }
  .header {
    padding: 0.84rem 0.88rem 0.72rem 2.19rem;
    color: rgb(0, 0, 0);
    font-size: 0.94rem;
    line-height: 1.13rem;
    letter-spacing: -0.019rem;
    white-space: nowrap;
    height: 2.69rem;
  }
  .group_1 {
    flex: 1 1 auto;
    overflow-y: auto;
  }
  .group {
    margin: 0.19rem 0 0.22rem;
  }
  .group_6 {
    margin-top: 2.5rem;
    padding: 0 0.94rem;
  }
  .group_29 {
    margin-top: 1.88rem;
  }
  .group_36 {
    margin-top: 3.94rem;
    height: 52.25rem;
  }
  .group_46 {
    margin-top: 3.13rem;
  }
  .image {
    width: 1.06rem;
    height: 0.69rem;
  }
  .image_1 {
    margin-left: 0.28rem;
    width: 1rem;
    height: 0.69rem;
  }
  .image_2 {
    margin-left: 0.28rem;
    width: 1.53rem;
    height: 0.72rem;
  }
  .section_1 {
    padding: 0.88rem 1rem 0.81rem;
    color: rgb(255, 106, 0);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(0, 0, 0, 0.03);
  }
  .section_2 {
    margin-top: 0.19rem;
    padding: 2.06rem 1.19rem 1.63rem;
    background-image: linear-gradient(
      180deg,
      rgb(250, 250, 251) 0%,
      rgb(250, 250, 251) 0%,
      rgb(243, 244, 246) 100%,
      rgb(243, 244, 246) 100%
    );
    overflow: hidden;
    height: 29.38rem;
  }
  .text_8 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_7 {
    margin-top: 1rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .group_8 {
    margin-top: 2rem;
    padding-left: 0.31rem;
    padding-right: 0.063rem;
  }
  .text_21 {
    margin-top: 3.69rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_22 {
    margin: 1.25rem 0.31rem 0 0.75rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: center;
  }
  .image_13 {
    margin-top: 1.75rem;
    align-self: center;
    width: 20.25rem;
    height: 12.5rem;
  }
  .group_13 {
    margin-top: 1.69rem;
    padding-left: 2.44rem;
    padding-right: 2.19rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .text-wrapper_1 {
    margin-top: 1.94rem;
  }
  .group_17 {
    margin-top: 3rem;
    padding-left: 0.81rem;
    padding-right: 0.38rem;
  }
  .image_14 {
    margin-top: 1.75rem;
    align-self: center;
    width: 20.06rem;
    height: 12.5rem;
  }
  .view_10 {
    margin-top: 1.69rem;
  }
  .view_16 {
    margin-top: 4.25rem;
  }
  .view_18 {
    margin-top: 1.66rem;
  }
  .view_25 {
    margin-top: 3.25rem;
  }
  .image_16 {
    margin-top: 1.75rem;
    align-self: center;
    width: 20.31rem;
    height: 12.5rem;
  }
  .group_22 {
    margin-top: 1.69rem;
    padding: 0 1.63rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .group_26 {
    margin-top: 3.25rem;
  }
  .text_52 {
    margin-top: 3.25rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .section_4 {
    padding: 1.13rem 0.75rem 1.56rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
    border-top: solid 0.26rem rgb(245, 245, 245);
  }
  .section_5 {
    padding: 1.13rem 1.06rem 3.13rem 1.38rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_6 {
    padding: 1.13rem 0.75rem 3.13rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.25rem;
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_7 {
    padding: 1.13rem 0.75rem 1.53rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_8 {
    padding: 1.06rem 0.75rem 1.44rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_9 {
    padding: 1.06rem 0.75rem 1.5rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
    border-bottom: solid 0.26rem rgb(245, 245, 245);
  }
  .group_37 {
    padding-left: 1.31rem;
    padding-right: 1.25rem;
  }
  .section_11 {
    margin-top: -0.44rem;
    padding: 2.19rem 1.25rem 2rem 1.31rem;
    background-image: linear-gradient(
      90deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.29%,
      rgb(255, 181, 129) 148.28%,
      rgb(255, 181, 129) 100%
    );
    position: relative;
  }
  .section_12 {
    padding: 1.94rem 1.69rem 1.31rem 1.75rem;
    background-color: rgb(249, 250, 252);
  }
  .group_48 {
    padding-bottom: 13.94rem;
    position: relative;
  }
  .group_3 {
    align-self: center;
  }
  .text-wrapper {
    padding: 0.44rem 0;
    background-color: rgba(255, 106, 0, 0.05);
    border-radius: 1rem;
    width: 5rem;
    height: 2rem;
  }
  .group_5 {
    margin-top: 1.72rem;
    color: rgb(61, 61, 61);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
  .view {
    margin: 2.25rem 0.5rem 0 0.56rem;
  }
  .text_10 {
    margin-left: 0.59rem;
  }
  .view_3 {
    margin-top: 0;
  }
  .group_15 {
    margin-top: 1.38rem;
  }
  .group_16 {
    margin-top: 1.38rem;
  }
  .text_27 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_28 {
    margin-top: 1.25rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: center;
  }
  .text_41 {
    text-align: center;
  }
  .group_24 {
    margin-top: 1.38rem;
  }
  .group_25 {
    margin-top: 1.38rem;
  }
  .text_46 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_27 {
    margin-top: 1.25rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
  }
  .view_29 {
    margin-top: 1.31rem;
  }
  .image_19 {
    align-self: center;
  }
  .group_30 {
    margin-top: 1rem;
  }
  .image_20 {
    align-self: center;
  }
  .group_31 {
    margin-top: 1rem;
  }
  .image_21 {
    align-self: center;
  }
  .group_32 {
    margin-top: 1rem;
  }
  .image_22 {
    align-self: center;
  }
  .group_33 {
    margin-top: 1.03rem;
  }
  .image_23 {
    align-self: center;
  }
  .group_34 {
    margin-top: 1.19rem;
  }
  .image_24 {
    align-self: center;
  }
  .group_35 {
    margin-top: 1.25rem;
  }
  .text_65 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_66 {
    margin: 1.25rem 0.44rem 0 0.56rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: center;
  }
  .group_38 {
    margin-top: 3.06rem;
  }
  .image_29 {
    align-self: center;
  }
  .group_45 {
    margin-left: 1.25rem;
    flex: 1 1 auto;
  }
  .text_77 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.13rem;
    font-weight: 500;
    line-height: 1.13rem;
    text-align: center;
    width: 12.38rem;
  }
  .section_13 {
    margin-top: 0.88rem;
  }
  .group_47 {
    margin-top: 1.13rem;
    align-self: center;
    text-align: center;
    width: 11.38rem;
  }
  .section_14 {
    background-color: rgb(31, 41, 55);
    height: 2.06rem;
  }
  .section_15 {
    padding: 3.25rem 1.06rem 1.81rem 1.44rem;
    background-color: rgb(31, 41, 55);
    position: absolute;
    left: 0;
    right: 0;
    top: 2.06rem;
  }
  .image_32 {
    width: 7.25rem;
    height: 2.25rem;
    position: absolute;
    left: 1.44rem;
    top: 1.44rem;
  }
  .image_3 {
    align-self: center;
    width: 1rem;
    height: 1rem;
  }
  .image_4 {
    margin-left: 1.5rem;
    width: 4.94rem;
    height: 1.5rem;
  }
  .text_2 {
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_3 {
    margin-top: 0.38rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .image_5 {
    align-self: center;
    width: 17.06rem;
    height: 9.75rem;
  }
  .text_4 {
    margin-right: 0.19rem;
    margin-top: 1.34rem;
    text-align: left;
  }
  .group_9 {
    margin: 0.38rem 0.19rem 0 1rem;
    flex: 1 1 auto;
  }
  .group_10 {
    margin: 0.38rem 0.19rem 0 1rem;
    flex: 1 1 auto;
  }
  .group_11 {
    margin-left: 1rem;
    margin-top: 0.38rem;
    flex: 1 1 auto;
  }
  .group_12 {
    margin: 0.38rem 0.19rem 0 1rem;
    flex: 1 1 auto;
  }
  .text_23 {
    margin-left: 0.63rem;
  }
  .text_24 {
    margin-left: 0.63rem;
    margin-right: 2.19rem;
  }
  .text_25 {
    margin-left: 0.63rem;
    margin-right: 1.31rem;
  }
  .text_29 {
    margin-left: 0.63rem;
    margin-right: 1.06rem;
  }
  .text_30 {
    margin-left: 0.63rem;
    margin-right: 1.06rem;
  }
  .text_31 {
    margin-left: 0.63rem;
  }
  .text_35 {
    text-align: center;
  }
  .image_15 {
    margin-top: 1.72rem;
    width: 20rem;
    height: 12.56rem;
  }
  .text_36 {
    margin-left: 0.63rem;
  }
  .text_37 {
    margin-left: 0.63rem;
  }
  .text_38 {
    margin-left: 0.63rem;
  }
  .text_42 {
    margin-left: 0.63rem;
    margin-right: 0.25rem;
  }
  .text_43 {
    margin-left: 0.63rem;
    margin-right: 1.06rem;
  }
  .text_44 {
    margin-left: 0.63rem;
    margin-right: 0.25rem;
  }
  .text_47 {
    margin-left: 0.88rem;
    margin-right: 0.56rem;
    text-align: center;
  }
  .image_17 {
    margin-right: 0.25rem;
    margin-top: 1.25rem;
    width: 91vw;
    height: 56.5vw;
  }
  .text_53 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_54 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: center;
  }
  .text_55 {
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_56 {
    margin-top: 1rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .text_57 {
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_58 {
    margin-top: 1rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .text_59 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_60 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: center;
  }
  .text_61 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_62 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: center;
  }
  .text_63 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_64 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: center;
  }
  .group_39 {
    padding-top: 4.56rem;
    width: 5rem;
    position: relative;
  }
  .group_40 {
    margin-left: 1.25rem;
    margin-top: 0.31rem;
    flex: 1 1 auto;
  }
  .text_75 {
    color: rgb(255, 255, 255);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_76 {
    margin-top: 0.38rem;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_80 {
    color: rgb(137, 137, 137);
    font-size: 0.88rem;
    line-height: 1.5rem;
  }
  .text_81 {
    color: rgb(255, 106, 0);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.38rem;
  }
  .group_49 {
    width: 12.44rem;
  }
  .image_31 {
    margin-top: 2.63rem;
    width: 6.25rem;
    height: 6.25rem;
  }
  .text_18 {
    width: 13.13rem;
  }
  .text_48 {
    margin-left: 0.63rem;
    margin-right: 0.25rem;
  }
  .text_49 {
    margin-left: 0.63rem;
    margin-right: 0.25rem;
  }
  .text_50 {
    margin-left: 0.63rem;
    margin-right: 0.25rem;
  }
  .section_10 {
    background-image: linear-gradient(
      180.1deg,
      rgb(255, 211, 134) 0%,
      rgb(255, 211, 134) -38.14%,
      rgb(255, 239, 187) 118.97%,
      rgb(255, 239, 187) 100%
    );
    width: 0.38rem;
    height: 29.19rem;
        position: relative;
    overflow: hidden;
  }
    .liuguang{
    position: absolute;
    bottom:0rem;
    left:0;
    width: 0.38rem;
    height: 20rem;
    background:#f5bf62;
    animation: animate1 1s linear infinite;
  }
  @keyframes animate1 {
      0% {
          bottom: -100%;
      }
      50%,
      100% {
          bottom: 100%;
      }
  }
  .image_25 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .image_26 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 8.13rem;
  }
  .image_27 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 16.25rem;
  }
  .image_28 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 24.38rem;
  }
  .group_42 {
    margin-top: 3.81rem;
  }
  .group_43 {
    margin-top: 3.81rem;
  }
  .group_44 {
    margin-top: 3.81rem;
  }
  .group_50 {
    text-align: left;
    width: 11.56rem;
  }
  .text_86 {
    margin-top: 2.19rem;
    color: rgb(140, 140, 140);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: left;
  }
  .text_67 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_68 {
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    width: 13rem;
  }
  .text_69 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_70 {
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_71 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_72 {
    margin-right: 0.81rem;
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_73 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_74 {
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_82 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 2.19rem;
  }
  .text_83 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 2.19rem;
  }
  .text_84 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 1.25rem;
  }
  .text_85 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 1.25rem;
  }
</style>