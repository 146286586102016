import Vue from 'vue';
import App from './app.vue';
import router from './router';
import 'swiper/dist/css/swiper.css';
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
