<template>
  <div class="flex-col container">
    <v-header></v-header>
    <div class="flex-col group_3">
      <div class="flex-col group_4">
        <div class="flex-col group_5">
          <div class="flex-col group_6">
            <div class="flex-col group_7">
              <span class="text_2">ABM一站式增长</span>
              <span class="text_3"
                >大数据智能推荐引擎+营销自动化平台+专业服务<br />一站式助力企业搭建可持续增长的数字营销体系</span
              >
            </div>
            <img
              src="@/res/local/dbc9dc4ea28332bddbf8742fba523be6.png"
              class="image_5"
            />
            <div @click="goformPageFun()" class="flex-col items-center button">
              <span>定制您的专属方案</span>
            </div>
            <span class="text_5">方案价值</span>
            <div class="flex-col list">
              <div class="list-item flex-row">
                <img
                  src="@/res/local/jjfa_increase_Improve_icon.png"
                  class="image_6"
                />
                <div class="right-group flex-col">
                  <span class="text_6">提升获客精准度</span>
                  <span class="text_8"
                    >传统的营销方式无法向目标企业“精准出击”，市场部需要耗费大量精力进行线索筛选。<br
                  /></span>
                </div>
              </div>
              <div class="list-item flex-row">
                <img
                  src="@/res/local/jjfa_increase_expansion_icon.png"
                  class="image_6"
                />
                <div class="right-group flex-col">
                  <span class="text_6">扩充有效触达渠道</span>
                  <span class="text_8"
                    >市场部对逐渐多样化的营销渠道“选择困难”，根本原因是缺乏能够迅速与目标用户产生互动的有效触达手段。<br
                  /></span>
                </div>
              </div>
              <div class="list-item flex-row">
                <img
                  src="@/res/local/jjfa_increase_enlarge_icon.png"
                  class="image_6"
                />
                <div class="right-group flex-col">
                  <span class="text_6">放大市场部门价值</span>
                  <span class="text_8"
                    >市场与销售往往“相爱相杀”，市场部无法实时得知线索跟进程度，出现信息不对称、商机难溯源等窘境。<br
                  /></span>
                </div>
              </div>
            </div>
            <div class="flex-col items-center group_8">
              <span class="text_14">火眼云ABM一站式增长解决方案</span>
              <span class="text_15">建立符合客户画像的目标企业列表</span>
            </div>
          </div>
          <div class="flex-col list_1">
            <div class="list-item_1 flex-col">
              <div class="group_10 flex-col">
                <img
                  src="@/res/local/1516ea58858ac1c0836b9848a1613773.png"
                  class="image_10"
                />
                <div class="flex-row group_11">
                  <div class="section_5"></div>
                  <span class="text_16">火眼数据云</span>
                </div>
                <span class="text_17"
                  >6000万+企业储备，多维度筛选符合理想客户画像的企业</span
                >
              </div>
              <div class="group_12 flex-col">
                <div class="flex-row group_13">
                  <div class="section_6"></div>
                  <span class="text_18">定制企业标签</span>
                </div>
                <span class="text_19"
                  >丰富对目标企业业务特征的洞察，定制筛选字段，推测哪些目标企业具备强烈需求</span
                >
              </div>
              <div class="group_14 flex-col">
                <div class="top-group flex-row">
                  <div class="left-section"></div>
                  <span class="text_20">匿名访客识别</span>
                </div>
                <span class="text_22"
                  >根据网站/落地页来访流量分析可能所属的企业名称</span
                >
              </div>
              <div
                @click="goformPageFun()"
                class="button_1 flex-col items-center"
              >
                <span>立即体验</span>
              </div>
            </div>
            <div class="list-item_1 flex-col">
              <div class="flex-col items-center" style="margin-bottom: 1.75rem">
                <span class="text_15">分析与目标企业最佳沟通方式</span>
              </div>
              <div class="group_10 flex-col">
                <img
                  style="height: auto"
                  src="@/res/remote/94d65b2d0ba01b8e55a6c580fe502015.png"
                  class="image_10"
                />
                <div class="flex-row group_11">
                  <div class="section_5"></div>
                  <span class="text_16">全量目标企业列表生成</span>
                </div>
                <span class="text_17"
                  >1.6亿职场人群画像，可快速筛选目标客户，为精准营销做准备</span
                >
              </div>
              <div class="group_12 flex-col">
                <div class="flex-row group_13">
                  <div class="section_6"></div>
                  <span class="text_18">职场人群定向</span>
                </div>
                <span class="text_19"
                  >通过目标人群画像和特定行为建模，交叉分析并精准锁定高意向人群</span
                >
              </div>
              <div class="group_14 flex-col">
                <div class="top-group flex-row">
                  <div class="left-section"></div>
                  <span class="text_20">IP/location人群拓展</span>
                </div>
                <span class="text_22"
                  >利用已知种子人群进行企业内部同网拓展和广告覆盖</span
                >
              </div>
              <div
                @click="goformPageFun()"
                class="button_1 flex-col items-center"
              >
                <span>立即体验</span>
              </div>
            </div>
            <div class="list-item_1 flex-col">
              <div class="flex-col items-center" style="margin-bottom: 1.75rem">
                <span class="text_15">精确评估营销活动ROI</span>
              </div>
              <div class="group_10 flex-col">
                <img src="@/res/local/yzszz_jjfa3.png" class="image_10" />
                <div class="flex-row group_11">
                  <div class="section_5"></div>
                  <span class="text_16">营销渠道整合</span>
                </div>
                <span class="text_17"
                  >整合广告、微信、邮件、短信、电话等多营销渠道，汇聚统一平台</span
                >
              </div>
              <div class="group_12 flex-col">
                <div class="flex-row group_13">
                  <div class="section_6"></div>
                  <span class="text_18">内容管理和营销自动化</span>
                </div>
                <span class="text_19"
                  >模板化+拖拽式内容排版，可对选定的目标客户组进行多渠道多轮次、个性化的内容触达</span
                >
              </div>
              <div class="group_14 flex-col">
                <div class="top-group flex-row">
                  <div class="left-section"></div>
                  <span class="text_20">完整的客户旅程呈现</span>
                </div>
                <span class="text_22 text_221"
                  >整合同一客户在多个渠道的互动行为，支持匿名状态行为收集，一旦客户实名后，之前的行为自动合并，呈现完整的客户旅程
                </span>
              </div>
              <div
                @click="goformPageFun()"
                class="button_1 flex-col items-center"
              >
                <span>立即体验</span>
              </div>
            </div>
          </div>
          <div class="flex-col section_8">
            <span class="text_45">链接销售工具，增进营销协同</span>
            <img
              src="@/res/local/2909bdf92289b3ba91ea133f10c02f33.png"
              class="image_13"
            />
            <div class="flex-col group_19">
              <div class="flex-row group_20">
                <div class="left-section_3"></div>
                <span class="text_46">客户管理系统（CRM）对接</span>
              </div>
              <span class="text_47"
                >开放API接口，支持与国内外CRM系统对接，实现数据流转</span
              >
            </div>
            <div class="flex-col group_21">
              <div class="flex-row group_22">
                <div class="left-section_3"></div>
                <span class="text_48">统一市场与销售信息</span>
              </div>
              <span class="text_49"
                >可将CRM中的线索跟进情况同步至营销云平台，包含标签、备注等，市场部可根据进展，进行针对性内容设计，加速线索转化</span
              >
            </div>
            <div
              @click="goformPageFun()"
              class="button_3 flex-col items-center"
            >
              <span>立即体验</span>
            </div>
          </div>
        </div>
        <div class="flex-col group_23">
          <span class="text_51">精确评估营销活动ROI</span>
          <img
            src="@/res/local/00d202145ead78ca7f623853804860c6.png"
            class="image_14"
          />
          <div class="flex-col group_24">
            <div class="flex-row group_25">
              <div class="left-section_3"></div>
              <span class="text_52">营销活动效果分析</span>
            </div>
            <span class="text_53"
              >内置多个统计维度的数据分析看板，实现营销效果可量化，精确衡量ROI</span
            >
          </div>
          <div class="flex-col group_26">
            <div class="flex-row group_27">
              <div class="left-section_3"></div>
              <span class="text_54">线索来源归因分析</span>
            </div>
            <span class="text_55"
              >通过在落地页表单和二维码配置渠道来源参数，可为线索精准溯源，为营销渠道和内容的优化提供有力根据</span
            >
          </div>
          <div @click="goformPageFun()" class="button_3 flex-col items-center">
            <span>立即体验</span>
          </div>
        </div>
        <div class="flex-col section_9">
          <span class="text_57">成功案例</span>
          <div class="mySwiperPar">
            <div class="swiper mySwiper mySwiper1">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="flex-col group_28">
                    <div class="flex-row section_10">
                      <span>
                        <img
                          src="@/res/local/16377417687141280804.png"
                          class="image_15"
                        />
                      </span>

                      <img
                        src="@/res/local/994a00cf5c8f903da4989e3b6874619d.png"
                        class="image_16"
                      />
                      <span class="text_58 text_59"
                        >作为知名外企，戴尔公司非常认同ABM策略，但在中国的业务发展过程中，无法快速将ABM方法论结合本土的市场环境，在线索质量和数量上也面临不足。通过与火眼云专业团队建立合作，迅速一站式落地ABM解决方案，仅用一季度的时间，为业务团队带来大量精准线索，预估商机金额到达900万美金。</span
                      >
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="flex-col group_28">
                    <div class="flex-row section_10">
                      <span>
                        <img
                          src="@/res/local/logo_10.png"
                          class="image_15 image_15_new"
                        />
                      </span>

                      <img
                        src="@/res/local/994a00cf5c8f903da4989e3b6874619d.png"
                        class="image_16"
                      />
                      <span class="text_58 text_59"
                        >在深耕行业化打法的探索中，纷享销客采用适用于B2B业务的ABM解决方案作为市场策略支撑和全套方案验证，与火眼云建立合作伙伴关系，以垂直行业活动为具体落地方式，通过完整的ABM一站式方案实施，为纷享销客在短短60天内，线索量增加63%，真正达成降本增效，精准出击。</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
          <div class="flex-col group_29">
            <span class="text_60">他们也在用</span>
            <div class="flex-row group_30">
              <div class="grid">
                <div class="flex-col items-center grid-item">
                  <img
                    src="@/res/local/16377417720235425188.png"
                    class="image_18"
                  />
                </div>
                <div class="grid-item_1 flex-col items-center">
                  <img
                    src="@/res/local/16377417720231477864.png"
                    class="image_18"
                  />
                </div>
                <div class="grid-item_1 flex-col items-center">
                  <img
                    src="@/res/local/c8240b94125456554fded61cd4d6b9ed.png"
                    class="image_18"
                  />
                </div>
                <div class="flex-col items-center grid-item_2">
                  <img
                    src="@/res/local/7f2e66101245d0bcaab67cfe6aac9172.png"
                    class="image_18"
                  />
                </div>
              </div>
              <div class="flex-col items-center image-wrapper_2">
                <img
                  src="@/res/local/3358aacff61b54b6ef62bc4d0a5034eb.png"
                  class="image_18"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="text_61">了解更多ABM营销自动化方案</span>
      <div class="mySwiperPar1">
        <div class="swiper mySwiper mySwiper2">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div
                @click="
                  goOtherPageFun('/marketing-digital-transformation.html')
                "
                class="flex-col group_31"
              >
                <div class="flex-col items-center image-wrapper_3">
                  <img src="@/res/local/lujing13_c1.png" class="image_24" />
                </div>
                <span class="text_62">B2B数字化转型</span>
              </div>
            </div>
            <div class="swiper-slide">
              <div
                @click="goOtherPageFun('/Intent-analysis.html')"
                class="flex-col group_31"
              >
                <div class="flex-col items-center image-wrapper_3">
                  <img src="@/res/local/lujing13_c2.png" class="image_24" />
                </div>
                <span class="text_62">行为意图分析</span>
              </div>
            </div>
            <div class="swiper-slide">
              <div
                @click="goOtherPageFun('/wechat-marketing.html')"
                class="flex-col group_31"
              >
                <div class="flex-col items-center image-wrapper_3">
                  <img src="@/res/local/lujing13_c3.png" class="image_24" />
                </div>
                <span class="text_62">私域营销</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="flex-col group_32">
        <div class="flex-col section_11">
          <span class="text_63">留下您的联系方式<br />专属顾问会尽快与您联系</span>
          <div class="justify-between search">
            <div class="flex-row group_33">
              <img src="@/res/local/16376631404543724564.png" class="image_25" />
              <span class="text_64">请输入您的手机号码</span>
            </div>
            <div class="flex-col items-center text-wrapper_6">
              <span>免费试用</span>
            </div>
          </div>
          <div class="group_34">
            <span class="text_66">如有疑问，欢迎致电客服热线</span>
            <span class="text_67">400-8521-789</span>
          </div>
        </div>
        <div class="text_58 flex-col view_28">
          <div class="section_12"></div>
          <div class="justify-between section_13">
            <div class="flex-col group_35">
              <div class="group_36">
                <span class="text_68">电话：</span>
                <span class="text_69">400-8521-789</span>
                <span class="text_70">邮箱： </span>
                <span class="text_71">marketing@huoyan.cn</span>
              </div>
              <span class="text_72">©2017 北京绘客科技有限公司 <br />京ICP备16023115号-1 隐私政策</span>
            </div>
            <img src="@/res/remote/06c5f76966fbb88f09c7d54f941ea701.png" class="image_26" />
          </div>
          <img src="@/res/local/52ca3200bc469c20b13881eecea2030c.svg" class="image_27" />
        </div>
      </div> -->
      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import vFooter from "@/components/footer.vue";
import Swiper from "swiper";
export default {
  data() {
    return {
      listSlpcNcHA: [null, null, null],
      listR1QBWj3r: [null, null, null],
    };
  },
  components: {
    vFooter,
    vHeader,
  },
  mounted() {
    var swiper = new Swiper(".mySwiper1", {
      pagination: {
        el: ".swiper-pagination",
      },
      // loop:true
    });
    var swiper1 = new Swiper(".mySwiper2", {
      slidesPerView: 3,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
    });
  },
  methods: {
    goformPageFun(val) {
      if (val == 1) {
        window.location.href = "/form.html?mobile=" + this.input1;
      } else {
        window.location.href = "/form.html?mobile=";
      }
    },
    goOtherPageFun(path) {
      window.location.href = path;
    },
  },
};
</script>

<style scoped lang="css">
/deep/.swiper-pagination-bullet-active {
  background-color: #ff6a00;
}
.mySwiperPar {
  position: relative;
  overflow: hidden;
  padding-bottom: 3rem;
}
.mySwiperPar1 {
  position: relative;
  overflow: hidden;
}
.swiper {
  width: 100%;
}

.swiper-slide {
}

.mySwiper2 .swiper-slide {
  transition: 300ms;
  z-index: 1;
  transform: scale(0.8);
}
.mySwiper2 .swiper-slide-active,
.mySwiper1 .swiper-slide-duplicate-active {
  transform: scale(1);
  z-index: 2;
}
.mySwiper2 .swiper-slide span {
  display: none;
  width: 9rem;
  text-align: center;
  /* padding-top: 16px; */
}
.mySwiper2 .swiper-slide-active span {
  display: block;
}
/* .image_15_new{
      height: 1.3rem !important;
    } */
.text_58 {
  position: relative;
}
.button_3 {
  margin-top: 1.88rem;
  align-self: center;
  color: rgb(255, 255, 255);
  font-size: 0.81rem;
  font-weight: 500;
  line-height: 1.13rem;
  white-space: nowrap;
  padding: 0.44rem 0;
  background-image: linear-gradient(
    99.8deg,
    rgb(255, 106, 0) 0%,
    rgb(255, 106, 0) -2.22%,
    rgb(255, 106, 0) 82.66%,
    rgb(255, 106, 0) 100%
  );
  box-shadow: 0px 0.13rem 0.56rem 0px rgba(255, 65, 65, 0.1);
  border-radius: 0.13rem;
  width: 6.25rem;
}
.list-item_1 {
  padding: 0 1.56rem 1.25rem;
}
.list-item {
  padding: 0.94rem 0.41rem 0.97rem 0.81rem;
  background-image: linear-gradient(
    180deg,
    rgb(246, 247, 251) 0%,
    rgb(246, 247, 251) 0%,
    rgb(253, 253, 255) 100%,
    rgb(253, 253, 255) 100%
  );
  box-shadow: 0px 0.13rem 1.19rem 0px rgba(227, 227, 227, 0.5);
  border-radius: 0.19rem;
  border: solid 0.2rem rgb(255, 255, 255);
}
.group_12 {
  margin-top: 1.88rem;
}
.group_14 {
  margin-top: 1.94rem;
}
.button_1 {
  margin-top: 1.56rem;
  padding: 0.44rem 0;
  align-self: center;
  color: rgb(255, 255, 255);
  font-size: 0.81rem;
  font-weight: 500;
  line-height: 1.13rem;
  white-space: nowrap;
  background-image: linear-gradient(
    99.8deg,
    rgb(255, 106, 0) 0%,
    rgb(255, 106, 0) -2.22%,
    rgb(255, 106, 0) 82.66%,
    rgb(255, 106, 0) 100%
  );
  box-shadow: 0px 0.13rem 0.56rem 0px rgba(255, 65, 65, 0.1);
  border-radius: 0.13rem;
  width: 6.25rem;
}
.left-section_3 {
  align-self: center;
  background-color: rgb(216, 216, 216);
  border-radius: 50%;
  width: 0.25rem;
  height: 0.25rem;
}
.image_6 {
  width: 3rem;
  height: 3rem;
}
.right-group {
  margin-left: 1.19rem;
  margin-top: 0.31rem;
  flex: 1 1 auto;
}
.top-group {
  color: rgb(38, 38, 38);
  font-size: 0.88rem;
  font-weight: 500;
  line-height: 1.25rem;
  white-space: nowrap;
}
.text_22 {
  margin-left: 0.75rem;
  margin-top: 0.69rem;
  /* align-self: center; */
  color: rgba(89, 89, 89, 0.95);
  font-size: 0.81rem;
  line-height: 1.13rem;
  white-space: nowrap;
}
.text_221 {
  white-space: pre-wrap;
}
.grid-item_1 {
  padding: 0.56rem 0 0.63rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
  border-radius: 0.13rem;
  position: relative;
}
.image_18 {
  width: 5.63rem;
  height: 1.75rem;
}
.text_6 {
  color: rgb(38, 38, 38);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.38rem;
  white-space: nowrap;
}
.text_8 {
  margin-top: 0.63rem;
  color: rgb(89, 89, 89);
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.41rem;
  text-align: left;
  width: 14rem;
}
.left-section {
  align-self: center;
  background-color: rgb(216, 216, 216);
  border-radius: 50%;
  width: 0.25rem;
  height: 0.25rem;
}
.text_20 {
  margin-left: 0.5rem;
}
.container {
  padding-top: 3.5rem;
  background-color: #ffffff;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}
.header {
  height: 6.38rem;
}
.group_3 {
  padding-top: 1.69rem;
  flex: 1 1 auto;
  overflow-y: auto;
}
.group {
  padding: 0.84rem 0.88rem 0.72rem 2.19rem;
  color: rgb(0, 0, 0);
  font-size: 0.94rem;
  line-height: 1.13rem;
  letter-spacing: -0.019rem;
  white-space: nowrap;
}
.section_1 {
  padding: 0.88rem 1rem 0.81rem 1.09rem;
  color: rgb(255, 106, 0);
  font-size: 0.81rem;
  font-weight: 500;
  line-height: 1.13rem;
  white-space: nowrap;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0.13rem 0.25rem 0px rgba(0, 0, 0, 0.03);
}
.text_61 {
  margin-top: 2.5rem;
  align-self: center;
  color: rgb(35, 35, 35);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  white-space: nowrap;
}
.group_31 {
  margin-top: 2.19rem;
  padding: 0 0.81rem;
  color: rgb(38, 38, 38);
  font-size: 0.81rem;
  font-weight: 500;
  line-height: 1.13rem;
  white-space: nowrap;
}
.group_32 {
  margin-top: 3.25rem;
}
.group_1 {
  margin: 0.19rem 0 0.22rem;
}
.group_2 {
  align-self: center;
}
.text-wrapper {
  padding: 0.44rem 0;
  background-color: rgba(255, 106, 0, 0.05);
  border-radius: 1rem;
  width: 5rem;
  height: 2rem;
}
.group_23 {
  margin-top: 2.63rem;
  padding: 0 1.56rem;
}
.section_9 {
  margin-top: 2rem;
  padding: 1.88rem 1.25rem 1.63rem 1.31rem;
  background-color: rgb(248, 249, 251);
}
.image-wrapper_3 {
  background-image: url("https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/6198d70c8611d00011b57c2f/6198d8928611d00011b57c86/16377417725036935418.png");
  background-position: 0px 0.31rem;
  background-size: 100% 91%;
  background-repeat: no-repeat;
}
.text_62 {
  margin-top: 1rem;
  align-self: center;
}
.section_11 {
  padding: 1.94rem 1.69rem 1.31rem 1.75rem;
  background-color: rgb(249, 250, 252);
}
.image {
  width: 1.06rem;
  height: 0.69rem;
}
.image_1 {
  margin-left: 0.28rem;
  width: 1rem;
  height: 0.69rem;
}
.image_2 {
  margin-left: 0.28rem;
  width: 1.53rem;
  height: 0.72rem;
}
.image_3 {
  align-self: center;
  width: 0.81rem;
  height: 0.69rem;
}
.image_4 {
  margin-left: 1.59rem;
  width: 4.94rem;
  height: 1.5rem;
}
.group_6 {
  padding: 0 1.25rem 0.88rem 1.31rem;
}
.section_8 {
  margin-top: 1rem;
  padding: 1.75rem 1.56rem 0.94rem;
  background-color: rgb(248, 249, 251);
}
.text_51 {
  align-self: center;
  color: rgb(35, 35, 35);
  font-size: 1.13rem;
  font-weight: 600;
  line-height: 1.56rem;
  white-space: nowrap;
}
.image_14 {
  margin-top: 1.25rem;
  align-self: center;
  width: 20rem;
  height: 13.75rem;
}
.group_24 {
  margin-top: 1.31rem;
}
.group_26 {
  margin-top: 1.88rem;
}
.text_57 {
  align-self: center;
  color: rgb(35, 35, 35);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  white-space: nowrap;
}
.group_28 {
  margin-top: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.19rem;
  color: rgb(38, 38, 38);
  font-size: 0.81rem;
  line-height: 1.63rem;
}
.group_29 {
  margin-top: 2.81rem;
  color: rgb(35, 35, 35);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  white-space: nowrap;
}
.image_24 {
  filter: drop-shadow(0px 0.063rem 0.44rem rgba(174, 174, 174, 0.64));
  width: 11.44rem;
  /* height: 7.13rem; */
}
.text_63 {
  align-self: center;
  color: rgb(38, 38, 38);
  font-size: 1.13rem;
  font-weight: 500;
  line-height: 1.13rem;
  text-align: center;
  width: 12.38rem;
}
.search {
  margin-top: 0.88rem;
  padding: 0.5rem 0.31rem 0.44rem 0.56rem;
  filter: drop-shadow(0px 0.88rem 0.66rem #1c25330d);
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  border: solid 0.063rem rgb(222, 222, 222);
}
.group_34 {
  margin-top: 1.13rem;
  align-self: center;
  text-align: center;
  width: 11.38rem;
}
.section_12 {
  background-color: rgb(31, 41, 55);
  height: 2.06rem;
}
.section_13 {
  padding: 3.25rem 1.06rem 1.94rem 1.44rem;
  background-color: rgb(31, 41, 55);
}
.image_27 {
  width: 7.25rem;
  height: 2.25rem;
  position: absolute;
  left: 1.44rem;
  top: 1.44rem;
}
.group_7 {
  /* padding-left: 1.19rem;
    padding-right: 1.13rem; */
}
.image_5 {
  margin-top: 1.31rem;
  align-self: center;
  width: 19.75rem;
  height: 14.56rem;
}
.button {
  margin-top: 1.19rem;
  padding: 0.56rem 0;
  align-self: center;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.38rem;
  white-space: nowrap;
  background-color: rgb(255, 106, 0);
  border-radius: 0.13rem;
  width: 11.13rem;
}
.text_5 {
  margin-top: 2.44rem;
  align-self: center;
  color: rgb(35, 35, 35);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  white-space: nowrap;
}
.list {
  margin-top: 1.81rem;
}
.group_8 {
  margin-top: 3.88rem;
}
.list-item_1:last-of-type {
  margin-top: 2.5rem;
}
.text_45 {
  align-self: center;
  color: rgb(35, 35, 35);
  font-size: 1.13rem;
  font-weight: 600;
  line-height: 1.56rem;
  white-space: nowrap;
}
.image_13 {
  margin-top: 1.69rem;
  align-self: center;
  width: 16.13rem;
  height: 12.19rem;
}
.group_19 {
  margin-top: 2.44rem;
}
.group_21 {
  margin-top: 1.88rem;
}
.group_25 {
  color: rgb(38, 38, 38);
  font-size: 0.88rem;
  font-weight: 500;
  line-height: 1.25rem;
  white-space: nowrap;
}
.text_53 {
  margin: 0.63rem 0.88rem 0 0.75rem;
  color: rgba(89, 89, 89, 0.95);
  font-size: 0.81rem;
  line-height: 1.25rem;
  text-align: left;
}
.group_27 {
  color: rgb(38, 38, 38);
  font-size: 0.88rem;
  font-weight: 500;
  line-height: 1.25rem;
  white-space: nowrap;
}
.text_55 {
  margin-left: 0.75rem;
  margin-top: 0.56rem;
  color: rgba(89, 89, 89, 0.95);
  font-size: 0.81rem;
  line-height: 1.5rem;
  text-align: left;
}
.section_10 {
  padding: 1.31rem 0.94rem 3rem;
  overflow: hidden;
  border-radius: 0.94rem;
  background-color: rgb(255, 255, 255);
  height: 16.81rem;
  position: relative;
}
.text_60 {
  align-self: center;
}
.group_30 {
  margin-top: 1rem;
}
.group_33 {
  margin: 0.19rem 0 0.31rem;
  color: rgb(199, 199, 199);
  font-size: 0.88rem;
  line-height: 1.25rem;
  white-space: nowrap;
}
.text-wrapper_6 {
  padding: 0.44rem 0;
  color: rgb(255, 255, 255);
  font-size: 0.81rem;
  font-weight: 500;
  line-height: 1.13rem;
  white-space: nowrap;
  background-image: linear-gradient(
    102.2deg,
    rgb(255, 106, 0) 0%,
    rgb(255, 106, 0) -2.22%,
    rgb(255, 106, 0) 81.34%,
    rgb(255, 106, 0) 100%
  );
  box-shadow: 0px 0.13rem 0.25rem 0px rgba(255, 65, 65, 0.5);
  border-radius: 0.25rem;
  width: 5rem;
  height: 2rem;
}
.text_66 {
  color: rgb(137, 137, 137);
  font-size: 0.88rem;
  line-height: 1.5rem;
}
.text_67 {
  color: rgb(255, 106, 0);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.38rem;
}
.group_35 {
  width: 12.44rem;
}
.image_26 {
  margin-top: 2.63rem;
  width: 6.25rem;
  height: 6.25rem;
}
.text_2 {
  align-self: center;
  color: rgb(38, 38, 38);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.06rem;
  white-space: nowrap;
}
.text_3 {
  margin-top: 0.81rem;
  color: rgb(38, 38, 38);
  font-size: 0.88rem;
  line-height: 1.38rem;
  text-align: center;
}
.list-item:not(:first-of-type) {
  margin-top: 0.81rem;
}
.text_14 {
  color: rgb(35, 35, 35);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  white-space: nowrap;
}
.text_15 {
  margin-top: 1rem;
  color: rgb(35, 35, 35);
  font-size: 1.13rem;
  font-weight: 600;
  line-height: 1.56rem;
  white-space: nowrap;
}
.group_20 {
  color: rgb(38, 38, 38);
  font-size: 0.88rem;
  font-weight: 500;
  line-height: 1.25rem;
  white-space: nowrap;
}
.text_47 {
  margin: 0.63rem 1rem 0 0.75rem;
  color: rgba(89, 89, 89, 0.95);
  font-size: 0.81rem;
  line-height: 1.25rem;
  text-align: left;
}
.group_22 {
  color: rgb(38, 38, 38);
  font-size: 0.88rem;
  font-weight: 500;
  line-height: 1.25rem;
  white-space: nowrap;
}
.text_49 {
  margin-left: 0.75rem;
  margin-top: 0.56rem;
  color: rgba(89, 89, 89, 0.95);
  font-size: 0.81rem;
  line-height: 1.5rem;
  text-align: left;
}
.text_52 {
  margin-left: 0.5rem;
}
.text_54 {
  margin-left: 0.5rem;
}
.image_15 {
  width: 3rem;
  /* height: 3rem; */
}
.image_16 {
  width: 4.63rem;
  height: 4.25rem;
  position: absolute;
  left: 0;
  top: 12.56rem;
}
.text_59 {
  margin-left: 1.19rem;
  margin-right: 0.13rem;
  flex: 1 1 auto;
  text-align: justify;
}
.grid {
  flex: 1 1 auto;
  height: 6.38rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.56rem;
}
.image-wrapper_2 {
  margin-left: 0.5rem;
  padding: 0.56rem 0 0.63rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
  border-radius: 0.13rem;
  width: 6.63rem;
  height: 2.94rem;
}
.image_25 {
  width: 1.5rem;
  height: 1.5rem;
}
.text_64 {
  margin-left: 0.81rem;
  margin-top: 0.19rem;
}
.group_36 {
  text-align: left;
  width: 11.56rem;
}
.text_72 {
  margin-top: 2.19rem;
  color: rgb(140, 140, 140);
  font-size: 0.88rem;
  line-height: 1.63rem;
  text-align: left;
}
.image_10 {
  margin-left: 0.75rem;
  margin-right: 0.56rem;
  width: 19rem;
  height: 14.38rem;
}
.group_11 {
  margin-top: 1.06rem;
  color: rgb(38, 38, 38);
  font-size: 0.88rem;
  font-weight: 500;
  line-height: 1.25rem;
  white-space: nowrap;
}
.text_17 {
  margin: 0.63rem 0.75rem 0 0.81rem;
  color: rgba(89, 89, 89, 0.95);
  font-size: 0.81rem;
  line-height: 1.25rem;
  text-align: left;
}
.group_13 {
  color: rgb(38, 38, 38);
  font-size: 0.88rem;
  font-weight: 500;
  line-height: 1.25rem;
  white-space: nowrap;
}
.text_19 {
  margin-left: 0.75rem;
  margin-top: 0.56rem;
  color: rgba(89, 89, 89, 0.95);
  font-size: 0.81rem;
  line-height: 1.5rem;
  text-align: left;
}
.text_46 {
  margin-left: 0.5rem;
}
.text_48 {
  margin-left: 0.5rem;
}
.grid-item {
  padding: 0.63rem 0 0.56rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0.063rem 0.63rem 0px rgba(224, 224, 224, 0.5);
  border-radius: 0.094rem;
  position: relative;
}
.grid-item_2 {
  padding: 0.56rem 0 0.63rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
  border-radius: 0.13rem;
}
.text_68 {
  color: rgb(255, 255, 255);
  font-size: 0.88rem;
  line-height: 2.19rem;
}
.text_69 {
  color: rgb(255, 255, 255);
  font-size: 0.88rem;
  font-weight: 600;
  line-height: 2.19rem;
}
.text_70 {
  color: rgb(255, 255, 255);
  font-size: 0.88rem;
  line-height: 1.25rem;
}
.text_71 {
  color: rgb(255, 255, 255);
  font-size: 0.88rem;
  font-weight: 600;
  line-height: 1.25rem;
}
.section_5 {
  align-self: center;
  background-color: rgb(216, 216, 216);
  border-radius: 50%;
  width: 0.25rem;
  height: 0.25rem;
}
.text_16 {
  margin-left: 0.5rem;
}
.section_6 {
  align-self: center;
  background-color: rgb(216, 216, 216);
  border-radius: 50%;
  width: 0.25rem;
  height: 0.25rem;
}
.text_18 {
  margin-left: 0.5rem;
}
</style>
