<template>
  <div class="flex-col container">
    <div class="flex-col group_1">
      <div class="flex-col group_2">
        <v-header></v-header>
        <div class="flex-col group_4">
          <span class="text_2">私域营销</span>
          <span class="text_3">基于微信生态的营销增长方案</span>
          <img src="@/res/remote/97dc8b25c19bceff1b8585ad60b68d5c.png" class="image_5" />
          <div @click="goformPageFun()" class="flex-col items-center button">
            <span>定制您的专属方案</span>
          </div>
          <div class="flex-col group_5">
            <span class="text_5">方案价值</span>
            <div class="flex-col list">
              <div class="list-item flex-row">
                <img src="@/res/local/jjfa_marketing_assets_icon.png" class="image_6" />
                <div class="right-group flex-col">
                  <span class="text_6">丰富企业数据资产</span>
                  <span class="text_8"
                    >基于火眼云庞大数据能力，将公域流量中的目标客户精准引流到私域流量池，规模化扩充企业营销数据资产。</span
                  >
                </div>
              </div>
              <div class="list-item flex-row">
                <img src="@/res/local/jjfa_marketing_fans_icon.png" class="image_6" />
                <div class="right-group flex-col">
                  <span class="text_6">微信粉丝实名化</span>
                  <span class="text_8"
                    >大量的“匿名”粉丝和社群成员对业务的价值不甚明朗。火眼云可帮助企业实名化部分微信粉丝与社群成员，更好地提升私域营销价值。</span
                  >
                </div>
              </div>
              <div class="list-item flex-row">
                <img src="@/res/local/jjfa_marketing_promote_icon.png" class="image_6" />
                <div class="right-group flex-col">
                  <span class="text_6">提升获客转化效率</span>
                  <span class="text_8"
                    >微信本身具备强连接属性，火眼云帮助企业建立基于微信的数字化运营闭环，大幅度降低获客成本和难度，构建增长新引擎</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex-col group_6">
            <span class="text_14">火眼云ABM一站式增长解决方案</span>
            <div class="flex-col items-center group_7">
              <span>精准引流</span>
              <img src="@/res/remote/c7520185fca941e86621ba2492e1977d.png" class="image_10 image_11" />
            </div>
          </div>
          <div class="group_8 flex-col view_3">
            <div class="flex-row group_9">
              <div class="left-section"></div>
              <span class="text_16">火眼数据云</span>
            </div>
            <span class="text_17">覆盖海量企业和职场人群，灵活筛选维度，批量生成待触达人群包</span>
          </div>
          <div class="group_8 flex-col view_5">
            <div class="flex-row group_10">
              <div class="left-section"></div>
              <span class="text_18">定向人群挖掘</span>
            </div>
            <span class="text_19"
              >基于火眼云数据分析和算力，可持续地定向挖掘私域生态下各垂直平台人群，锁定更精细颗粒度的目标人群。</span
            >
          </div>
          <div class="group_8 flex-col view_7">
            <div class="flex-col group_11">
              <div class="flex-row group_12">
                <div class="left-section"></div>
                <span class="text_20">全渠道触达引流</span>
              </div>
              <span class="text_21"
                >利用整合多渠道的营销渠道整合平台触达精准人群，以微信公众号/微信群/客服个人微信等作为私域流量入口，将公域流量引流至私域。</span
              >
            </div>
            <div @click="goformPageFun()" class="bottom-text-wrapper flex-col items-center view_9">
              <span>立即体验</span>
            </div>
          </div>
        </div>
        <div class="flex-col section_5">
          <span class="text_23">精准识别</span>
          <img src="@/res/remote/748220e290191e295ce0e6abcc5b586f.png" class="image_10 image_12" />
          <div class="flex-col group_13">
            <div class="flex-row group_14">
              <div class="left-section"></div>
              <span class="text_24">微信粉丝实名化</span>
            </div>
            <span class="text_25"
              >将企业公众号与ABM营销云平台绑定，匹配火眼云DMP数据与企业CRM数据，即刻实名化公众号粉丝，让私域运营效果最大化。</span
            >
          </div>
          <div class="flex-col group_15">
            <div class="flex-col group_16">
              <div class="flex-row group_17">
                <div class="left-section"></div>
                <span class="text_26">社群成员识别</span>
              </div>
              <span class="text_27"
                >以火眼云匿名流量识别能力实名化社群成员，提供更清晰的社群运营视角，帮助企业开展高效运营策略。</span
              >
            </div>
            <div @click="goformPageFun()" class="bottom-text-wrapper flex-col items-center text-wrapper_1">
              <span>立即体验</span>
            </div>
          </div>
        </div>
        <div class="flex-col group_18">
          <span class="button_1">精细运营</span>
          <img src="@/res/remote/2e90906acff58f5ae7152a2a100aba54.png" class="image_10 image_13" />
          <div class="flex-col group_19">
            <div class="flex-row group_20">
              <div class="left-section"></div>
              <span class="text_29">统一客户身份</span>
            </div>
            <span class="text_30"
              >基于火眼云One ID能力，将同一客户的手机号码、微信OPEN
              ID、设备号等不同终端的身份进行合并，以唯一ID的形式保存在营销云。</span
            >
          </div>
          <div class="flex-col group_21">
            <div class="flex-row group_22">
              <div class="left-section"></div>
              <span class="text_31">呈现客户旅程</span>
            </div>
            <span class="text_32"
              >除了微信端的客户行为外，其他渠道的行为数据也将归集合并，从匿名阶段开始的客户旅程将以时间顺序清晰呈现。</span
            >
          </div>
          <div class="flex-col group_23">
            <div class="flex-row group_24">
              <div class="left-section"></div>
              <span class="text_33">个性化营销培育</span>
            </div>
            <span class="text_34"
              >营销渠道整合汇聚广告、邮件、短信、电销等多种触达手段，匹配个性化内容+自动化营销技术，促进用户互动及转化。</span
            >
          </div>
          <div @click="goformPageFun()" class="bottom-text-wrapper flex-col items-center view_15">
            <span>立即体验</span>
          </div>
        </div>
        <div class="flex-col section_6">
          <span class="text_36">成功案例</span>
            <div class="mySwiperPar">
              <div class="swiper mySwiper mySwiper1">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="flex-col group_25">
                      <div class="flex-row section_7">
                        <span>
                          <img src="@/res/local/logo_21.png" class="image_14 image_15_new" />
                        </span>
                        
                        <img src="@/res/local/994a00cf5c8f903da4989e3b6874619d.png" class="image_15" />
                        <span class="text_37 text_38"
                          >作为国内领先的知识产权解决方案提供商，超凡网在营销层面也在积极创新，放大私域营销价值，采用专业性强的内容营销将目标客户引流至微信公众号，识别和匹配匿名粉丝信息，并借助营销渠道整合多频次+多角度个性化触达目标客户，实现线索快速转化。并实现老客户的复购和增购，真正为业务产生价值。</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="flex-col group_25">
                      <div class="flex-row section_7">
                        <span>
                          <img src="@/res/local/logo_18.png" class="image_14 image_15_new" />
                        </span>
                        
                        <img src="@/res/local/994a00cf5c8f903da4989e3b6874619d.png" class="image_15" />
                        <span class="text_37 text_38"
                          >在日常的培育过程中，云学堂采用大量社群来沉淀和触达目标客户。但经常会发现“匿名”群成员，无法判定其是否匹配目标客户画像。利用火眼云私域营销方案，能够让该企业的所有社群成员不再“神秘”，通过群成员信息识别和人企关联，企业可以清晰判断社群中有多少目标客户，为精细化的培育策略提供有效支撑。</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>
            </div>
          <div class="flex-col group_26">
            <span class="text_39">他们也在用</span>
            <div class="flex-row group_27">
              <div class="grid">
                <div class="flex-col items-center grid-item">
                  <img src="@/res/local/logo_7.png" class="image_17" />
                </div>
                <div class="grid-item_1 flex-col items-center">
                  <img src="@/res/local/logo_12.png" class="image_17" />
                </div>
                <div class="grid-item_1 flex-col items-center">
                  <img src="@/res/local/liepin_logo.png" class="image_17" />
                </div>
                <div class="flex-col items-center grid-item_2">
                  <img src="@/res/local/logo_25.png" class="image_17" />
                </div>
              </div>
              <div class="flex-col items-center image-wrapper_2">
                <img src="@/res/local/logo_1.png" class="image_17" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="text_40">了解更多ABM营销自动化方案</span>
      <div class="mySwiperPar1"> 
          <div class="swiper mySwiper mySwiper2">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="flex-col group_28" @click="goOtherPageFun('/marketing-digital-transformation.html')">
                  <div class="flex-col items-center image-wrapper_3">
                    <img src="@/res/local/lujing13_c1.png" class="image_23" />
                  </div>
                  <span class="text_62">B2B数字化转型</span>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="flex-col group_28" @click="goOtherPageFun('/Intent-analysis.html')">
                  <div class="flex-col items-center image-wrapper_3">
                    <img src="@/res/local/lujing13_c2.png" class="image_23" />
                  </div>
                  <span class="text_62">行为意图分析</span>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="flex-col group_28" @click="goOtherPageFun('/abm_grow.html')">
                  <div class="flex-col items-center image-wrapper_3">
                    <img src="@/res/local/lujing13.png" class="image_23" />
                  </div>
                  <span class="text_62">ABM一站式增长</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- <div class="flex-col group_28">
        <div class="flex-col items-center image-wrapper_3">
          <img src="@/res/local/7680e0f18080c186b26510f717f3babb.png" class="image_23" />
        </div>
        <span class="text_41">行为意图分析</span>
      </div> -->
      <!-- <div class="flex-col group_29">
        <div class="flex-col section_8">
          <span class="text_42">留下您的联系方式<br />专属顾问会尽快与您联系</span>
          <div class="justify-between search">
            <div class="flex-row group_30">
              <img src="@/res/local/16376631404543724564.png" class="image_24" />
              <span class="text_43">请输入您的手机号码</span>
            </div>
            <div class="flex-col items-center text-wrapper_2">
              <span>免费试用</span>
            </div>
          </div>
          <div class="group_31">
            <span class="text_45">如有疑问，欢迎致电客服热线</span>
            <span class="text_46">400-8521-789</span>
          </div>
        </div>
        <div class="text_37 flex-col view_16">
          <div class="section_9"></div>
          <div class="justify-between section_10">
            <div class="flex-col group_32">
              <div class="group_33">
                <span class="text_47">电话：</span>
                <span class="text_48">400-8521-789</span>
                <span class="text_49">邮箱： </span>
                <span class="text_50">marketing@huoyan.cn</span>
              </div>
              <span class="text_51">©2017 北京绘客科技有限公司 <br />京ICP备16023115号-1 隐私政策</span>
            </div>
            <img src="@/res/remote/06c5f76966fbb88f09c7d54f941ea701.png" class="image_25" />
          </div>
          <img src="@/res/local/52ca3200bc469c20b13881eecea2030c.svg" class="image_26" />
        </div>
      </div> -->
      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
import vFooter from '@/components/footer.vue'
import Swiper from 'swiper';
  export default {
    data() {
      return { listjcbFb11V: [null, null, null] };
    },
        components: {
      vFooter,vHeader
    },
    mounted(){
      var swiper = new Swiper(".mySwiper1", {
        pagination: {
          el: ".swiper-pagination",
        },
        // loop:true
      });
      var swiper1 = new Swiper('.mySwiper2', {
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
      });
    },
    methods: {
      goformPageFun(val){
        if(val==1){
          window.location.href='/form.html?mobile='+this.input1
        }else{
          window.location.href='/form.html?mobile='
        }
      },
      goOtherPageFun(path){
        window.location.href = path
      }
    }
  };
</script>

<style scoped lang="css">
/deep/.swiper-pagination-bullet-active{
  background-color: #FF6A00;
}
    .mySwiperPar{
      position: relative;
      overflow: hidden;
      padding-bottom: 3rem;
    }
      .swiper {
        width: 100%;
      }

      .swiper-slide {

      }
    .mySwiperPar1{
      position: relative;
      overflow: hidden;
    }
    .mySwiper2 .swiper-slide {
      transition: 300ms;
      z-index: 1;
      transform: scale(0.8);
    }
    .mySwiper2 .swiper-slide-active,.mySwiper1 .swiper-slide-duplicate-active{
        transform: scale(1);
        z-index: 2;
    }
    .mySwiper2 .swiper-slide span{
      display: none;
      width: 9rem;
      text-align: center;
      /* padding-top: 16px; */
    }
    .mySwiper2 .swiper-slide-active span{
      display: block;
    }
    /* .image_15_new{
      height: 1.3rem !important;
    } */
  .text_62 {
    margin-top: 1rem;
    align-self: center;
  }
  .text_37 {
    position: relative;
  }
  .group_8 {
    padding: 0 0.31rem;
  }
  .image_10 {
    width: 20rem;
    height: 12.5rem;
  }
  .bottom-text-wrapper {
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      99.8deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 82.66%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.56rem 0px rgba(255, 65, 65, 0.1);
    border-radius: 0.13rem;
    width: 6.25rem;
  }
  .list-item {
    padding: 0.75rem 0.44rem 1.25rem 0.94rem;
    background-image: linear-gradient(
      180deg,
      rgb(246, 247, 251) 0%,
      rgb(246, 247, 251) 0%,
      rgb(253, 253, 255) 100%,
      rgb(253, 253, 255) 100%
    );
    box-shadow: 0px 0.13rem 1.5rem 0px rgba(227, 227, 227, 0.5);
    border-radius: 0.25rem;
    border: solid 0.25rem rgb(255, 255, 255);
  }
  .left-section {
    align-self: center;
    background-color: rgb(216, 216, 216);
    border-radius: 50%;
    width: 0.25rem;
    height: 0.25rem;
  }
  .image_6 {
    width: 3rem;
    height: 3rem;
  }
  .right-group {
    margin-left: 1.06rem;
    flex: 1 1 auto;
  }
  .grid-item_1 {
    padding: 0.56rem 0 0.63rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.13rem;
    position: relative;
  }
  .image_17 {
    width: 5.63rem;
    height: 1.75rem;
  }
  .text_6 {
    color: rgb(38, 38, 38);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.38rem;
    white-space: nowrap;
  }
  .text_8 {
    margin-right: 0.94rem;
    margin-top: 0.69rem;
    color: rgb(89, 89, 89);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: left;
  }
  .container {
    padding-top:3.5rem;
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
  }
  .header {
    padding: 0.84rem 0.88rem 0.72rem 2.19rem;
    color: rgb(0, 0, 0);
    font-size: 0.94rem;
    line-height: 1.13rem;
    letter-spacing: -0.019rem;
    white-space: nowrap;
    height: 2.69rem;
  }
  .group_1 {
    flex: 1 1 auto;
    overflow-y: auto;
  }
  .group {
    margin: 0.19rem 0 0.22rem;
  }
  .text_40 {
    margin-top: 2.5rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_28 {
    margin-top: 2.19rem;
    padding: 0 0.81rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .group_29 {
    margin-top: 3.25rem;
  }
  .image {
    width: 1.06rem;
    height: 0.69rem;
  }
  .image_1 {
    margin-left: 0.28rem;
    width: 1rem;
    height: 0.69rem;
  }
  .image_2 {
    margin-left: 0.28rem;
    width: 1.53rem;
    height: 0.72rem;
  }
  .section_1 {
    padding: 0.88rem 1rem 0.81rem;
    color: rgb(255, 106, 0);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(0, 0, 0, 0.03);
    position: relative;
  }
  .group_4 {
    padding: 1.69rem 1.25rem 1.88rem;
  }
  .section_5 {
    padding: 1.38rem 1.56rem 1.56rem;
    background-color: rgb(248, 249, 251);
  }
  .group_18 {
    padding: 2.69rem 1.56rem;
  }
  .section_6 {
    margin-top: 0.56rem;
    padding: 1.88rem 1.25rem 1.63rem 1.31rem;
    background-color: rgb(248, 249, 251);
  }
  .image-wrapper_3 {
    background-image: url('https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/6198d70c8611d00011b57c2f/6198d8928611d00011b57c86/16377417725036935418.png');
    background-position: 0px 0.31rem;
    background-size: 100% 91%;
    background-repeat: no-repeat;
  }
  .text_41 {
    margin-top: 1.25rem;
    align-self: center;
  }
  .section_8 {
    padding: 1.94rem 1.69rem 1.31rem 1.75rem;
    background-color: rgb(249, 250, 252);
  }
  .group_3 {
    align-self: center;
  }
  .text-wrapper {
    padding: 0.44rem 0;
    background-color: rgba(255, 106, 0, 0.05);
    border-radius: 1rem;
    width: 5rem;
    height: 2rem;
  }
  .text_2 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.06rem;
    white-space: nowrap;
  }
  .text_3 {
    margin-top: 0.88rem;
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .image_5 {
    margin-top: 1.75rem;
    align-self: center;
    width: 20.25rem;
    height: 14.94rem;
  }
  .button {
    margin-top: 1.69rem;
    padding: 0.56rem 0;
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.38rem;
    white-space: nowrap;
    background-color: rgb(255, 106, 0);
    border-radius: 0.13rem;
    width: 11.13rem;
  }
  .group_5 {
    margin-top: 2.44rem;
  }
  .group_6 {
    margin-top: 2.31rem;
    padding-left: 0.5rem;
    padding-right: 0.44rem;
  }
  .view_3 {
    margin-top: 1.75rem;
  }
  .view_5 {
    margin-top: 1.63rem;
  }
  .view_7 {
    margin-top: 1.88rem;
  }
  .text_23 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .image_12 {
    margin-top: 1.13rem;
    align-self: center;
  }
  .group_13 {
    margin-top: 1.44rem;
  }
  .group_15 {
    margin-top: 1.63rem;
  }
  .button_1 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .image_13 {
    margin-top: 1.25rem;
    align-self: center;
  }
  .group_19 {
    margin-top: 2.56rem;
  }
  .group_21 {
    margin-top: 1.63rem;
  }
  .group_23 {
    margin-top: 1.88rem;
  }
  .view_15 {
    margin-top: 2.38rem;
  }
  .text_36 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_25 {
    margin-top: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.19rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    line-height: 1.63rem;
  }
  .group_26 {
    margin-top: 2.81rem;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .image_23 {
    filter: drop-shadow(0px 0.063rem 0.44rem rgba(174, 174, 174, 0.64));
    width: 11.44rem;
    /* height: 7.13rem; */
  }
  .text_42 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.13rem;
    font-weight: 500;
    line-height: 1.13rem;
    text-align: center;
    width: 12.38rem;
  }
  .search {
    margin-top: 0.88rem;
    padding: 0.5rem 0.31rem 0.44rem 0.56rem;
    filter: drop-shadow(0px 0.88rem 0.66rem #1c25330d);
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    border: solid 0.063rem rgb(222, 222, 222);
  }
  .group_31 {
    margin-top: 1.13rem;
    align-self: center;
    text-align: center;
    width: 11.38rem;
  }
  .section_9 {
    background-color: rgb(31, 41, 55);
    height: 2.06rem;
  }
  .section_10 {
    padding: 3.25rem 1.06rem 1.81rem 1.44rem;
    background-color: rgb(31, 41, 55);
  }
  .image_26 {
    width: 7.25rem;
    height: 2.25rem;
    position: absolute;
    left: 1.44rem;
    top: 1.44rem;
  }
  .image_3 {
    align-self: center;
    width: 1rem;
    height: 1rem;
  }
  .image_4 {
    margin-left: 1.5rem;
    width: 4.94rem;
    height: 1.5rem;
  }
  .text_5 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .list {
    margin-top: 0.75rem;
  }
  .text_14 {
    margin-left: 1.06rem;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_7 {
    margin-top: 0.88rem;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .group_9 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_17 {
    margin: 0.63rem 0.88rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: left;
  }
  .group_10 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_19 {
    margin-left: 0.75rem;
    margin-top: 0.56rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .view_9 {
    margin-top: 1.38rem;
  }
  .group_14 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_25 {
    margin: 0.63rem 0.63rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: justify;
  }
  .text-wrapper_1 {
    margin-top: 1.63rem;
  }
  .group_20 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_30 {
    margin: 0.63rem 0.69rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: left;
  }
  .group_22 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_32 {
    margin-left: 0.75rem;
    margin-top: 0.56rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .group_24 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_34 {
    margin-left: 0.75rem;
    margin-top: 0.56rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .section_7 {
    padding: 1.31rem 0.94rem 3rem;
    overflow: hidden;
    border-radius: 0.94rem;
    background-color: rgb(255, 255, 255);
    height: 16.81rem;
    position: relative;
  }
  .image_16 {
    margin-top: 0.94rem;
    align-self: center;
    width: 1.38rem;
    height: 0.25rem;
  }
  .text_39 {
    align-self: center;
  }
  .group_27 {
    margin-top: 1rem;
  }
  .group_30 {
    margin: 0.19rem 0 0.31rem;
    color: rgb(199, 199, 199);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text-wrapper_2 {
    padding: 0.44rem 0;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-image: linear-gradient(
      102.2deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 81.34%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(255, 65, 65, 0.5);
    border-radius: 0.25rem;
    width: 5rem;
    height: 2rem;
  }
  .text_45 {
    color: rgb(137, 137, 137);
    font-size: 0.88rem;
    line-height: 1.5rem;
  }
  .text_46 {
    color: rgb(255, 106, 0);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.38rem;
  }
  .group_32 {
    width: 12.44rem;
  }
  .image_25 {
    margin-top: 2.63rem;
    width: 6.25rem;
    height: 6.25rem;
  }
  .list-item:not(:first-of-type) {
    margin-top: 0.75rem;
  }
  .image_11 {
    margin-top: 0.88rem;
  }
  .text_16 {
    margin-left: 0.5rem;
  }
  .text_18 {
    margin-left: 0.5rem;
  }
  .group_12 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_21 {
    margin-left: 0.75rem;
    margin-top: 0.63rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: left;
  }
  .text_24 {
    margin-left: 0.5rem;
  }
  .group_17 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_27 {
    margin: 0.63rem 0.44rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: justify;
  }
  .text_29 {
    margin-left: 0.5rem;
  }
  .text_31 {
    margin-left: 0.5rem;
  }
  .text_33 {
    margin-left: 0.5rem;
  }
  .image_14 {
    width: 3rem;
    /* height: 3rem; */
  }
  .image_15 {
    width: 4.63rem;
    /* height: 4.25rem; */
    position: absolute;
    left: 0;
    top: 12.56rem;
  }
  .text_38 {
    margin-left: 1.19rem;
    margin-right: 0.13rem;
    flex: 1 1 auto;
    text-align: justify;
  }
  .grid {
    flex: 1 1 auto;
    height: 6.38rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 0.5rem;
    grid-column-gap: 0.56rem;
  }
  .image-wrapper_2 {
    margin-left: 0.5rem;
    padding: 0.56rem 0 0.63rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.13rem;
    width: 6.63rem;
    height: 2.94rem;
  }
  .image_24 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .text_43 {
    margin-left: 0.81rem;
    margin-top: 0.19rem;
  }
  .group_33 {
    text-align: left;
    width: 11.56rem;
  }
  .text_51 {
    margin-top: 2.19rem;
    color: rgb(140, 140, 140);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: left;
  }
  .text_20 {
    margin-left: 0.5rem;
  }
  .text_26 {
    margin-left: 0.5rem;
  }
  .grid-item {
    padding: 0.63rem 0 0.56rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.63rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.094rem;
    position: relative;
  }
  .grid-item_2 {
    padding: 0.56rem 0 0.63rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.13rem;
  }
  .text_47 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 2.19rem;
  }
  .text_48 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 2.19rem;
  }
  .text_49 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 1.25rem;
  }
  .text_50 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 1.25rem;
  }
</style>