<template>
  <div class="flex-col container">
    <v-header></v-header>
    <div class="flex-col group_3">
      <div class="flex-col section_2">
        <div class="flex-col group_4">
          <div class="flex-col items-center group_5">
            <span class="text_2">情报云</span>
            <span class="text_3">在竞争对手之前找到意向客户</span>
          </div>
          <img src="@/res/remote/qingbao.png" class="image_5" />
        </div>
        <span class="text_4">火眼情报云通过企业情报分析和行为意图数据，实时定位对您产品或服务感兴趣的潜在客户</span>
        <div class="bottom-section justify-between view">
          <!-- <div class="left-group flex-row">
            <img src="@/res/local/16376631404543724564.png" class="image_6" />
            <span class="text_5">请输入您的手机号码</span>
          </div> -->
          <img src="@/res/local/16376631404543724564.png" class="image_6 image_7_1" />
          <input v-model="input1" class="left-group flex-row" type="text" placeholder="请输入您的手机号码">
          <div @click="goformPageFun(1)" class="right-text-wrapper flex-col items-center">
            <span>预约演示</span>
          </div>
        </div>
      </div>
      <div class="flex-col group_6">
        <span class="text_8">功能亮点</span>
        <span class="text_9">国内B2B意图情报分析发明者与引领者</span>
        <div class="flex-col group_7">
          <div class="section_3 flex-row view_3">
            <img src="@/res/local/bc2997b775d62a9b7ca45c5d143175a2.png" class="image_8" />
            <div class="flex-col group_8">
              <span class="text_10">获取客户意图</span>
              <span class="text_12">使用意图数据确定潜在客户优先级，单位时间完成更多线索转化</span>
            </div>
          </div>
          <div class="section_3 flex-row">
            <img src="@/res/local/a1da93467d99c1a3a4d62a7c74e73677.png" class="image_8" />
            <div class="flex-col group_9">
              <span class="text_10">提升线索转化</span>
              <span class="text_14">全面了解潜在客户的意图，抓住转化时机提供有效触达</span>
            </div>
          </div>
          <div class="section_3 flex-row">
            <img src="@/res/local/e2d7daa2ebeb28e645089f5af7f39945.png" class="image_8" />
            <div class="flex-col group_10">
              <span class="text_10">提供销售情报</span>
              <span class="text_16">发现目标客户意图并及时告知销售或SDR人员，及时跟进促进成交</span>
            </div>
          </div>
          <div class="section_3 flex-row">
            <img src="@/res/local/890deddef066b9194bdd812c57050061.png" class="image_8" />
            <div class="flex-col group_11">
              <span class="text_10">减少客户流失</span>
              <span class="text_16">当您的客户正在研究竞品解决方案时，我们会在客户流失之前提醒您</span>
            </div>
          </div>
        </div>
        <span class="text_20">潜在客户情报分析</span>
        <span class="text_21">通过行业、营收、技术应用、岗位方向、特定行为等多维度数据进行潜在客户的需求洞察分析</span>
        <img src="@/res/remote/da7067ddd313a3b32a6db3ce87d9e1e4.svg" class="image_13 image_14" />
        <div class="flex-col group_12">
          <div class="flex-row group_13">
            <div class="left-section"></div>
            <span class="text_22">通过企业特征洞察，找出对您有潜在需求的企业</span>
          </div>
          <div class="flex-row group_14">
            <div class="left-section"></div>
            <span class="text_23">通过职场人群行为数据分析，匹配正在研究你的潜在客户</span>
          </div>
          <div class="flex-row group_15">
            <div class="left-section"></div>
            <span class="text_24">将情报数据无缝衔接到企业自有数据中</span>
          </div>
        </div>
        <div @click="goformPageFun()" class="button flex-col items-center text-wrapper_1">
          <span>免费试用</span>
        </div>
        <div class="group_16 flex-col view_10">
          <span class="text_26">企业高意向行为分析</span>
          <span class="text_28">使用意图数据来确定哪些企业正在研究您或是您竞品的产品和服务</span>
        </div>
        <div class="flex-col group_17">
          <img src="@/res/remote/e9483753ef4f946e5c25c292d4ad2399.svg" class="image_13 image_15" />
          <div class="bottom-group flex-col view_11">
            <div class="flex-row group_18">
              <div class="left-section"></div>
              <span class="text_30">通过行为意图数据分析，在您的竞争对手之前主动找到高意向潜在客户</span>
            </div>
            <div class="center-group flex-row">
              <div class="left-section"></div>
              <span class="text_31">自定义行为意图主题，高效识别出专属购买意向信号</span>
            </div>
            <div class="bottom-group_1 flex-row">
              <div class="left-section"></div>
              <span class="text_32">根据行为意图数据优先处理热线索，把握营销与转化时机</span>
            </div>
          </div>
        </div>
        <div @click="goformPageFun()" class="button flex-col items-center">
          <span>免费试用</span>
        </div>
        <div class="flex-col group_19">
          <span class="text_34">匿名访客识别</span>
          <span class="text_35">98%的网站访客不会留资，我们帮助您发掘这些有高购买意愿但没有留资的企业</span>
        </div>
        <img src="@/res/remote/df822423f379dbc168171d22eabea587.svg" class="image_13 image_16" />
        <div class="flex-col group_20">
          <div class="flex-row group_21">
            <div class="left-section"></div>
            <span class="text_36">以匿名访客识别能力，精准锁定访客背后企业或是访客所在位置</span>
          </div>
          <div class="flex-row group_22">
            <div class="left-section"></div>
            <span class="text_37">基于识别出的企业和访客行为信息，分析其购买意图</span>
          </div>
          <div class="flex-row group_23">
            <div class="left-section"></div>
            <span class="text_38">识别来访渠道，评估渠道质量，优化营销策略</span>
          </div>
        </div>
        <div @click="goformPageFun()" class="button flex-col items-center">
          <span>了解更多</span>
        </div>
        <span class="text_40">微信私域匿名粉丝实名化</span>
        <div class="flex-col group_24">
          <div class="flex-col items-center group_25">
            <span>识别您的“匿名”粉丝和社群成员，他们是谁？</span>
            <span class="text_42">在干什么？将私域流量转化为可联系的有效线索</span>
          </div>
          <img src="@/res/remote/b465d48913b13c4944d6bcbb351e0e19.svg" class="image_13 image_17" />
          <div class="bottom-group flex-col view_20">
            <div class="flex-row group_26">
              <div class="left-section"></div>
              <span class="text_43">微信粉丝实名化，匹配粉丝画像标签</span>
            </div>
            <div class="center-group flex-row">
              <div class="left-section"></div>
              <span class="text_44">识别客户所在微信群、并获取访问和下载行为</span>
            </div>
            <div class="bottom-group_1 flex-row">
              <div class="left-section"></div>
              <span class="text_45">与自有数据合并，以OneID技术统一客户视图</span>
            </div>
          </div>
        </div>
        <div @click="goformPageFun()" class="button flex-col items-center">
          <span>免费试用</span>
        </div>
        <div class="group_16 flex-col view_26">
          <span class="text_26">充分利用情报意图数据</span>
          <span class="text_28">通过实时的意图、情报和预测数据，创建自动更新的动态客户组，以便随时分析</span>
        </div>
        <img src="@/res/local/b4c80f2bfa38e4cdfe2ae7afea7c5758.svg" class="image_18" />
        <div class="flex-col group_27">
          <div class="flex-row group_28">
            <div class="left-section"></div>
            <span class="text_49">根据行为意图数据，优先触达、转化高意向目标客户</span>
          </div>
          <div class="flex-row group_29">
            <div class="left-section"></div>
            <span class="text_50">根据特定意图信号，匹配对应营销内容</span>
          </div>
          <div class="flex-row group_30">
            <div class="left-section"></div>
            <span class="text_51">掌握实时意图数据，时刻拥有第一手客户信息</span>
          </div>
        </div>
        <div @click="goformPageFun()" class="button flex-col items-center">
          <span>免费试用</span>
        </div>
        <span class="text_53">应用场景</span>
      </div>
      <div class="flex-col group_31">
        <div class="flex-col section_4">
          <img src="@/res/local/8bae080468283e06fcd02fad18d2eba6.png" class="image_19" />
          <div class="flex-col group_32">
            <span class="text_54">确定客户优先级</span>
            <div class="flex-col items-center group_33">
              <span>根据目标客户的行为意图和情报进行人群细分，</span>
              <span class="text_56">优先营销高意向目标</span>
            </div>
          </div>
        </div>
        <div class="flex-col section_5">
          <img src="@/res/local/pd_qby_xsqbdc.png" class="image_19" />
          <div class="flex-col group_32">
            <span class="text_57">销售情报洞察</span>
            <div class="flex-col items-center group_34">
              <span>为销售人员提供目标客户的行为意图及情报数据，</span>
              <span class="text_59">快速提升购买周期</span>
            </div>
          </div>
          
        </div>
        <div class="flex-col section_6">
          <img src="@/res/local/pd_qby_szgg.png" class="image_19" />
          <div class="flex-col items-center ">
            <span class="text_60">数字广告</span>
            <span class="text_61">实现基于意图的广告，向高意向行为人群投放个性化广告内容</span>
          </div>
        </div>
        
        <div class="flex-col section_7">
          <img src="@/res/local/554e8891b7326289ae21743441508e61.png" class="image_20 image_21" />
          <div class="flex-col group_35">
            <span class="text_62">营销内容优化</span>
            <span class="text_63">了解哪些内容可以让客户产生共鸣，并且洞察买家旅程阶段的内容适配度</span>
          </div>
        </div>
        <div class="flex-col section_8">
          <img src="@/res/local/5c5652590454d284f759554e58a5f4f0.png" class="image_20 image_22" />
          <div class="flex-col group_36">
            <span class="text_64">投放渠道优化</span>
            <div class="flex-col items-center group_37">
              <span>通过匿名和实名客户行为，监控投放渠道效果，</span>
              <span class="text_66">调整渠道来增加获客效率</span>
            </div>
          </div>
        </div>
        <div class="flex-col section_9">
          <img src="@/res/local/pd_sjy_abmyx.png" class="image_20 image_22" />
          <div class="flex-col">
            <span class="text_67">ABM营销</span>
            <div class="flex-col items-center group_38">
              <span>匿名线索以及高意向人群，都可以生成特定线索列表，</span>
              <span class="text_69">作为ABM策略的目标人群</span>
            </div>
          </div>
        </div>

      </div>
      <div class="flex-col group_39">
        <div class="flex-col group_40">
          <span class="text_70">火眼云一站式营销增长产品矩阵</span>
          <span class="text_71">找到目标客户 、发现关键意图 、创新营销体验，并开展爆炸式营销增长</span>
          <div class="flex-row group_41">
            <div class="flex-col items-center group_42">
              <div class="section_10">
                <span class="liuguang"></span>
              </div>
              <img src="@/res/local/a8e129945b8c9d085b5d3356e13e4bef.png" class="image_23" />
              <img src="@/res/local/dbc56334b545bcdc59619a25af96c5ad.png" class="image_24" />
              <img src="@/res/local/548757465bc8230d91f7a254af8da972.png" class="image_25" />
              <img src="@/res/local/8c13bce909108634edc08da1d14465bd.png" class="image_26" />
            </div>
            <div class="flex-col group_43">
              <div @click="goOtherPageFun('/abm-marketing-cloud.html')" class="flex-col group_44">
                <span class="text_72">ABM营销云</span>
                <span class="text_73">让大规模、个性化执行目标客户营销（ABM）策略成为可能</span>
              </div>
              <div @click="goOtherPageFun('/Intent-cloud.html')" class="flex-col group_45">
                <span class="text_74">情报云</span>
                <span class="text_75">通过企业情报和行为意图，实时定位对您产品或服务感兴趣的潜在客户</span>
              </div>
              <div @click="goOtherPageFun('/cdp.html')" class="flex-col group_46">
                <span class="text_76">客户数据平台（CDP）</span>
                <span class="text_77">以OneID技术打通、补充、规范企业多元数据，统一客户视图，实现营销数字化转型</span>
              </div>
              <div @click="goOtherPageFun('/ad-cloud.html')" class="flex-col group_47">
                <span class="text_78">广告云</span>
                <span class="text_79">进行数字广告的全渠道投放、监测、归因分析以及投放策略优化</span>
              </div>
            </div>
          </div>
        </div>
        <div @click="goOtherPageFun('/data-cloud.html')" class="flex-row section_11">
          <img src="@/res/local/7afc9facdfff43874d0ffaf4318ef4c3.png" class="image_20 image_27" />
          <div class="flex-col group_48">
            <span class="text_80">数据云（DMP）</span>
            <span class="text_81"
              >覆盖6000万企业主体及1.6亿职场人群画像，掌握B2B企业情报及意向数据，帮助您识别、理解和吸引理想的目标客户</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="flex-col group_49">
        <div class="flex-col section_12">
          <span class="text_82">留下您的联系方式<br />专属顾问会尽快与您联系</span>
          <div class="bottom-section justify-between section_13">
            <div class="left-group flex-row">
              <img src="@/res/local/16376631404543724564.png" class="image_6" />
              <span class="text_5">请输入您的手机号码</span>
            </div>
            <div class="right-text-wrapper flex-col items-center">
              <span>免费试用</span>
            </div>
          </div>
          <div class="group_50">
            <span class="text_85">如有疑问，欢迎致电客服热线</span>
            <span class="text_86">400-8521-789</span>
          </div>
        </div>
        <div class="flex-col group_51">
          <div class="section_14"></div>
          <div class="justify-between section_15">
            <div class="flex-col group_52">
              <div class="group_53">
                <span class="text_87">电话：</span>
                <span class="text_88">400-8521-789</span>
                <span class="text_89">邮箱： </span>
                <span class="text_90">marketing@huoyan.cn</span>
              </div>
              <span class="text_91">©2017 北京绘客科技有限公司 <br />京ICP备16023115号-1 隐私政策</span>
            </div>
            <img src="@/res/remote/06c5f76966fbb88f09c7d54f941ea701.png" class="image_29" />
          </div>
          <img src="@/res/local/52ca3200bc469c20b13881eecea2030c.svg" class="image_30" />
        </div>
      </div> -->
      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
import vFooter from '@/components/footer.vue'
  export default {
    data() {
      return {
        input1:''
      };
    },
        components: {
      vFooter,vHeader
    },
    methods: {
      goformPageFun(val){
        var mobileReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if(val == 1){
          if(!this.input1){
            window.location.href= '/form.html?mobile='
          } else if(mobileReg.test(this.input1)){
             window.location.href = '/form.html?mobile='+this.input1
          } else {
            alert('手机格式错误!')
          }
        }else{
          window.location.href= '/form.html?mobile='
        }
      },
      goOtherPageFun(path){
        window.location.href = path
      }
    }
  };
</script>

<style scoped lang="css">
  .bottom-section {
        position: relative;
    height: 49px;
    /* padding: 0.5rem 0.31rem 0.44rem 0.56rem; */
    filter: drop-shadow(0px 0.88rem 0.66rem #1c25330d);
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    border: solid 0.063rem rgb(222, 222, 222);
  }
  .bottom-section input{
     width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 10px;
    padding-left:2rem;
   }
  .image_7_1{
    position: absolute;
    top: 0.7rem;
    left:.3125rem
  }
  .image_13 {
    width: 20rem;
    height: 12.5rem;
  }
  .button {
    margin-top: 1.69rem;
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      99.8deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 82.66%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.56rem 0px rgba(255, 65, 65, 0.1);
    border-radius: 0.13rem;
    width: 6.25rem;
  }
  .group_16 {
    padding-left: 0.56rem;
    padding-right: 0.5rem;
  }
  .right-text-wrapper {
    position: absolute;
    right:.625rem;
    top: 0.4rem;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      102.2deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 81.34%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(255, 65, 65, 0.5);
    border-radius: 0.25rem;
    width: 5rem;
    height: 2rem;
  }
  .section_3 {
    margin-top: 1rem;
    padding: 1.5rem 1.5rem 1.75rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 1.13rem 0px rgb(243, 244, 246);
    border-radius: 0.25rem;
  }
  .bottom-group {
    padding: 0 1.38rem;
  }
  .image_20 {
    width: 5rem;
    height: 5rem;
  }
  .image_6 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .image_8 {
    width: 3rem;
    height: 3rem;
  }
  .left-section {
    align-self: center;
    background-color: rgb(212, 212, 212);
    border-radius: 50%;
    width: 0.38rem;
    height: 0.38rem;
  }
  .text_16 {
    margin-top: 0.81rem;
    color: rgb(35, 35, 35);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    width: 13.13rem;
  }
  .text_26 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_10 {
    color: rgb(51, 51, 51);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .left-group {
    margin: 0.19rem 0 0.31rem;
    color: rgb(199, 199, 199);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_28 {
    margin-top: 1.25rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: center;
  }
  .text_5 {
    margin-left: 0.81rem;
    margin-top: 0.19rem;
  }
  .center-group {
    margin-top: 1.38rem;
  }
  .bottom-group_1 {
    margin-top: 1.38rem;
  }
  .container {
    padding-top:3.5rem;
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
  }
  .header {
    height: 6.38rem;
  }
  .group_3 {
    padding-top: 0.19rem;
    flex: 1 1 auto;
    overflow-y: auto;
  }
  .group {
    padding: 0.84rem 0.88rem 0.72rem 2.19rem;
    color: rgb(0, 0, 0);
    font-size: 0.94rem;
    line-height: 1.13rem;
    letter-spacing: -0.019rem;
    white-space: nowrap;
  }
  .section_1 {
    padding: 0.88rem 1rem 0.81rem 1.09rem;
    color: rgb(255, 106, 0);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(0, 0, 0, 0.03);
  }
  .section_2 {
    padding: 2.06rem 1.19rem 1.63rem;
    background-image: linear-gradient(
      180deg,
      rgb(250, 250, 251) 0%,
      rgb(250, 250, 251) 0%,
      rgb(243, 244, 246) 100%,
      rgb(243, 244, 246) 100%
    );
    overflow: hidden;
    height: 29.38rem;
  }
  .group_6 {
    margin-top: 2.5rem;
    padding-left: 1.25rem;
    padding-right: 1rem;
  }
  .group_31 {
    margin-top: 1.88rem;
  }
  .group_39 {
    margin-top: 3.94rem;
    height: 52.25rem;
  }
  .group_49 {
    margin-top: 3.13rem;
  }
  .group_1 {
    margin: 0.19rem 0 0.22rem;
  }
  .group_2 {
    align-self: center;
  }
  .text-wrapper {
    padding: 0.44rem 0;
    background-color: rgba(255, 106, 0, 0.05);
    border-radius: 1rem;
    width: 5rem;
    height: 2rem;
  }
  .group_4 {
    padding: 0 3.47rem;
  }
  .text_4 {
    margin-right: 0.75rem;
    margin-top: 2.13rem;
    color: rgb(61, 61, 61);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .view {
    margin: 2.44rem 0.5rem 0 0.56rem;
  }
  .text_8 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_9 {
    margin-top: 1rem;
    align-self: center;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .group_7 {
    margin-top: 2rem;
  }
  .text_20 {
    margin-top: 3.69rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_21 {
    margin: 1.25rem 0.5rem 0 0.56rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: center;
  }
  .image_14 {
    margin-top: 1.63rem;
    align-self: center;
  }
  .group_12 {
    margin-top: 1.81rem;
    padding: 0 1.5rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .text-wrapper_1 {
    margin-top: 1.94rem;
  }
  .view_10 {
    margin-top: 3rem;
  }
  .group_17 {
    margin-top: 1.88rem;
    padding: 0 0.31rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .group_19 {
    margin-top: 4.25rem;
    padding-left: 0.5rem;
    padding-right: 0.38rem;
  }
  .image_16 {
    margin-top: 1.81rem;
    align-self: center;
  }
  .group_20 {
    margin-top: 1.63rem;
    padding-left: 1.63rem;
    padding-right: 1.5rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .text_40 {
    margin-top: 3.25rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_24 {
    margin-top: 1.25rem;
    padding: 0 0.44rem;
  }
  .view_26 {
    margin-top: 3.25rem;
  }
  .image_18 {
    margin-top: 2.63rem;
    align-self: center;
    width: 20.06rem;
    height: 12.5rem;
  }
  .group_27 {
    margin-top: 1.69rem;
    padding: 0 3.19rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .text_53 {
    margin-top: 3.88rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .section_4 {
    padding: 1.19rem 2.81rem 1.19rem 3.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
    border-top: solid 0.26rem rgb(245, 245, 245);
  }
  .section_5 {
    padding: 1.19rem 2.38rem 1.56rem 2.69rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_6 {
    padding: 1.19rem 0 3.13rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.25rem;
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_7 {
    padding: 1.13rem 0.75rem 1.53rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_8 {
    padding: 1.13rem 2.81rem 1.31rem 3.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_9 {
    padding: 1.19rem 1.56rem 1.38rem 1.88rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
    border-bottom: solid 0.26rem rgb(245, 245, 245);
  }
  .group_40 {
    padding-left: 1.31rem;
    padding-right: 1.25rem;
  }
  .section_11 {
    margin-top: -0.44rem;
    padding: 2.19rem 1.25rem 2rem 1.31rem;
    background-image: linear-gradient(
      90deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.29%,
      rgb(255, 181, 129) 148.28%,
      rgb(255, 181, 129) 100%
    );
    position: relative;
  }
  .section_12 {
    padding: 1.94rem 1.69rem 1.31rem 1.75rem;
    background-color: rgb(249, 250, 252);
  }
  .group_51 {
    padding-bottom: 13.94rem;
    position: relative;
  }
  .image {
    width: 1.06rem;
    height: 0.69rem;
  }
  .image_1 {
    margin-left: 0.28rem;
    width: 1rem;
    height: 0.69rem;
  }
  .image_2 {
    margin-left: 0.28rem;
    width: 1.53rem;
    height: 0.72rem;
  }
  .image_3 {
    align-self: center;
    width: 0.81rem;
    height: 0.69rem;
  }
  .image_4 {
    margin-left: 1.59rem;
    width: 4.94rem;
    height: 1.5rem;
  }
  .image_5 {
    margin-top: 0.75rem;
  }
  .view_3 {
    margin-top: 0;
  }
  .group_14 {
    margin-top: 1.38rem;
  }
  .group_15 {
    margin-top: 1.38rem;
  }
  .image_15 {
    margin-right: 0.56rem;
  }
  .view_11 {
    margin-top: 1.56rem;
  }
  .text_34 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_35 {
    margin-top: 1.25rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: center;
  }
  .group_22 {
    margin-top: 1.38rem;
  }
  .group_23 {
    margin-top: 1.38rem;
  }
  .group_25 {
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    white-space: nowrap;
  }
  .image_17 {
    margin-right: 0.31rem;
    margin-top: 1.31rem;
  }
  .view_20 {
    margin-top: 1.25rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .group_29 {
    margin-top: 1.38rem;
  }
  .group_30 {
    margin-top: 1.38rem;
  }
  .image_19 {
    align-self: center;
    width: 5.25rem;
    height: 5rem;
  }
  .group_32 {
    margin-top: 0.94rem;
  }
  .text_57 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_34 {
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_60 {
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_61 {
    margin-top: 1rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .image_21 {
    align-self: center;
  }
  .group_35 {
    margin-top: 1.03rem;
  }
  .image_22 {
    align-self: center;
  }
  .group_36 {
    margin-top: 1.13rem;
  }
  .text_67 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_38 {
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_70 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_71 {
    margin: 1.25rem 0.44rem 0 0.56rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: center;
  }
  .group_41 {
    margin-top: 3.06rem;
  }
  .image_27 {
    align-self: center;
  }
  .group_48 {
    margin-left: 1.25rem;
    flex: 1 1 auto;
  }
  .text_82 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.13rem;
    font-weight: 500;
    line-height: 1.13rem;
    text-align: center;
    width: 12.38rem;
  }
  .section_13 {
    margin-top: 0.88rem;
  }
  .group_50 {
    margin-top: 1.13rem;
    align-self: center;
    text-align: center;
    width: 11.38rem;
  }
  .section_14 {
    background-color: rgb(31, 41, 55);
    height: 2.06rem;
  }
  .section_15 {
    padding: 3.25rem 1.06rem 1.81rem 1.44rem;
    background-color: rgb(31, 41, 55);
    position: absolute;
    left: 0;
    right: 0;
    top: 2.06rem;
  }
  .image_30 {
    width: 7.25rem;
    height: 2.25rem;
    position: absolute;
    left: 1.44rem;
    top: 1.44rem;
  }
  .text_2 {
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_3 {
    margin-top: 0.38rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .group_8 {
    margin: 0.38rem 0.19rem 0 1rem;
    flex: 1 1 auto;
  }
  .group_9 {
    margin: 0.38rem 0.19rem 0 1rem;
    flex: 1 1 auto;
  }
  .group_10 {
    margin-left: 1rem;
    margin-top: 0.38rem;
    flex: 1 1 auto;
  }
  .group_11 {
    margin-left: 1rem;
    margin-top: 0.38rem;
    flex: 1 1 auto;
  }
  .text_22 {
    margin-left: 0.63rem;
    margin-right: 0.13rem;
  }
  .text_23 {
    margin-left: 0.63rem;
    margin-right: 0.13rem;
  }
  .text_24 {
    margin-left: 0.63rem;
  }
  .text_36 {
    margin-left: 0.63rem;
  }
  .text_37 {
    margin-left: 0.63rem;
    margin-right: 0.81rem;
  }
  .text_38 {
    margin-left: 0.63rem;
    margin-right: 0.81rem;
  }
  .text_42 {
    margin-top: 0.5rem;
  }
  .text_49 {
    margin-left: 0.63rem;
    margin-right: 0.81rem;
  }
  .text_50 {
    margin-left: 0.63rem;
  }
  .text_51 {
    margin-left: 0.63rem;
  }
  .text_54 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_33 {
    margin-top: 0.94rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_59 {
    margin-top: 0.5rem;
  }
  .text_62 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_63 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: center;
  }
  .text_64 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_37 {
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_69 {
    margin-top: 0.5rem;
  }
  .group_42 {
    padding-top: 4.56rem;
    width: 5rem;
    position: relative;
  }
  .group_43 {
    margin-left: 1.25rem;
    margin-top: 0.31rem;
    flex: 1 1 auto;
  }
  .text_80 {
    color: rgb(255, 255, 255);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_81 {
    margin-top: 0.38rem;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_85 {
    color: rgb(137, 137, 137);
    font-size: 0.88rem;
    line-height: 1.5rem;
  }
  .text_86 {
    color: rgb(255, 106, 0);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.38rem;
  }
  .group_52 {
    width: 12.44rem;
  }
  .image_29 {
    margin-top: 2.63rem;
    width: 6.25rem;
    height: 6.25rem;
  }
  .text_12 {
    margin-top: 0.81rem;
    color: rgb(35, 35, 35);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_14 {
    margin-top: 0.81rem;
    color: rgb(35, 35, 35);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_30 {
    margin-left: 0.63rem;
    margin-right: 0.56rem;
  }
  .text_31 {
    margin-left: 0.63rem;
    margin-right: 2.19rem;
  }
  .text_32 {
    margin-left: 0.63rem;
    margin-right: 0.56rem;
  }
  .text_43 {
    margin-left: 0.63rem;
    margin-right: 1.13rem;
  }
  .text_44 {
    margin-left: 0.63rem;
    margin-right: 0.31rem;
  }
  .text_45 {
    margin-left: 0.63rem;
  }
  .text_56 {
    margin-top: 0.5rem;
  }
  .text_66 {
    margin-top: 0.5rem;
  }
  .section_10 {
    background-image: linear-gradient(
      180.1deg,
      rgb(255, 211, 134) 0%,
      rgb(255, 211, 134) -38.14%,
      rgb(255, 239, 187) 118.97%,
      rgb(255, 239, 187) 100%
    );
    width: 0.38rem;
    height: 29.19rem;
    position: relative;
    overflow: hidden;
  }
    .liuguang{
    position: absolute;
    bottom:0rem;
    left:0;
    width: 0.38rem;
    height: 20rem;
    background:#f5bf62;
    animation: animate1 1s linear infinite;
  }
  @keyframes animate1 {
      0% {
          bottom: -100%;
      }
      50%,
      100% {
          bottom: 100%;
      }
  }
  .image_23 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .image_24 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 8.13rem;
  }
  .image_25 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 16.25rem;
  }
  .image_26 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 24.38rem;
  }
  .group_45 {
    margin-top: 3.81rem;
  }
  .group_46 {
    margin-top: 3.81rem;
  }
  .group_47 {
    margin-top: 3.81rem;
  }
  .group_53 {
    text-align: left;
    width: 11.56rem;
  }
  .text_91 {
    margin-top: 2.19rem;
    color: rgb(140, 140, 140);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: left;
  }
  .text_72 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_73 {
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    width: 13rem;
  }
  .text_74 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_75 {
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_76 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_77 {
    margin-right: 0.81rem;
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_78 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_79 {
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_87 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 2.19rem;
  }
  .text_88 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 2.19rem;
  }
  .text_89 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 1.25rem;
  }
  .text_90 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 1.25rem;
  }
</style>