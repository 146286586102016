<template>
  <div class="flex-col container">
    <v-header></v-header>
    <div class="flex-col group_3">
      <div class="flex-col group_4">
        <div class="flex-col section_2">
          <div class="flex-col items-center group_5">
            <span class="text_2">ABM营销云</span>
            <span class="text_3">搭载B2B企业级数据库的营销自动化产品</span>
          </div>
          <img src="@/res/local/67e2972db5f3297a0061653df8ad6e8f.png" class="image_5" />
          <div class="flex-col group_6">
            <span class="text_4"
              >选定目标客户进行全渠道互动，提高客户全生命周期转化率，让大规模、个性化执行目标客户营销（ABM）策略成为可能</span
            >
            <div class="bottom-section justify-between view">
              <!-- <div class="left-group flex-row">
                <img src="@/res/local/16376631404543724564.png" class="image_6" />
                <span class="text_5">请输入您的手机号码</span>
              </div> -->
              <img src="@/res/local/16376631404543724564.png" class="image_6 image_7_1" />
              <input v-model="input1" class="left-group flex-row" type="text" placeholder="请输入您的手机号码">
              <div @click="goformPageFun(1)" class="right-text-wrapper flex-col items-center">
                <span>预约演示</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-col group_7">
          <span class="text_8">功能亮点</span>
          <span class="text_9">基于目标客户的跨渠道营销自动化，实现规模化营销增长</span>
          <div class="flex-col group_8">
            <div class="flex-row section_3">
              <img src="@/res/local/1de872c6da2f8070db9313c9a25a68f7.png" class="image_8 image_9" />
              <div class="flex-col group_9">
                <span class="text_10">全渠道触达</span>
                <span class="text_11"
                  >链接主流营销渠道与平台，识别客户身份来源，以OneID统一客户视图，为客户提供一致性体验。</span
                >
              </div>
            </div>
            <div class="flex-row section_4">
              <img src="@/res/local/e20680f29ff703186585c947afd825e2.png" class="image_8 image_10" />
              <div class="flex-col group_10">
                <span class="text_12">确定目标客户</span>
                <span class="text_13"
                  >在火眼ABM营销云中发现高匹配目标客户，帮您在营销前确认全量目标客户</span
                >
              </div>
            </div>
            <div class="section_5 flex-row">
              <img src="@/res/local/0841b4333f6f8368db7d3b151c7143a8.png" class="image_8 left" />
              <div class="right-group flex-col">
                <span class="text_14">营销自动化</span>
                <span class="text_16"
                  >使用可视化工作流程，实时创建、调整跨渠道营销动作，实现对目标客户的大规模、自动化的全生命周期营销</span
                >
              </div>
            </div>
            <div class="section_5 flex-row">
              <img src="@/res/local/221bf971268f18999aceaa792854735a.png" class="image_8 left" />
              <div class="right-group flex-col">
                <span class="text_14">找到活跃线索</span>
                <span class="text_16"
                  >为目标客户的行为和兴趣进行评分，当线索达到特定分数时，我们会告诉您谁的意向度最高</span
                >
              </div>
            </div>
            <div class="section_5 flex-row">
              <img src="@/res/local/f7d4be3955f4e1a755f05c119ecbe05b.png" class="image_8 left" />
              <div class="right-group flex-col">
                <span class="text_14">效果实时衡量</span>
                <span class="text_16"
                  >可直接用于汇报的营销仪表盘，提供营销事件的归因分析和表现分析，还可根据需求定制报告</span
                >
              </div>
            </div>
          </div>
          <span class="text_21">创建目标客户列表</span>
          <span class="text_22">基于火眼数据云创建目标客户列表，并根据客户基础信息和行为信息进行动态分组</span>
          <img src="@/res/remote/abm_picture_cj.png" class="image_14 image_15" />
          <div class="group_11 items-center view_7">
            <div class="left-section"></div>
            <span class="text_23">从火眼云三方数据平台中确定目标客户</span>
          </div>
          <div class="group_11 flex-col view_9">
            <div class="flex-row group_12">
              <div class="left-section"></div>
              <span class="text_24">从您的官网和微信生态中识别新的潜在客户</span>
            </div>
            <div class="flex-row group_13">
              <div class="left-section"></div>
              <span class="text_25">从行为意图数据和中发现意向客户</span>
            </div>
          </div>
          <div @click="goformPageFun()" class="button flex-col items-center text-wrapper_1">
            <span>免费试用</span>
          </div>
          <div class="group_14 flex-col view_12">
            <span class="text_27">培育线索智能分群</span>
            <div class="bottom-group flex-col">
              <span class="text_29">根据客户基础信息和行为信息进行静态&动态分组，为每个细分客群量身定制营销策略</span>
              <img src="@/res/remote/a030ee9903e0d7b5d15a6e16b53fa623.svg" class="image_14 image_16" />
            </div>
          </div>
          <div class="flex-col group_15">
            <div class="flex-row group_16">
              <div class="left-section"></div>
              <span class="text_31">按照目标企业属性、标签多维度进行细分</span>
            </div>
            <div class="flex-row group_17">
              <div class="left-section"></div>
              <span class="text_32">按照人群职务、意图高低等进行细分</span>
            </div>
            <div class="flex-row group_18">
              <div class="left-section"></div>
              <span class="text_33">按照在官网或营销活动中的行为进行细分</span>
            </div>
          </div>
          <div @click="goformPageFun()" class="button flex-col items-center">
            <span>免费试用</span>
          </div>
          <div class="group_14 flex-col view_17">
            <span class="text_27">设置自动化营销流程</span>
            <div class="bottom-group flex-col">
              <span class="text_29">可视的自动化营销画布搭建，让客户旅程的各个阶段都可以自动化执行个性化的营销事件</span>
              <img src="@/res/local/544d4b6abe9aa2ee35a35cf314d67ca4.svg" class="image_14 image_17" />
            </div>
          </div>
          <div class="flex-col group_19">
            <div class="flex-row group_20">
              <div class="left-section"></div>
              <span class="text_37">通过简单的拖放就能完成自动营销流程搭建</span>
            </div>
            <div class="flex-row group_21">
              <div class="left-section"></div>
              <span class="text_38">轻松设置特定行为后的下一步营销动作</span>
            </div>
            <div class="flex-row group_22">
              <div class="left-section"></div>
              <span class="text_39">使用行业模板快速构建营销流程</span>
            </div>
          </div>
          <div @click="goformPageFun()" class="button flex-col items-center">
            <span>免费试用</span>
          </div>
          <div class="group_23 flex-col">
            <span class="text_41">全渠道触达与互动</span>
            <span class="text_43"
              >与腾讯、字节跳动、百度系平台深度合作，并配合微信、邮件、电话等渠道，实现全渠道触达目标客户</span
            >
          </div>
          <img src="@/res/remote/a35d011e7d81231a3ffcd7f456a7078c.svg" class="image_18 image_19" />
          <div class="flex-col group_24">
            <div class="flex-row group_25">
              <div class="left-section"></div>
              <span class="text_45">整合一线主流广告平台，跨渠道持续触达客户</span>
            </div>
            <div class="flex-row group_26">
              <div class="left-section"></div>
              <span class="text_46">根据客户行为意图数据智能选择触达方式</span>
            </div>
            <div class="flex-row group_27">
              <div class="left-section"></div>
              <span class="text_47">通过人群特征分析与营销自动化实现千人千面的内容触达</span>
            </div>
          </div>
          <div @click="goformPageFun()" class="button flex-col items-center">
            <span>免费试用</span>
          </div>
          <div class="group_23 flex-col">
            <span class="text_41">以OneID能力统一客户视图</span>
            <span class="text_43">链接各渠道的营销数据，为每个线索构建统一的客户视图，实现全生命周期管理</span>
          </div>
          <img src="@/res/remote/e43894de4e16003eb73034a17caddac8.svg" class="image_14 image_20" />
          <div class="flex-col group_28">
            <div class="flex-row group_29">
              <div class="left-section"></div>
              <span class="text_51">统一您的所有客户数据并进行完善与清洗</span>
            </div>
            <div class="flex-row group_30">
              <div class="left-section"></div>
              <span class="text_52">打通全营销触点数据，多渠道身份标记识别匹配</span>
            </div>
            <div class="flex-row group_31">
              <div class="left-section"></div>
              <span class="text_53">识别与记录客户匿名行为，实名后直接补充</span>
            </div>
          </div>
          <div @click="goformPageFun()" class="button flex-col items-center">
            <span>免费试用</span>
          </div>
          <div class="group_23 flex-col">
            <span class="text_41">营销全场景的可视化报表</span>
            <span class="text_43">基于市场部业务场景的分析报表，即可分析细节，也可纵观全局，实时调整策略<br /></span>
          </div>
          <img src="@/res/remote/5e42463d519c828bf0328a64c366a242.svg" class="image_18 image_21" />
          <div class="flex-col group_32">
            <div class="flex-row group_33">
              <div class="left-section"></div>
              <span class="text_57">每个目标客户的生命周期行为分析</span>
            </div>
            <div class="flex-row group_34">
              <div class="left-section"></div>
              <span class="text_58">每个营销活动的传播、渠道、归因分析</span>
            </div>
            <div class="flex-row group_35">
              <div class="left-section"></div>
              <span class="text_59">基于KPI可自定义组合数据仪表盘</span>
            </div>
          </div>
          <div @click="goformPageFun()" class="button flex-col items-center">
            <span>免费试用</span>
          </div>
          <div class="group_23 flex-col view_35">
            <span class="text_41">更多功能</span>
            <span class="text_43 text_62">目标客户管理、线索行为管理、营销内容管理、推广渠道管理、效果报告管理</span>
          </div>
        </div>
      </div>
      <div class="flex-col group_36">
        <div class="group_36_swiper">
          <div class="swiper mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="grid">
                  <div class="flex-col items-center grid-item">
                    <img src="@/res/local/abm_gd_01.png" class="image_22" />
                    <span class="text_63">企业匹配度评估</span>
                  </div>
                  <div class="flex-col items-center grid-item_1">
                    <img src="@/res/local/abm_gd_02.png" class="image_8" />
                    <span class="text_64">企业标签画像</span>
                  </div>
                  <div class="flex-col items-center grid-item_2">
                    <img src="@/res/local/abm_gd_03.png" class="image_8" />
                    <span class="text_65">企业情报洞察</span>
                  </div>
                  <div class="flex-col items-center grid-item_3">
                    <img src="@/res/local/abm_gd_04.png" class="image_22" />
                    <span class="text_66">线索OneID</span>
                  </div>
                  <div class="flex-col items-center grid-item_4">
                    <img src="@/res/local/abm_gd_05.png" class="image_8" />
                    <span class="text_67">线索行为轨迹</span>
                  </div>
                  <div class="flex-col items-center grid-item_5">
                    <img src="@/res/local/abm_gd_06.png" class="image_8" />
                    <span class="text_68">线索评分模型</span>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="grid">
                  <div class="flex-col items-center grid-item">
                    <img src="@/res/local/abm_gd_07.png" class="image_22" />
                    <span class="text_63">线索归因分析</span>
                  </div>
                  <div class="flex-col items-center grid-item_1">
                    <img src="@/res/local/abm_gd_08.png" class="image_8" />
                    <span class="text_64">直播活动管理</span>
                  </div>
                  <div class="flex-col items-center grid-item_2">
                    <img src="@/res/local/abm_gd_09.png" class="image_8" />
                    <span class="text_65">线下活动管理</span>
                  </div>
                  <div class="flex-col items-center grid-item_3">
                    <img src="@/res/local/abm_gd_10.png" class="image_22" />
                    <span class="text_66">营销任务管理</span>
                  </div>
                  <div class="flex-col items-center grid-item_4">
                    <img src="@/res/local/abm_gd_11.png" class="image_8" />
                    <span class="text_67">营销自动化</span>
                  </div>
                  <div class="flex-col items-center grid-item_5">
                    <img src="@/res/local/abm_gd_12.png" class="image_8" />
                    <span class="text_68">呼叫中心</span>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="grid">
                  <div class="flex-col items-center grid-item">
                    <img src="@/res/local/abm_gd_13.png" class="image_22" />
                    <span class="text_63">外呼机器人</span>
                  </div>
                  <div class="flex-col items-center grid-item_1">
                    <img src="@/res/local/abm_gd_14.png" class="image_8" />
                    <span class="text_64">短信</span>
                  </div>
                  <div class="flex-col items-center grid-item_2">
                    <img src="@/res/local/abm_gd_15.png" class="image_8" />
                    <span class="text_65">邮件</span>
                  </div>
                  <div class="flex-col items-center grid-item_3">
                    <img src="@/res/local/abm_gd_16.png" class="image_22" />
                    <span class="text_66">企业微信</span>
                  </div>
                  <div class="flex-col items-center grid-item_4">
                    <img src="@/res/local/abm_gd_17.png" class="image_8" />
                    <span class="text_67">公众号</span>
                  </div>
                  <div class="flex-col items-center grid-item_5">
                    <img src="@/res/local/abm_gd_18.png" class="image_8" />
                    <span class="text_68">小程序</span>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="grid">
                  <div class="flex-col items-center grid-item">
                    <img src="@/res/local/abm_gd_19.png" class="image_22" />
                    <span class="text_63">信息流广告</span>
                  </div>
                  <div class="flex-col items-center grid-item_1">
                    <img src="@/res/local/abm_gd_20.png" class="image_8" />
                    <span class="text_64">素材管理</span>
                  </div>
                  <div class="flex-col items-center grid-item_2">
                    <img src="@/res/local/abm_gd_21.png" class="image_8" />
                    <span class="text_65">落地页</span>
                  </div>
                  <div class="flex-col items-center grid-item_3">
                    <img src="@/res/local/abm_gd_22.png" class="image_22" />
                    <span class="text_66">自定义表单</span>
                  </div>
                  <div class="flex-col items-center grid-item_4">
                    <img src="@/res/local/abm_gd_23.png" class="image_8" />
                    <span class="text_67">微网站</span>
                  </div>
                  <div class="flex-col items-center grid-item_5">
                    <img src="@/res/local/abm_gd_24.png" class="image_8" />
                    <span class="text_68">CRM集成</span>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="grid">
                  <div class="flex-col items-center grid-item">
                    <img src="@/res/local/abm_gd_25.png" class="image_22" />
                    <span class="text_63">呼叫中心集成</span>
                  </div>
                  <div class="flex-col items-center grid-item_1">
                    <img src="@/res/local/abm_gd_26.png" class="image_8" />
                    <span class="text_64">微信通知</span>
                  </div>
                  <div class="flex-col items-center grid-item_2">
                    <img src="@/res/local/abm_gd_27.png" class="image_8" />
                    <span class="text_65">自定义仪表盘</span>
                  </div>
                  <div class="flex-col items-center grid-item_3">
                    <img src="@/res/local/abm_gd_28.png" class="image_22" />
                    <span class="text_66">渠道归因分析</span>
                  </div>
                  <div class="flex-col items-center grid-item_4">
                    <img src="@/res/local/abm_gd_29.png" class="image_8" />
                    <span class="text_67">匿名访客识别</span>
                  </div>
                  <div class="flex-col items-center grid-item_5">
                    <img src="@/res/local/abm_gd_30.png" class="image_8" />
                    <span class="text_68">微信粉丝识别</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>


        <div class="flex-col group_37">
          <div class="flex-col items-center group_38">
            <span class="text_72">应用场景</span>
          </div>
          <div class="flex-col group_39">
            <div class="flex-col section_7">
              <img src="@/res/local/eb7259e8b7f4f4b352750d960c19a68f.png" class="image_33 image_34" />
              <div class="flex-col group_40">
                <span class="text_73">大规模获客</span>
                <span class="text_74">自动化营销工具可以让大规模、个性化的触达成为可能，可以成规模的进行线索获取</span>
              </div>
            </div>
            <div class="flex-col section_8">
              <img src="@/res/local/6fea22fa13d44daf57f17f173065d058.png" class="image_35" />
              <div class="flex-col group_41">
                <span class="text_75">数字化营销</span>
                <span class="text_76">沉淀企业一方数据，动态生成线索全景画像，构建企业数字化营销资产</span>
              </div>
            </div>
            <div class="flex-col section_9">
              <img src="@/res/local/e7b92be32c10afe589dd8a302cc6057a.png" class="image_33 image_36" />
              <div class="flex-col group_42">
                <span class="text_77">营销活动管理</span>
                <span class="text_78">线上直播、线下活动的全流程管理，大幅提升营销活动管理效率以及效果分析能力</span>
              </div>
            </div>
            <!-- <div class="flex-col section_10">
              <img src="@/res/local/ee76a110c15c6c2b9c91e7de8fb60933.png" class="image_33 image_37" />
              <div class="flex-col group_43">
                <span class="text_79">广告投放监测</span>
                <span class="text_80">整合全部投放渠道，完成从流量到转化的全流程广告效果监测，降低平均获客成本</span>
              </div>
            </div> -->
            <div class="flex-col section_11">
              <img src="@/res/local/5a2275065fa2ee03f51951336dc42086.png" class="image_33 image_38" />
              <div class="flex-col group_44">
                <span class="text_81">私域流量运营</span>
                <span class="text_82">基于火眼云数据能力，全量触达目标人群，将公域流量引进私域流量</span>
              </div>
            </div>
            <div class="flex-col section_12">
              <img src="@/res/local/55caca7f47f63d54a574697c62bca973.png" class="image_33 image_39" />
              <div class="flex-col group_45">
                <span class="text_83">营销自动化</span>
                <span class="text_84">对营销流程进行自动化配置，对不同画像的客户进行自动化、个性化的培育</span>
              </div>
            </div>
            <div class="flex-col section_13">
              <img src="@/res/local/e83672f2ecaa0b109a6e096fea11305d.png" class="image_33 image_40" />
              <div class="flex-col group_46">
                <span class="text_85">ABM策略执行</span>
                <span class="text_86">产品从目标获取、联系人扩展、跨渠道触达、数据分析，完美支持ABM营销策略</span>
              </div>
            </div>
          </div>
          <div class="flex-col group_47">
            <div class="flex-col group_48">
              <span class="text_87">火眼云一站式营销增长产品矩阵</span>
              <span class="text_88">找到目标客户 、发现关键意图 、创新营销体验，并开展爆炸式营销增长</span>
              <div class="flex-row group_49">
                <div class="flex-col items-center group_50">
                  <div class="section_14">
                    <span class="liuguang"></span>
                  </div>
                  <img src="@/res/local/a8e129945b8c9d085b5d3356e13e4bef.png" class="image_41" />
                  <img src="@/res/local/dbc56334b545bcdc59619a25af96c5ad.png" class="image_42" />
                  <img src="@/res/local/548757465bc8230d91f7a254af8da972.png" class="image_43" />
                  <img src="@/res/local/8c13bce909108634edc08da1d14465bd.png" class="image_44" />
                </div>
                <div class="flex-col group_51">
                  <div @click="goOtherPageFun('/abm-marketing-cloud.html')" class="flex-col group_52">
                    <span class="text_89">ABM营销云</span>
                    <span class="text_90">让大规模、个性化执行目标客户营销（ABM）策略成为可能</span>
                  </div>
                  <div @click="goOtherPageFun('/Intent-cloud.html')" class="flex-col group_53">
                    <span class="text_91">情报云</span>
                    <span class="text_92">通过企业情报和行为意图，实时定位对您产品或服务感兴趣的潜在客户</span>
                  </div>
                  <div @click="goOtherPageFun('/cdp.html')" class="flex-col group_54">
                    <span class="text_93">客户数据平台（CDP）</span>
                    <span class="text_94">以OneID技术打通、补充、规范企业多元数据，统一客户视图，实现营销数字化转型</span>
                  </div>
                  <div @click="goOtherPageFun('/ad-cloud.html')" class="flex-col group_55">
                    <span class="text_95">广告云</span>
                    <span class="text_96">进行数字广告的全渠道投放、监测、归因分析以及投放策略优化</span>
                  </div>
                </div>
              </div>
            </div>
            <div @click="goOtherPageFun('/data-cloud.html')" class="flex-row section_15">
              <img src="@/res/local/7afc9facdfff43874d0ffaf4318ef4c3.png" class="image_33 image_45" />
              <div class="flex-col group_56">
                <span class="text_97">数据云（DMP）</span>
                <span class="text_98"
                  >覆盖6000万企业主体及1.6亿职场人群画像，掌握B2B企业情报及意向数据，帮助您识别、理解和吸引理想的目标客户</span
                >
              </div>
            </div>
          </div>
          <!-- <div class="flex-col group_57">
            <div class="flex-col section_16">
              <span class="text_99">留下您的联系方式<br />专属顾问会尽快与您联系</span>
              <div class="bottom-section justify-between section_17">
                <div class="left-group flex-row">
                  <img src="@/res/local/16376631404543724564.png" class="image_6" />
                  <span class="text_5">请输入您的手机号码</span>
                </div>
                <div class="right-text-wrapper flex-col items-center">
                  <span>免费试用</span>
                </div>
              </div>
              <div class="group_58">
                <span class="text_102">如有疑问，欢迎致电客服热线</span>
                <span class="text_103">400-8521-789</span>
              </div>
            </div>
            <div class="flex-col group_59">
              <div class="section_18"></div>
              <div class="justify-between section_19">
                <div class="flex-col group_60">
                  <div class="group_61">
                    <span class="text_104">电话：</span>
                    <span class="text_105">400-8521-789</span>
                    <span class="text_106">邮箱： </span>
                    <span class="text_107">marketing@huoyan.cn</span>
                  </div>
                  <span class="text_108">©2017 北京绘客科技有限公司 <br />京ICP备16023115号-1 隐私政策</span>
                </div>
                <img src="@/res/remote/06c5f76966fbb88f09c7d54f941ea701.png" class="image_47" />
              </div>
              <img src="@/res/local/52ca3200bc469c20b13881eecea2030c.svg" class="image_48" />
            </div>
          </div> -->
          <v-footer></v-footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
import vFooter from '@/components/footer.vue'
import Swiper from 'swiper';
  export default {
    data() {
      return {
        input1:''
      };
    },
        components: {
      vFooter,vHeader
    },
    mounted () {
      var swiper = new Swiper(".mySwiper", {
        pagination: {
          el: ".swiper-pagination",
        },
        loop:true
      });
    },
    methods: {
      goformPageFun(val){
        var mobileReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if(val == 1){
          if(!this.input1){
            window.location.href= '/form.html?mobile='
          } else if(mobileReg.test(this.input1)){
             window.location.href = '/form.html?mobile='+this.input1
          } else {
            alert('手机格式错误!')
          }
        }else{
          window.location.href= '/form.html?mobile='
        }
      },
      goOtherPageFun(path){
        window.location.href = path
      }
    }
  };
</script>

<style scoped lang="css">
/deep/.swiper-pagination-bullet-active{
  background-color: #FF6A00;
}
  .group_36_swiper{
    position: relative;
    overflow: hidden;
    padding-bottom: 3rem;
  }
      .swiper {
        
        width: 100%;
        
      }

      .swiper-slide {

      }
      .wiper-pagination .swiper-pagination-bullet{
        background: #d8d8d8;
        width: 6px;
        height:6px;
      }
      .wiper-pagination .swiper-pagination-bullet-active{
            background: #b1b1b1;
      }
  .image_14 {
    width: 20rem;
    height: 12.5rem;
  }
  .group_11 {
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0 2.63rem;
  }
  .button {
    margin-top: 1.69rem;
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      99.8deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 82.66%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.56rem 0px rgba(255, 65, 65, 0.1);
    border-radius: 0.13rem;
    width: 6.25rem;
  }
  .group_14 {
    padding: 0 0.44rem;
  }
  .group_23 {
    margin-top: 3.25rem;
    padding-left: 0.56rem;
    padding-right: 0.5rem;
  }
  .image_18 {
    align-self: center;
    width: 20.06rem;
    height: 12.5rem;
  }
  .bottom-section {
    position: relative;
    height: 49px;
    /* padding: 0.5rem 0.31rem 0.44rem 0.56rem; */
    filter: drop-shadow(0px 0.88rem 0.66rem #1c25330d);
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    border: solid 0.063rem rgb(222, 222, 222);
  }
  .bottom-section input{
     width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 10px;
    padding-left:2rem;
   }
  .image_7_1{
    position: absolute;
    top: 0.7rem;
    left:.3125rem
  }
  .section_5 {
    margin-top: 1rem;
    padding: 1rem 1.5rem 1.31rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 1.13rem 0px rgb(243, 244, 246);
    border-radius: 0.25rem;
  }
  .left-section {
    align-self: center;
    background-color: rgb(212, 212, 212);
    border-radius: 50%;
    width: 0.38rem;
    height: 0.38rem;
  }
  .image_22 {
    width: 3rem;
    height: 3rem;
  }
  .image_8 {
    width: 3rem;
    height: 3rem;
  }
  .right-text-wrapper {
    position: absolute;
   right:.625rem;
    top: 0.4rem;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      102.2deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 81.34%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(255, 65, 65, 0.5);
    border-radius: 0.25rem;
    width: 5rem;
    height: 2rem;
  }
  .image_33 {
    width: 5rem;
    height: 5rem;
  }
  .image_6 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .text_27 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_41 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_43 {
    margin-top: 1.25rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: center;
  }
  .text_14 {
    color: rgb(51, 51, 51);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .bottom-group {
    margin-top: 1.25rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
  }
  .left-group {
    margin: 0.19rem 0 0.31rem;
    color: rgb(199, 199, 199);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .left {
    margin-top: 0.5rem;
  }
  .right-group {
    margin-left: 1rem;
    margin-right: 0.19rem;
    flex: 1 1 auto;
  }
  .text_29 {
    margin-left: 0.13rem;
    text-align: center;
  }
  .text_5 {
    margin-left: 0.81rem;
    margin-top: 0.19rem;
  }
  .text_16 {
    margin-top: 0.63rem;
    color: rgb(35, 35, 35);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .container {
    padding-top:3.5rem;
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
  }
  .header {
    height: 6.38rem;
  }
  .group_3 {
    padding-top: 0.19rem;
    flex: 1 1 auto;
    overflow-y: auto;
  }
  .group {
    padding: 0.84rem 0.88rem 0.72rem 2.19rem;
    color: rgb(0, 0, 0);
    font-size: 0.94rem;
    line-height: 1.13rem;
    letter-spacing: -0.019rem;
    white-space: nowrap;
  }
  .section_1 {
    padding: 0.88rem 1rem 0.81rem 1.09rem;
    color: rgb(255, 106, 0);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(0, 0, 0, 0.03);
  }
  .group_36 {
    margin-top: 2.06rem;
  }
  .group_1 {
    margin: 0.19rem 0 0.22rem;
  }
  .group_2 {
    align-self: center;
  }
  .text-wrapper {
    padding: 0.44rem 0;
    background-color: rgba(255, 106, 0, 0.05);
    border-radius: 1rem;
    width: 5rem;
    height: 2rem;
  }
  .section_2 {
    padding: 2.06rem 1.19rem 1.63rem;
    background-image: linear-gradient(
      180deg,
      rgb(250, 250, 251) 0%,
      rgb(250, 250, 251) 0%,
      rgb(243, 244, 246) 100%,
      rgb(243, 244, 246) 100%
    );
    overflow: hidden;
    height: 29.38rem;
  }
  .group_7 {
    margin-top: 2.5rem;
    padding-left: 1.25rem;
    padding-right: 1rem;
  }
  .grid {
    margin-left: 0.56rem;
    margin-right: 0.72rem;
    color: rgb(89, 89, 89);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.06rem;
    white-space: nowrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 0.66rem;
    grid-column-gap: 0.66rem;
  }
  .group_37 {
    margin-top: 1.03rem;
  }
  .image {
    width: 1.06rem;
    height: 0.69rem;
  }
  .image_1 {
    margin-left: 0.28rem;
    width: 1rem;
    height: 0.69rem;
  }
  .image_2 {
    margin-left: 0.28rem;
    width: 1.53rem;
    height: 0.72rem;
  }
  .image_3 {
    align-self: center;
    width: 0.81rem;
    height: 0.69rem;
  }
  .image_4 {
    margin-left: 1.59rem;
    width: 4.94rem;
    height: 1.5rem;
  }
  .image_5 {
    margin-top: 2.31rem;
    align-self: center;
    width: 15rem;
    height: 9.38rem;
  }
  .group_6 {
    margin-top: 2.44rem;
  }
  .text_8 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_9 {
    margin-top: 1rem;
    align-self: center;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .group_8 {
    margin-top: 2rem;
  }
  .text_21 {
    margin-top: 2.5rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_22 {
    margin: 1.25rem 0.5rem 0 0.56rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: center;
  }
  .image_15 {
    margin-top: 1.75rem;
    align-self: center;
  }
  .view_7 {
    margin-top: 1.69rem;
  }
  .view_9 {
    margin-top: 1.38rem;
  }
  .text-wrapper_1 {
    margin-top: 1.94rem;
  }
  .view_12 {
    margin-top: 3rem;
  }
  .group_15 {
    margin-top: 1.81rem;
    padding: 0 2.44rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .view_17 {
    margin-top: 4.25rem;
  }
  .group_19 {
    margin-top: 1.75rem;
    padding: 0 1.63rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .image_19 {
    margin-top: 1.75rem;
  }
  .group_24 {
    margin-top: 1.69rem;
    padding: 0 1.81rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .image_20 {
    margin-top: 2.31rem;
    align-self: center;
  }
  .group_28 {
    margin-top: 2rem;
    padding: 0 1.06rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .image_21 {
    margin-top: 1.88rem;
  }
  .group_32 {
    margin-top: 2.44rem;
    padding: 0 2.94rem;
    color: rgb(89, 89, 89);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .view_35 {
    margin-top: 3.75rem;
  }
  .grid-item {
    padding: 1.28rem 0 0.84rem;
    background-color: rgb(249, 250, 252);
    border-radius: 0.063rem;
  }
  .grid-item_1 {
    padding: 1.28rem 0 0.84rem;
    background-color: rgb(249, 250, 252);
    border-radius: 0.063rem;
  }
  .grid-item_2 {
    padding: 1.28rem 0 0.84rem;
    background-color: rgb(249, 250, 252);
    border-radius: 0.063rem;
  }
  .grid-item_3 {
    padding: 1.28rem 0 0.84rem;
    background-color: rgb(249, 250, 252);
    border-radius: 0.063rem;
  }
  .grid-item_4 {
    padding: 1.28rem 0 0.84rem;
    background-color: rgb(249, 250, 252);
    border-radius: 0.063rem;
  }
  .grid-item_5 {
    padding: 1.28rem 0 0.84rem;
    background-color: rgb(249, 250, 252);
    border-radius: 0.063rem;
  }
  .grid-item_6 {
    padding: 1.28rem 0 0.84rem;
    background-color: rgb(249, 250, 252);
    border-radius: 0.063rem;
  }
  .grid-item_7 {
    padding: 1.31rem 0 0.84rem;
    background-color: rgb(249, 250, 252);
    border-radius: 0.063rem;
  }
  .grid-item_8 {
    padding: 1.31rem 0 0.84rem;
    background-color: rgb(249, 250, 252);
    border-radius: 0.063rem;
  }
  .group_38 {
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_39 {
    margin-top: 1.88rem;
  }
  .group_47 {
    margin-top: 3rem;
    height: 52.25rem;
  }
  .group_57 {
    margin-top: 3.13rem;
  }
  .text_2 {
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_3 {
    margin-top: 0.38rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_4 {
    margin-right: 0.56rem;
    color: rgb(61, 61, 61);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .view {
    margin: .625rem 0.5rem 0 0.56rem;
  }
  .section_3 {
    padding: 1.19rem 1.5rem 1.06rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 1.13rem 0px rgb(243, 244, 246);
    border-radius: 0.25rem;
  }
  .section_4 {
    margin-top: 1rem;
    padding: 0.81rem 1.5rem 1.31rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 1.13rem 0px rgb(243, 244, 246);
    border-radius: 0.25rem;
  }
  .section_6 {
    margin-top: 1rem;
    padding: 1rem 1.5rem 4.25rem;
    color: rgb(51, 51, 51);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 1.13rem 0px rgb(243, 244, 246);
    border-radius: 0.25rem;
  }
  .text_23 {
    margin-left: 0.63rem;
  }
  .group_13 {
    margin-top: 1.38rem;
  }
  .group_17 {
    margin-top: 1.38rem;
  }
  .group_18 {
    margin-top: 1.38rem;
  }
  .group_21 {
    margin-top: 1.38rem;
  }
  .group_22 {
    margin-top: 1.38rem;
  }
  .group_26 {
    margin-top: 1.38rem;
  }
  .group_27 {
    margin-top: 1.38rem;
  }
  .group_30 {
    margin-top: 1.38rem;
  }
  .group_31 {
    margin-top: 1.38rem;
  }
  .group_34 {
    margin-top: 1.38rem;
  }
  .group_35 {
    margin-top: 1.38rem;
  }
  .text_62 {
    margin-top: 1.06rem;
  }
  .text_63 {
    margin-top: 0.78rem;
  }
  .text_64 {
    margin-top: 0.78rem;
  }
  .text_65 {
    margin-top: 0.78rem;
  }
  .text_66 {
    margin-top: 0.78rem;
  }
  .text_67 {
    margin-top: 0.78rem;
  }
  .text_68 {
    margin-top: 0.78rem;
  }
  .text_69 {
    margin-top: 0.78rem;
  }
  .text_70 {
    margin-top: 0.75rem;
  }
  .text_71 {
    margin-top: 0.75rem;
  }
  .image_32 {
    width: 3rem;
    height: 0.38rem;
  }
  .text_72 {
    margin-top: 1.75rem;
  }
  .section_7 {
    padding: 1.13rem 0.75rem 1.81rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
    border-top: solid 0.26rem rgb(245, 245, 245);
  }
  .section_8 {
    padding: 1.5rem 0.75rem 2.19rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_9 {
    padding: 1.31rem 0.75rem 1.69rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.25rem;
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_10 {
    padding: 1.13rem 0.75rem 1.53rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_11 {
    padding: 1.31rem 0.75rem 1.94rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_12 {
    padding: 1.25rem 1.19rem 1.88rem 1.44rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
  }
  .section_13 {
    padding: 1.25rem 0.75rem 2rem 1.06rem;
    background-color: rgb(255, 255, 255);
    border: solid 0.13rem rgb(245, 245, 245);
    border-bottom: solid 0.26rem rgb(245, 245, 245);
  }
  .group_48 {
    padding-left: 1.31rem;
    padding-right: 1.25rem;
  }
  .section_15 {
    margin-top: -0.44rem;
    padding: 2.19rem 1.25rem 2rem 1.31rem;
    background-image: linear-gradient(
      90deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.29%,
      rgb(255, 181, 129) 148.28%,
      rgb(255, 181, 129) 100%
    );
    position: relative;
  }
  .section_16 {
    padding: 1.94rem 1.69rem 1.31rem 1.75rem;
    background-color: rgb(249, 250, 252);
  }
  .group_59 {
    padding-bottom: 14.13rem;
    position: relative;
  }
  .group_9 {
    margin-left: 1rem;
    margin-right: 0.19rem;
    flex: 1 1 auto;
  }
  .group_10 {
    margin-left: 1rem;
    margin-top: 0.19rem;
    flex: 1 1 auto;
  }
  .image_13 {
    margin-top: 0.19rem;
  }
  .text_20 {
    margin-left: 1rem;
  }
  .text_24 {
    margin-left: 0.63rem;
    margin-right: 0.31rem;
  }
  .text_25 {
    margin-left: 0.63rem;
  }
  .image_16 {
    margin-right: 0.31rem;
    margin-top: 1.63rem;
  }
  .text_31 {
    margin-left: 0.63rem;
    margin-right: 0.69rem;
  }
  .text_32 {
    margin-left: 0.63rem;
  }
  .text_33 {
    margin-left: 0.63rem;
    margin-right: 0.69rem;
  }
  .image_17 {
    margin-right: 0.31rem;
    margin-top: 1.69rem;
  }
  .text_37 {
    margin-left: 0.63rem;
    margin-right: 1.5rem;
  }
  .text_38 {
    margin-left: 0.63rem;
  }
  .text_39 {
    margin-left: 0.63rem;
  }
  .text_45 {
    margin-left: 0.63rem;
    margin-right: 0.31rem;
  }
  .text_46 {
    margin-left: 0.63rem;
  }
  .text_47 {
    margin-left: 0.63rem;
    margin-right: 1.13rem;
  }
  .text_51 {
    margin-left: 0.63rem;
  }
  .text_52 {
    margin-left: 0.63rem;
    margin-right: 1.94rem;
  }
  .text_53 {
    margin-left: 0.63rem;
    margin-right: 2.63rem;
  }
  .text_57 {
    margin-left: 0.63rem;
    margin-right: 2.13rem;
  }
  .text_58 {
    margin-left: 0.63rem;
    margin-right: 0.5rem;
  }
  .text_59 {
    margin-left: 0.63rem;
  }
  .image_34 {
    align-self: center;
  }
  .group_40 {
    margin-top: 1rem;
  }
  .image_35 {
    align-self: center;
    width: 5rem;
    height: 4.81rem;
  }
  .group_41 {
    margin-top: 0.81rem;
  }
  .image_36 {
    align-self: center;
  }
  .group_42 {
    margin-top: 0.81rem;
  }
  .image_37 {
    align-self: center;
  }
  .group_43 {
    margin-top: 1.03rem;
  }
  .image_38 {
    align-self: center;
  }
  .group_44 {
    margin-top: 0.94rem;
  }
  .image_39 {
    align-self: center;
  }
  .group_45 {
    margin-top: 1.06rem;
  }
  .image_40 {
    align-self: center;
  }
  .group_46 {
    margin-top: 1.06rem;
  }
  .text_87 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_88 {
    margin: 1.25rem 0.44rem 0 0.56rem;
    color: rgb(61, 61, 61);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: center;
  }
  .group_49 {
    margin-top: 3.06rem;
  }
  .image_45 {
    align-self: center;
  }
  .group_56 {
    margin-left: 1.25rem;
    flex: 1 1 auto;
  }
  .text_99 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.13rem;
    font-weight: 500;
    line-height: 2.13rem;
    text-align: center;
    width: 15.38rem;
  }
  .section_17 {
    margin-top: 0.88rem;
  }
  .group_58 {
    margin-top: 1.13rem;
    align-self: center;
    text-align: center;
    width: 11.38rem;
  }
  .section_18 {
    background-color: rgb(31, 41, 55);
    height: 2.06rem;
  }
  .section_19 {
    padding: 3.25rem 1.06rem 2rem 1.44rem;
    background-color: rgb(31, 41, 55);
    position: absolute;
    left: 0;
    right: 0;
    top: 2.06rem;
  }
  .image_48 {
    width: 7.25rem;
    height: 2.25rem;
    position: absolute;
    left: 1.44rem;
    top: 1.44rem;
  }
  .text_10 {
    color: rgb(51, 51, 51);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_11 {
    margin-top: 0.69rem;
    color: rgb(35, 35, 35);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_12 {
    color: rgb(51, 51, 51);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_13 {
    margin-top: 0.63rem;
    color: rgb(35, 35, 35);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_73 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_74 {
    margin-right: 0.25rem;
    margin-top: 0.94rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  .text_75 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_76 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  .text_77 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_78 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: center;
  }
  .text_79 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_80 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: center;
  }
  .text_81 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_82 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  .text_83 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_84 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  .text_85 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_86 {
    margin-right: 0.25rem;
    margin-top: 0.69rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  .group_50 {
    padding-top: 4.56rem;
    width: 5rem;
    position: relative;
  }
  .group_51 {
    margin-left: 1.25rem;
    margin-top: 0.31rem;
    flex: 1 1 auto;
  }
  .text_97 {
    color: rgb(255, 255, 255);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_98 {
    margin-top: 0.38rem;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_102 {
    color: rgb(137, 137, 137);
    font-size: 0.88rem;
    line-height: 1.5rem;
  }
  .text_103 {
    color: rgb(255, 106, 0);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.38rem;
  }
  .group_60 {
    width: 12.44rem;
  }
  .image_47 {
    margin-top: 2.63rem;
    width: 6.25rem;
    height: 6.25rem;
  }
  .section_14 {
    
    background-image: linear-gradient(
      180.1deg,
      rgb(255, 211, 134) 0%,
      rgb(255, 211, 134) -38.14%,
      rgb(255, 239, 187) 118.97%,
      rgb(255, 239, 187) 100%
    );
    width: 0.38rem;
    height: 29.19rem;
    position: relative;
    overflow: hidden;
  }
  .liuguang{
    position: absolute;
    bottom:0rem;
    left:0;
    width: 0.38rem;
    height: 20rem;
    background:#f5bf62;
    animation: animate1 1s linear infinite;
  }
  @keyframes animate1 {
      0% {
          bottom: -100%;
      }
      50%,
      100% {
          bottom: 100%;
      }
  }
  .image_41 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .image_42 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 8.13rem;
  }
  .image_43 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 16.25rem;
  }
  .image_44 {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 24.38rem;
  }
  .group_53 {
    margin-top: 3.81rem;
  }
  .group_54 {
    margin-top: 3.81rem;
  }
  .group_55 {
    margin-top: 3.81rem;
  }
  .group_61 {
    text-align: left;
    width: 11.56rem;
  }
  .text_108 {
    margin-top: 2.19rem;
    color: rgb(140, 140, 140);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: left;
  }
  .text_89 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_90 {
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    width: 13rem;
  }
  .text_91 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_92 {
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_93 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_94 {
    margin-right: 0.81rem;
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_95 {
    color: rgb(38, 38, 38);
    font-size: 0.94rem;
    font-weight: 500;
    line-height: 1.31rem;
    white-space: nowrap;
  }
  .text_96 {
    margin-top: 0.38rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_104 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 2.19rem;
  }
  .text_105 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 2.19rem;
  }
  .text_106 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 1.25rem;
  }
  .text_107 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 1.25rem;
  }
</style>