<template>
  <div class="flex-col container">
    <v-header></v-header>
    <div class="kehuanli">
        <div class="top">
            <div class="title">助力企业构建可衡量的营销增长</div>
            <div class="content">精选行业实践案例</div>
            <img class="banner" src="@/res/kehuanli/banner.png" alt="">
            <div class="formBtn">
              <img src="@/res/local/16376631404543724564.png" class="phoneIcon" />
              <input v-model="input1" class="ipt" type="text" placeholder="请输入您的手机号码">
              <div @click="goformPageFun(1)" class="btn">
                <span>预约演示</span>
              </div>
            </div>
        </div>
        <div class="list">
            
        </div>
    </div>
    <v-footer></v-footer>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
import vFooter from '@/components/footer.vue'
  export default {
    data() {
      return {
        input1:''
      };
    },
        components: {
      vFooter,vHeader
    },
    mounted () {

    },
    methods: {
      goformPageFun(val){
        var mobileReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if(val == 1){
          if(!this.input1){
            window.location.href= '/form.html?mobile='
          } else if(mobileReg.test(this.input1)){
             window.location.href = '/form.html?mobile='+this.input1
          } else {
            alert('手机格式错误!')
          }
        }else{
          window.location.href= '/form.html?mobile='
        }
      },
      goOtherPageFun(path){
        window.location.href = path
      }
    }
  };
</script>

<style scoped lang="css">
  .kehuanli{
      padding-top:3.75rem

  }
 .kehuanli .top{
     padding:2.0625rem 0 2.5rem 0;
     text-align: center;
     background: linear-gradient(180deg, #FAFAFB 0%, #F3F4F6 100%);
 }
 .kehuanli .top .title{
    font-size: 1.25rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #232323;
 }
  .kehuanli .top .content{
    margin:.375rem 0 .75rem 0;
    font-size: .875rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3D3D3D;
 }
  .kehuanli .top .banner{
      width: 18.125rem;
 }
  .formBtn{
    position: relative;
    margin: 0 auto;
    width: 20rem;
    height: 3.0625rem;
    margin-top:1.25rem;
    background: #FFFFFF;
    box-shadow: 0px 14px 21px 0px rgba(28, 37, 51, 0.05);
    border-radius: 8px;
    border: 1px solid #DEDEDE;
  }
  .formBtn .ipt{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 10px;
    padding-left: 2rem;
  }
  .formBtn .phoneIcon{
    width: 1.5rem;
    position: absolute;
    top: 0.7rem;
    left: 0.3rem;
  }
  .formBtn .btn{
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 5rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(118deg, #FF6A00 0%, #FF6A00 100%);
    box-shadow: 0px 14px 21px 0px rgba(28, 37, 51, 0.05), 0px 2px 4px 0px rgba(255, 65, 65, 0.5);
    border-radius: 4px;
  }
  .formBtn .btn span{
    font-size: .8125rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
 .bottom-section {
    position: relative;
    height: 49px;
    /* padding: 0.5rem 0.31rem 0.44rem 0.56rem; */
    filter: drop-shadow(0px 0.88rem 0.66rem #1c25330d);
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    border: solid 0.063rem rgb(222, 222, 222);
  }
  .bottom-section input{
     width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 10px;
    padding-left:2rem;
   }
</style>