<template>
  <div class="flex-col container">
    <v-header></v-header>
    <div class="flex-col group_3" id="pageId">
      <div class="flex-col group_4">
        <div class="flex-col group_5">
          <div class="flex-col group_6">
            <div class="flex-col group_7">
              <span class="text_2">工业制造行业 ABM营销方案</span>
              <span class="text_3"
                >精准连接客户，强化内部协同<br />加快制造企业数字化转型进程</span
              >
            </div>
            <img src="@/res/img0326/banner_pic1@2x.png" class="image_5" />
            <div class="btn_group">
              <div @click="goformPageFun('/form.html?utm_source=industry-demo')" class="flex-col items-center button">
                <span>定制您的专属方案</span>
              </div>
              <div @click="goOtherPageFun('https://marketing.huoyanio.com/gong-ye-zhi-zao-xing-ye-bai-pi-shu-')" class="secbtn flex-col items-center button">
                <span>获取制造业增长白皮书</span>
              </div>
            </div>
            <span class="text_5">行业营销痛点</span>
            <div class="flex-col list">
              <div class="list-item flex-row">
                <img src="@/res/img0326/jjfa_increase_Improve_icon@2x.png" class="image_6" />
                <div class="right-group flex-col">
                  <span class="text_6">业务增长需求急迫</span>
                  <span class="text_8"
                    >制造企业面临多个业务线的增长需求，希望通过营销带来更多流量，从而获取精准线索和商机。<br
                  /></span>
                </div>
              </div>
              <div class="list-item flex-row">
                <img src="@/res/img0326/jjfa_increase_Improve_icon@2x (1).png" class="image_6" />
                <div class="right-group flex-col">
                  <span class="text_6">内部缺乏高效协同</span>
                  <span class="text_8"
                    >企业内部缺乏营销和销售协同的整体规划，多个业务系统未打通，导致数据流转不畅通，信息不同步。<br
                  /></span>
                </div>
              </div>
              <div class="list-item flex-row">
                <img src="@/res/img0326/jjfa_increase_Improve_icon@2x (2).png" class="image_6" />
                <div class="right-group flex-col">
                  <span class="text_6">获客模式传统单一</span>
                  <span class="text_8"
                    >多数企业还停留在传统营销模式，缺乏与目标客户有效连接的方式，难以快速拓展获客通路。<br
                  /></span>
                </div>
              </div>
              <div class="list-item flex-row">
                <img src="@/res/img0326/jjfa_increase_Improve_icon@2x (3).png" class="image_6" />
                <div class="right-group flex-col">
                  <span class="text_6">营销效果难以评估</span>
                  <span class="text_8"
                    >在传统营销模式下，营销活动的效果难以准确评估，从而市场部门的价值难以体现和量化。<br
                  /></span>
                </div>
              </div>
            </div>
            <div class="flex-col items-center group_8">
              <span class="text_14">火眼云ABM营销自动化解决方案全面带动</span>
              <span class="text_14">工业制造行业营销增长</span>
            </div>
            <div class="screen1-box">
              <canvas id="canvas1"></canvas>
              <canvas id="canvas2"></canvas>
              <canvas id="canvas3"></canvas>
            </div>
          </div>
            <div class="list-item_1 flex-col">
              <div class="flex-col items-center" style="margin-bottom:1.75rem">
                <span class="text_15">精准定位目标人群，营销效果翻倍</span>
              </div>
              <div class="group_10 flex-col">
                <img style="height: auto;" src="@/res/img0326/gyzzy_01.png" class="image_10" />
                <div class="flex-row group_11">
                  <div class="section_5"></div>
                  <span class="text_16">扩充营销数据资产</span>
                </div>
                <span class="text_17">火眼数据云覆盖6000万+企业和1.6亿职场人群画像，多维度筛选目标企业，锁定关键客群；</span>
              </div>
              <div class="group_12 flex-col">
                <div class="flex-row group_13">
                  <div class="section_6"></div>
                  <span class="text_18">深度挖掘潜客意向</span>
                </div>
                <span class="text_19">支持从职场画像和意向行为出发，持续分析并定向挖掘公众号、APP、小程序、网站等垂直平台人群，从行为意图情报数据中挖掘潜客；</span>
              </div>
              <div class="group_14 flex-col">
                <div class="top-group flex-row">
                  <div class="left-section"></div>
                  <span class="text_20">线上线下活动触达</span>
                </div>
                <span class="text_22">ABM营销的全渠道触达能力支持企业与目标联系人迅速建立连接，丰富线上线下营销活动场景，增进与潜客之间的互动。</span>
              </div>
              <div @click="goformPageFun('/form.html?utm_source=industry-demo')" class="button_1 flex-col items-center">
                <span>立即体验</span>
              </div>
            </div>
            <div class="list-item_1 flex-col">
              <div class="flex-col items-center" style="margin-bottom:1.75rem">
                <span class="text_15">内容在线分发，赋能客户及代理商</span>
              </div>
              <div class="group_10 flex-col">
                <img style="height: auto;" src="@/res/img0326/gyzzy_02.png" class="image_10" />
                <div class="flex-row group_11">
                  <div class="section_5"></div>
                  <span class="text_16">海量内容线上化</span>
                </div>
                <span class="text_17">营销渠道整合具备内容模块，将多种内容资源整合至线上，形成资源知识库。</span>
              </div>
              <div class="group_12 flex-col">
                <div class="flex-row group_13">
                  <div class="section_6"></div>
                  <span class="text_18">多渠道内容分发</span>
                </div>
                <span class="text_19">筛选并标记目标人群，包含客户、渠道商或代理商等，利用广告、邮件、短信、微信等多种触达形式，个性化+自动化传播内容</span>
              </div>
              <div class="group_14 flex-col">
                <div class="top-group flex-row">
                  <div class="left-section"></div>
                  <span class="text_20">客户旅程完整呈现</span>
                </div>
                <span class="text_22">将同一客户在各渠道的行为完整记录，标记来源及动态分组、匹配分值等，将内容营销的作用发挥到最大化。</span>
              </div>
              <div @click="goformPageFun('/form.html?utm_source=industry-demo')" class="button_1 flex-col items-center">
                <span>立即体验</span>
              </div>
            </div>
            <div class="list-item_1 flex-col">
              <div class="flex-col items-center" style="margin-bottom:1.75rem">
                <span class="text_15">增进内部协同，促进线索快速转化</span>
              </div>
              <div class="group_10 flex-col">
                <img style="height: auto;" src="@/res/img0326/gyzzy_03.png" class="image_10" />
                <div class="flex-row group_11">
                  <div class="section_5"></div>
                  <span class="text_16">营销线索无缝流转</span>
                </div>
                <span class="text_17">营销线索无缝流转至业务团队，无需系统间繁琐对接，利用系统内置的呼叫中心直接外呼，保障线索跟进的时效性；</span>
              </div>
              <div class="group_12 flex-col">
                <div class="flex-row group_13">
                  <div class="section_6"></div>
                  <span class="text_18">线索状态实时更新</span>
                </div>
                <span class="text_19">实现线索客户视图状态实时同步、保留电话录音、电销任务转化实时分析等功能，降低系统对接成本，提升内部效率，保障高质量线索稳定输出。</span>
              </div>
              <div @click="goformPageFun('/form.html?utm_source=industry-demo')" class="button_1 flex-col items-center">
                <span>立即体验</span>
              </div>
            </div>
            <div class="list-item_1 flex-col">
              <div class="flex-col items-center" style="margin-bottom:1.75rem">
                <span class="text_15">《火眼云制造行业营销增长白皮书》</span>
              </div>
              <div class="group_10 flex-col">
                <img style="height: auto;" src="@/res/img0326/gyzzy_04.png" class="image_10" />
                <div class="flex-row group_11">
                  <div class="section_5"></div>
                  <span class="text_16">ABM营销自动化实践，针对制造行业增长需求，结合众多知名企业实践案例打造</span>
                </div>
              </div>
              <div @click="goOtherPageFun('https://marketing.huoyanio.com/gong-ye-zhi-zao-xing-ye-bai-pi-shu-')" class="button_1 flex-col items-center">
                <span>免费下载</span>
              </div>
            </div>
        </div>

        <div class="flex-col section_9">
          <span class="text_57">成功案例</span>
            <div class="mySwiperPar">
              <div class="swiper mySwiper mySwiper1">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="flex-col group_28">
                      <div class="flex-row section_10">
                        <span>
<img src="@/res/img0326/logo_honey@2x.png" class="image_15" />
                        </span>
                        
                        <img src="@/res/local/994a00cf5c8f903da4989e3b6874619d.png" class="image_16" />
                        <span class="text_58 text_59"
                          >霍尼韦尔是走在数字营销前沿的五百强制造企业，已经拥有多年成熟的营销实践，与火眼云建立合作后，火眼云利用自身的数据能力，为霍尼韦尔快速扩充营销数据池，通过职场定向建模方式，建立符合目标客户职场画像和高度意向行为等不同的建模逻辑，短时间获取到超过十万的动态数据，并匹配一系列营销活动进行激活和触达，为全年的营销增长奠定充实的数据基座。</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="flex-col group_28">
                      <div class="flex-row section_10">
                        <span>
<img src="@/res/img0326/longxin_logo@2x.png" class="image_15 image_15_new" />
                        </span>
                        
                        <img src="@/res/local/994a00cf5c8f903da4989e3b6874619d.png" class="image_16" />
                        <span class="text_58 text_59"
                          >作为国产CPU的引领者，龙芯中科自身数字化转型的步伐稳健笃定，引进火眼云为其助力，在大型营销事件利用火眼云全渠道获客功能，在会前预热和会后传播上，广泛应用广告投放模式触达目标受众，夯实品牌影响力，并利用火眼DMP精准圈定目标企业，集中触达，在精准的同时降低投放成本，并最终为业务部门提供诸多线索。</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>
            </div>
          <div class="flex-col group_29">
            <span class="text_60">他们也在用</span>
            <div class="flex-row group_30">
              <div class="grid">
                <div class="flex-col items-center grid-item">
                  <img src="@/res/img0326/hangye_logo_01.png" class="image_18" />
                </div>
                <div class="grid-item_1 flex-col items-center">
                  <img src="@/res/img0326/hangye_logo_02.png" class="image_18" />
                </div>
                <div class="grid-item_1 flex-col items-center">
                  <img src="@/res/img0326/hangye_logo_04.png" class="image_18" />
                </div>
                <div class="flex-col items-center grid-item_2">
                  <img src="@/res/img0326/hangye_logo_05.png" class="image_18" />
                </div>
              </div>
              <div class="flex-col items-center image-wrapper_2">
                <img src="@/res/img0326/hangye_logo_03.png" class="image_18" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="text_61">了解更多ABM营销自动化方案</span>
        <div class="mySwiperPar1"> 
          <div class="swiper mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div @click="goOtherPageFun('/abm.html')" class="flex-col group_31">
                  <div class="flex-col items-center image-wrapper_3">
                    <img src="@/res/img0326/fangan_01.png" class="image_24" />
                  </div>
                  <span class="text_62">软件SaaS行业</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- <div class="flex-col group_32">
        <div class="flex-col section_11">
          <span class="text_63">留下您的联系方式<br />专属顾问会尽快与您联系</span>
          <div class="justify-between search">
            <div class="flex-row group_33">
              <img src="@/res/img0326/16376631404543724564.png" class="image_25" />
              <span class="text_64">请输入您的手机号码</span>
            </div>
            <div class="flex-col items-center text-wrapper_6">
              <span>免费试用</span>
            </div>
          </div>
          <div class="group_34">
            <span class="text_66">如有疑问，欢迎致电客服热线</span>
            <span class="text_67">400-8521-789</span>
          </div>
        </div>
        <div class="text_58 flex-col view_28">
          <div class="section_12"></div>
          <div class="justify-between section_13">
            <div class="flex-col group_35">
              <div class="group_36">
                <span class="text_68">电话：</span>
                <span class="text_69">400-8521-789</span>
                <span class="text_70">邮箱： </span>
                <span class="text_71">marketing@huoyan.cn</span>
              </div>
              <span class="text_72">©2017 北京绘客科技有限公司 <br />京ICP备16023115号-1 隐私政策</span>
            </div>
            <img src="@/res/remote/06c5f76966fbb88f09c7d54f941ea701.png" class="image_26" />
          </div>
          <img src="@/res/img0326/52ca3200bc469c20b13881eecea2030c.svg" class="image_27" />
        </div>
      </div> -->
      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vHeader from '@/components/header.vue'
import vFooter from '@/components/footer.vue'
import Swiper from 'swiper';
  export default {
    data() {
      return { listSlpcNcHA: [null, null, null], listR1QBWj3r: [null, null, null] };
    },
        components: {
      vFooter,vHeader
    },
    mounted(){
      var swiper = new Swiper(".mySwiper1", {
        pagination: {
          el: ".swiper-pagination",
        },
        loop:true
      });
      var swiper1 = new Swiper('.mySwiper2', {
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
      });
      this.$nextTick(()=>{
          var pageId = $("#pageId")[0]
            this.getjqueryFun()
            var refreshFlag = 0;
            let _this = this
            window.addEventListener('scroll', function() {
              
                var t = pageId.pageYOffset ||
                    pageId.scrollTop ||
                    pageId.scrollTop ||
                    0;
                var height = 580;
                if (t > height && refreshFlag == 0) {
                    refreshFlag = 1;
                    _this.refresh();
                }
            },true)
      })
    },
    methods: {
      goformPageFun(val){
        if(val==1){
          window.location.href='/form.html?mobile='+this.input1
        }else{
          window.location.href='/form.html?mobile='
        }
      },
      goOtherPageFun(path){
        window.location.href = path
      },
      refresh(){
            $("#canvas1").dashboard(60, {
                "text": "广告点击率增加",
                "scoreSuffix": "%",
                "angle": 90
            });
            $("#canvas2").dashboard(25, {
                "text": "新线索获取增加",
                "scoreSuffix": "%",
                "angle": 90
            });
            $("#canvas3").dashboard(30, {
                "text": "企业成单率增加",
                "scoreSuffix": "%",
                "angle": 90
            });
      },
      getjqueryFun(){
        (function($) {
            const flush = function(score, option) {
                let preScore = this.data("preScore") ? this.data("preScore") : 0;
                let radius = option.radius;
                let angle = option.angle;

                let canvas = this[0];
                let ctx = canvas.getContext('2d');
                let width = radius * 2 + option.progresslineWidth * 2;
                let addHeight = Math.sin(angle * 2 * Math.PI / 360) * (radius + option.progresslineWidth);
                let height = radius + addHeight + option.progresslineWidth;
                this.attr("width", width);
                this.attr("height", height);
                let addPI = angle * Math.PI / 180;
                ctx.translate(width / 2, height - addHeight);

                function draw(flag) {
                    ctx.save();
                    ctx.clearRect(-width / 2, -height, width, height + addHeight);
                    ctx.beginPath();
                    ctx.strokeStyle = option.progresslineColor;
                    ctx.lineWidth = option.progresslineWidth;
                    ctx.arc(0, 0, radius, Math.PI - addPI, 2 * Math.PI + addPI);
                    ctx.stroke();

                    let stepRadian = (Math.PI + addPI * 2) / option.max;
                    ctx.beginPath();
                    ctx.strokeStyle = option.progressColor(preScore);
                    let tempScore = preScore > option.max ? option.max : preScore;
                    ctx.arc(0, 0, radius, 135 + Math.PI - addPI, 135 + stepRadian * tempScore - addPI + Math.PI);
                    ctx.stroke();

                    ctx.beginPath();
                    ctx.font = "40px PingFangSC-Regular";
                    ctx.textAlign = "center";
                    ctx.fillText(option.text, 0, -20 + addHeight * 0.5)
                    ctx.stroke();
                    ctx.beginPath();
                    ctx.font = "110px PingFangSC-Regular";
                    ctx.textAlign = "center";
                    ctx.fillText(preScore + option.scoreSuffix, 0, -height / 2 + 30 + addHeight * 0.8);
                    ctx.stroke();

                    ctx.beginPath();
                    option.draw(ctx, preScore);
                    ctx.stroke();

                    if (flag && preScore < score) {
                        preScore += option.step;
                        preScore = preScore > score ? score : preScore
                        setTimeout(function() {
                            draw(true)
                        }, 20);
                    }
                    if (!flag && preScore > score) {
                        preScore -= option.step;
                        preScore = preScore < score ? score : preScore
                        setTimeout(function() {
                            draw(false)
                        }, 20);
                    }
                }
                draw(preScore <= score);
            }

            let defaultOption = {
                "max": 100,
                "step": 1,
                "angle": 0,
                "radius": 200,
                "progresslineWidth": 20,
                "progresslineColor": "#e3e8ee",
                "scoreSuffix": "",
                "text": "",
                "progressColor": function(score) {
                    return "#ff6a00"
                },
                "draw": function(ctx, score) {

                }
            }

            $.fn.extend({
                dashboard: function(score, option) {
                    let preScore = this.data("preScore");
                    flush.call(this, score, $.extend({}, defaultOption, option))
                    this.data("preScore", score);
                    return this;
                }
            });


        }($))



      }
    }
  };
</script>

<style scoped lang="css">
/deep/.swiper-pagination-bullet-active{
  background-color: #FF6A00;
}
.btn_group{
  padding: 0 3rem;
  /* display: flex;
  justify-content: space-between; */
}
.btn_group .secbtn{
  background: #243954;
}
.screen1-box{
  display: flex;
  padding: 2rem 0;
  justify-content: space-between;
}
.screen1-box canvas {
    display: inline-block;
    width: 5.8rem;
    height: 5.8rem;
}
    .mySwiperPar{
      position: relative;
      overflow: hidden;
      padding-bottom: 3rem;
    }
    .mySwiperPar1{
      position: relative;
      overflow: hidden;
    }
      .swiper {
        width: 100%;
      }

      .swiper-slide {

      }

    .mySwiper2 .swiper-slide {
      transition: 300ms;
      z-index: 1;
      transform: scale(0.8);
    }
    .mySwiper2 .swiper-slide-active,.mySwiper1 .swiper-slide-duplicate-active{
        transform: scale(1);
        z-index: 2;
    }
        .mySwiper2 .swiper-slide span{
      display: none;
      width: 9rem;
      text-align: center;
      /* padding-top: 16px; */
    }
    .mySwiper2 .swiper-slide-active span{
      display: block;
    }
    /* .image_15_new{
      height: 1.3rem !important;
    } */
  .text_58 {
    position: relative;
  }
  .button_3 {
    margin-top: 1.88rem;
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    padding: 0.44rem 0;
    background-image: linear-gradient(
      99.8deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 82.66%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.56rem 0px rgba(255, 65, 65, 0.1);
    border-radius: 0.13rem;
    width: 6.25rem;
  }
  .list-item_1 {
    padding: 0 1.56rem 1.25rem;
  }
  .list-item {
    padding: 0.94rem 0.41rem 0.97rem 0.81rem;
    background-image: linear-gradient(
      180deg,
      rgb(246, 247, 251) 0%,
      rgb(246, 247, 251) 0%,
      rgb(253, 253, 255) 100%,
      rgb(253, 253, 255) 100%
    );
    box-shadow: 0px 0.13rem 1.19rem 0px rgba(227, 227, 227, 0.5);
    border-radius: 0.19rem;
    border: solid 0.2rem rgb(255, 255, 255);
  }
  .group_12 {
    margin-top: 1.88rem;
  }
  .group_14 {
    margin-top: 1.94rem;
  }
  .button_1 {
    margin-top: 1.56rem;
    padding: 0.44rem 0;
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-image: linear-gradient(
      99.8deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 82.66%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.56rem 0px rgba(255, 65, 65, 0.1);
    border-radius: 0.13rem;
    width: 6.25rem;
  }
  .left-section_3 {
    align-self: center;
    background-color: rgb(216, 216, 216);
    border-radius: 50%;
    width: 0.25rem;
    height: 0.25rem;
  }
  .image_6 {
    width: 3rem;
    height: 3rem;
  }
  .right-group {
    margin-left: 1.19rem;
    margin-top: 0.31rem;
    flex: 1 1 auto;
  }
  .top-group {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_22 {
    margin-left: 0.75rem;
    /* margin-top: 0.69rem; */
    /* align-self: center; */
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.13rem;
    /* white-space: nowrap; */
  }
    .text_221 {
    white-space: pre-wrap;
  }
  .grid-item_1 {
    padding: 0.56rem 0 0.63rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.13rem;
    position: relative;
  }
  .image_18 {
    width: 5.63rem;
    height: 1.75rem;
  }
  .text_6 {
    color: rgb(38, 38, 38);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.38rem;
    white-space: nowrap;
  }
  .text_8 {
    margin-top: 0.63rem;
    color: rgb(89, 89, 89);
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.41rem;
    text-align: left;
    width: 14rem;
  }
  .left-section {
    align-self: center;
    background-color: rgb(216, 216, 216);
    border-radius: 50%;
    width: 0.25rem;
    height: 0.25rem;
  }
  .text_20 {
    margin-left: 0.5rem;
  }
  .container {
    padding-top:3.5rem;
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
  }
  .header {
    height: 6.38rem;
  }
  .group_3 {
    padding-top: 1.69rem;
    flex: 1 1 auto;
    overflow-y: auto;
  }
  .group {
    padding: 0.84rem 0.88rem 0.72rem 2.19rem;
    color: rgb(0, 0, 0);
    font-size: 0.94rem;
    line-height: 1.13rem;
    letter-spacing: -0.019rem;
    white-space: nowrap;
  }
  .section_1 {
    padding: 0.88rem 1rem 0.81rem 1.09rem;
    color: rgb(255, 106, 0);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(0, 0, 0, 0.03);
  }
  .text_61 {
    margin-top: 2.5rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_31 {
    margin-top: 2.19rem;
    padding: 0 0.81rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
  }
  .group_32 {
    margin-top: 3.25rem;
  }
  .group_1 {
    margin: 0.19rem 0 0.22rem;
  }
  .group_2 {
    align-self: center;
  }
  .text-wrapper {
    padding: 0.44rem 0;
    background-color: rgba(255, 106, 0, 0.05);
    border-radius: 1rem;
    width: 5rem;
    height: 2rem;
  }
  .group_23 {
    margin-top: 2.63rem;
    padding: 0 1.56rem;
  }
  .section_9 {
    margin-top: 2rem;
    padding: 1.88rem 1.25rem 1.63rem 1.31rem;
    background-color: rgb(248, 249, 251);
  }
  .image-wrapper_3 {
    background-image: url('https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/6198d70c8611d00011b57c2f/6198d8928611d00011b57c86/16377417725036935418.png');
    background-position: 0px 0.31rem;
    background-size: 100% 91%;
    background-repeat: no-repeat;
  }
  .text_62 {
    margin-top: 1rem;
    align-self: center;
  }
  .section_11 {
    padding: 1.94rem 1.69rem 1.31rem 1.75rem;
    background-color: rgb(249, 250, 252);
  }
  .image {
    width: 1.06rem;
    height: 0.69rem;
  }
  .image_1 {
    margin-left: 0.28rem;
    width: 1rem;
    height: 0.69rem;
  }
  .image_2 {
    margin-left: 0.28rem;
    width: 1.53rem;
    height: 0.72rem;
  }
  .image_3 {
    align-self: center;
    width: 0.81rem;
    height: 0.69rem;
  }
  .image_4 {
    margin-left: 1.59rem;
    width: 4.94rem;
    height: 1.5rem;
  }
  .group_6 {
    padding: 0 1.25rem 0.88rem 1.31rem;
  }
  .section_8 {
    margin-top: 1rem;
    padding: 1.75rem 1.56rem 0.94rem;
    background-color: rgb(248, 249, 251);
  }
  .text_51 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .image_14 {
    margin-top: 1.25rem;
    align-self: center;
    width: 20rem;
    height: 13.75rem;
  }
  .group_24 {
    margin-top: 1.31rem;
  }
  .group_26 {
    margin-top: 1.88rem;
  }
  .text_57 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .group_28 {
    margin-top: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.19rem;
    color: rgb(38, 38, 38);
    font-size: 0.81rem;
    line-height: 1.63rem;
  }
  .group_29 {
    margin-top: 2.81rem;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .image_24 {
    filter: drop-shadow(0px 0.063rem 0.44rem rgba(174, 174, 174, 0.64));
    width: 22.44rem;
    /* height: 7.13rem; */
  }
  .text_63 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.13rem;
    font-weight: 500;
    line-height: 1.13rem;
    text-align: center;
    width: 12.38rem;
  }
  .search {
    margin-top: 0.88rem;
    padding: 0.5rem 0.31rem 0.44rem 0.56rem;
    filter: drop-shadow(0px 0.88rem 0.66rem #1c25330d);
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    border: solid 0.063rem rgb(222, 222, 222);
  }
  .group_34 {
    margin-top: 1.13rem;
    align-self: center;
    text-align: center;
    width: 11.38rem;
  }
  .section_12 {
    background-color: rgb(31, 41, 55);
    height: 2.06rem;
  }
  .section_13 {
    padding: 3.25rem 1.06rem 1.94rem 1.44rem;
    background-color: rgb(31, 41, 55);
  }
  .image_27 {
    width: 7.25rem;
    height: 2.25rem;
    position: absolute;
    left: 1.44rem;
    top: 1.44rem;
  }
  .group_7 {
    /* padding-left: 1.19rem;
    padding-right: 1.13rem; */
  }
  .image_5 {
    margin-top: 1.31rem;
    align-self: center;
    width: 19.75rem;
    height: 14.56rem;
  }
  .button {
    margin-top: 1.19rem;
    padding: 0.56rem;
    align-self: center;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.38rem;
    white-space: nowrap;
    background-color: rgb(255, 106, 0);
    border-radius: 0.13rem;
  }
  .text_5 {
    margin-top: 2.44rem;
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .list {
    margin-top: 1.81rem;
  }
  .group_8 {
    margin-top: 3.88rem;
  }
  .list-item_1:last-of-type {
    margin-top: 2.5rem;
  }
  .text_45 {
    align-self: center;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .image_13 {
    margin-top: 1.69rem;
    align-self: center;
    width: 16.13rem;
    height: 12.19rem;
  }
  .group_19 {
    margin-top: 2.44rem;
  }
  .group_21 {
    margin-top: 1.88rem;
  }
  .group_25 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_53 {
    margin: 0.63rem 0.88rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: left;
  }
  .group_27 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_55 {
    margin-left: 0.75rem;
    margin-top: 0.56rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .section_10 {
    padding: 1.31rem 0.94rem 3rem;
    overflow: hidden;
    border-radius: 0.94rem;
    background-color: rgb(255, 255, 255);
    position: relative;
  }
  .text_60 {
    align-self: center;
  }
  .group_30 {
    margin-top: 1rem;
  }
  .group_33 {
    margin: 0.19rem 0 0.31rem;
    color: rgb(199, 199, 199);
    font-size: 0.88rem;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text-wrapper_6 {
    padding: 0.44rem 0;
    color: rgb(255, 255, 255);
    font-size: 0.81rem;
    font-weight: 500;
    line-height: 1.13rem;
    white-space: nowrap;
    background-image: linear-gradient(
      102.2deg,
      rgb(255, 106, 0) 0%,
      rgb(255, 106, 0) -2.22%,
      rgb(255, 106, 0) 81.34%,
      rgb(255, 106, 0) 100%
    );
    box-shadow: 0px 0.13rem 0.25rem 0px rgba(255, 65, 65, 0.5);
    border-radius: 0.25rem;
    width: 5rem;
    height: 2rem;
  }
  .text_66 {
    color: rgb(137, 137, 137);
    font-size: 0.88rem;
    line-height: 1.5rem;
  }
  .text_67 {
    color: rgb(255, 106, 0);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.38rem;
  }
  .group_35 {
    width: 12.44rem;
  }
  .image_26 {
    margin-top: 2.63rem;
    width: 6.25rem;
    height: 6.25rem;
  }
  .text_2 {
    align-self: center;
    color: rgb(38, 38, 38);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.06rem;
    white-space: nowrap;
  }
  .text_3 {
    margin-top: 0.81rem;
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    line-height: 1.38rem;
    text-align: center;
  }
  .list-item:not(:first-of-type) {
    margin-top: 0.81rem;
  }
  .text_14 {
    color: rgb(35, 35, 35);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    white-space: nowrap;
  }
  .text_15 {
    margin-top: 1rem;
    color: rgb(35, 35, 35);
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 1.56rem;
    white-space: nowrap;
  }
  .group_20 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_47 {
    margin: 0.63rem 1rem 0 0.75rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: left;
  }
  .group_22 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_49 {
    margin-left: 0.75rem;
    margin-top: 0.56rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_52 {
    margin-left: 0.5rem;
  }
  .text_54 {
    margin-left: 0.5rem;
  }
  .image_15 {
    width: 3rem;
    /* height: 3rem; */
  }
  .image_16 {
    width: 4.63rem;
    height: 4.25rem;
    position: absolute;
    left: 0;
    top: 12.56rem;
  }
  .text_59 {
    margin-left: 1.19rem;
    margin-right: 0.13rem;
    flex: 1 1 auto;
    text-align: justify;
  }
  .grid {
    flex: 1 1 auto;
    height: 6.38rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 0.5rem;
    grid-column-gap: 0.56rem;
  }
  .image-wrapper_2 {
    margin-left: 0.5rem;
    padding: 0.56rem 0 0.63rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.13rem;
    width: 6.63rem;
    height: 2.94rem;
  }
  .image_25 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .text_64 {
    margin-left: 0.81rem;
    margin-top: 0.19rem;
  }
  .group_36 {
    text-align: left;
    width: 11.56rem;
  }
  .text_72 {
    margin-top: 2.19rem;
    color: rgb(140, 140, 140);
    font-size: 0.88rem;
    line-height: 1.63rem;
    text-align: left;
  }
  .image_10 {
    margin-left: 0.75rem;
    margin-right: 0.56rem;
    width: 19rem;
    height: 14.38rem;
  }
  .group_11 {
    margin-top: 1.06rem;
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_17 {
    margin: 0.63rem 0.75rem 0 0.81rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.25rem;
    text-align: left;
  }
  .group_13 {
    color: rgb(38, 38, 38);
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
  }
  .text_19 {
    margin-left: 0.75rem;
    margin-top: 0.56rem;
    color: rgba(89, 89, 89, 0.95);
    font-size: 0.81rem;
    line-height: 1.5rem;
    text-align: left;
  }
  .text_46 {
    margin-left: 0.5rem;
  }
  .text_48 {
    margin-left: 0.5rem;
  }
  .grid-item {
    padding: 0.63rem 0 0.56rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.63rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.094rem;
    position: relative;
  }
  .grid-item_2 {
    padding: 0.56rem 0 0.63rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.063rem 0.75rem 0px rgba(224, 224, 224, 0.5);
    border-radius: 0.13rem;
  }
  .text_68 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 2.19rem;
  }
  .text_69 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 2.19rem;
  }
  .text_70 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 1.25rem;
  }
  .text_71 {
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 1.25rem;
  }
  .section_5 {
    align-self: center;
    background-color: rgb(216, 216, 216);
    border-radius: 50%;
    width: 0.25rem;
    height: 0.25rem;
  }
  .text_16 {
    margin-left: 0.81rem;
    white-space: pre-wrap;
    flex-shrink: 1;
  }
  .section_6 {
    align-self: center;
    background-color: rgb(216, 216, 216);
    border-radius: 50%;
    width: 0.25rem;
    height: 0.25rem;
  }
  .text_18 {
    margin-left: 0.5rem;
  }
</style>